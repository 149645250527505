import axios from "axios";
import Alert from "components/common/alert";
import "css/common.css";
import Landing from "pages/common/landing";
import NotFound from "pages/common/notFound";
import { useCallback, useLayoutEffect } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { openAlert } from "redux/slices/alertSlice";
import { insertUserInfo } from "redux/slices/userSlice";
import Body from "components/template/body";
import Footer from "components/template/footer";
import Header from "components/template/header";
import Wrapper from "components/template/wrapper";
import ScrollToTop from "components/common/scrollToTop";
import "lang/i18n";
import Zwcad from "pages/zwcad/zwcad";
import Foxit from "pages/foxit/foxit";
import Company from "pages/company/company";
import FloatingButton from "components/landing/floatingButton";
import Foxitpdfeditor from "pages/foxit/foxitpdfeditor";
import Admin from "pages/admin/admin";
import Zw3d from "pages/zwcad/zw3d";
import Pdfreader from "pages/foxit/pdfreader";
import Foxitpdfesign from "pages/foxit/foxitpdfesign";
import Zdream from "pages/zwcad/zdream";
import CadCompare from "pages/zwcad/cadCompare";
import Onsitesupport from "pages/community/onsitesupport";
import Onlinesupport from "pages/community/onlinesupport";
import Faq from "pages/community/faq";
import Announcement from "pages/community/announcement";
import Case from "pages/community/case";
import Freeexperience from "pages/community/freeexperience";
import Request from "pages/community/request";
import Communitydetail from "pages/community/communitydetail";
import Event from "pages/community/event";
import Zw3dCompare from "pages/zwcad/zw3dCompare";
/**
 * 파일명: root.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: 메인 라우터
 * axios 설정 처리
 * 스크린 사이즈에 따른 css 처리
 */
function Root() {
  //dispatch
  const dispatch = useDispatch();
  //cookie
  const cookies = new Cookies();

  //selector
  const userPayload = useSelector((state) => state.user);

  //env
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const authNm = process.env.REACT_APP_AUTH_TOKEN;
  // const authTime = process.env.REACT_APP_AUTH_TOKEN_TIME;
  const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
  const domain = process.env.REACT_APP_DOMAIN;

  //set screen size
  const setScreenSize = useCallback(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    let prevVisualViewport = 0;
    const currentVisualViewportHeight = window.visualViewport.height;

    if (
      prevVisualViewport - 30 > currentVisualViewportHeight &&
      prevVisualViewport - 100 < currentVisualViewportHeight
    ) {
      const scrollHeight = window.document.scrollingElement.scrollHeight;
      const scrollTop = scrollHeight - window.visualViewport.height;

      window.scrollTo(0, scrollTop);
    }

    prevVisualViewport = window.visualViewport.height;

    window.visualViewport.onresize = prevVisualViewport;
  }, []);

  //refresh user info
  const refreshInfo = useCallback(() => {
    axios
      .get("/open/refresh/info")
      .then((res) => {
        if (res.status === 200) {
          const memeberInfo = res.data;
          dispatch(
            insertUserInfo({
              name: memeberInfo.name,
              id: memeberInfo.id,
            })
          );
        }
      })
      .catch(() => {
        const effectCookies = new Cookies();
        effectCookies.remove(authNm, { path: "/", domain: domain });
        effectCookies.remove(refreshNm, { path: "/", domain: domain });
        window.location.href = process.env.REACT_APP_CLIENT_URL;
      });
  }, [authNm, dispatch, domain, refreshNm]);

  //resize eventListener
  useLayoutEffect(() => {
    setScreenSize();
    window.addEventListener("resize", () => setScreenSize());
  });

  useLayoutEffect(() => {
    const effectCookies = new Cookies();
    const refreshCookie = effectCookies.get(refreshNm);

    if (userPayload.name === "" && refreshCookie !== undefined) {
      refreshInfo();
    }
  }, [authNm, refreshInfo, refreshNm, userPayload.name]);

  //axios

  const inessentialList = [
    "/open/refresh/info",
    "/open/boardlist",
    "/open/boardcount",
    "/open/detail",
    "/open/product_fileinfo",
    "/open/signin",
    "/open/free",
    "/open/onsite",
    "/open/request",
    "/open/popup_list",
  ];
  axios.defaults.url = serverUrl;
  axios.defaults.baseURL = serverUrl;
  axios.defaults.withCredentials = false;

  //axios request
  axios.interceptors.request.use(
    async (config) => {
      if (config.params == null) {
        config.params = {};
      }

      if (!inessentialList.includes(config.url)) {
        const authCookie = cookies.get(authNm);
        const refreshCookie = cookies.get(refreshNm);

        if (authCookie === undefined && refreshCookie === undefined) {
          cookies.remove(authNm, { path: "/", domain: domain });
          cookies.remove(refreshNm, { path: "/", domain: domain });
          window.location.href = process.env.REACT_APP_CLIENT_URL;
        }

        config.headers.Authorization = `Bearer ${authCookie}`;
        config.headers.RefreshAuthorization = `Bearer ${refreshCookie}`;
      }

      const authCookie = cookies.get(authNm);
      const refreshCookie = cookies.get(refreshNm);

      if (authCookie !== undefined && refreshCookie !== undefined) {
        config.headers.Authorization = `Bearer ${authCookie}`;
        config.headers.RefreshAuthorization = `Bearer ${refreshCookie}`;
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  //axios response
  axios.interceptors.response.use(
    (res) => {
      if (res.headers.refresh_authorization !== undefined) {
        cookies.remove(authNm, { path: "/", domain: domain });
        cookies.set(authNm, res.headers.refresh_authorization, {
          path: "/",
          domain: domain,
        });
      }

      return res;
    },
    (err) => {
      const response = err.response;

      if (response !== undefined) {
        const status = response.status;

        if (status === 400) {
          cookies.remove(authNm, { path: "/", domain: domain });
          cookies.remove(refreshNm, { path: "/", domain: domain });
        } else if (status === 401) {
          cookies.remove(authNm, { path: "/", domain: domain });
          cookies.remove(refreshNm, { path: "/", domain: domain });

          dispatch(
            openAlert({
              title: "Notice",
              content: err.response.data,
              submitEventHandler: () =>
                (window.location.href = process.env.REACT_APP_CLIENT_URL),
            })
          );
        } else if (status === 404) {
          throw new Error("It is an unknown request.");
        }
      } else {
        dispatch(
          openAlert({
            title: "Error",
            content: err.code,
            submitEventHandler: () => {},
          })
        );
      }

      return Promise.reject(err);
    }
  );
  return (
    <div className="container">
      <Alert />

      <BrowserRouter>
        <ScrollToTop />
        <Wrapper>
          <Header />
          <Body>
            <FloatingButton />
            <Routes>
              <Route
                path={process.env.REACT_APP_CLIENT_URL}
                element={<Landing />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "zwcad"}
                element={<Zwcad />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "zw3d"}
                element={<Zw3d />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "zdream"}
                element={<Zdream />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "cad_compare"}
                element={<CadCompare />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "zw3d_compare"}
                element={<Zw3dCompare />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "foxit"}
                element={<Foxit />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "company"}
                element={<Company />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "foxit_pdf_editor"}
                element={<Foxitpdfeditor />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "foxit_pdf_esign"}
                element={<Foxitpdfesign />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "pdf_reader"}
                element={<Pdfreader />}
              />

              <Route
                path={process.env.REACT_APP_CLIENT_URL + "admin/*"}
                element={<Admin />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "onsite_support"}
                element={<Onsitesupport />}
              />
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "online_support"}
                element={<Onlinesupport />}
              ></Route>
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "faq"}
                element={<Faq />}
              ></Route>
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "announcement"}
                element={<Announcement />}
              ></Route>
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "case"}
                element={<Case />}
              ></Route>
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "event"}
                element={<Event />}
              ></Route>
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "free"}
                element={<Freeexperience />}
              ></Route>
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "request"}
                element={<Request />}
              ></Route>
              <Route
                path={process.env.REACT_APP_CLIENT_URL + "community"}
                element={<Communitydetail />}
              ></Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Body>
          <Footer />
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default Root;
