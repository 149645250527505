import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "redux/slices/alertSlice";

import ReactModal from "react-modal";
import style from "css/support.module.css";

function Alertsubmit(props) {
  //dispatch
  const dispatch = useDispatch();

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className={style.submitmodal} //모달 ClassName
      overlayClassName={style.submitmodal_overlay} ///모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <div className={style.sm_body}>
        <div className={style.sm_title}>{props.title}</div>
        <div className={style.sm_text}>
          <div className={style.sm_point}>{props.text1}</div>
          <div>{props.text2}</div>
          <div>{props.text3}</div>
        </div>
        <div className={style.sm_footer}>
          <div
            className={style.sm_button + " cursor"}
            onClick={() => props.close()}
          >
            확인
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Alertsubmit;
