import style from "css/adminMenu.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/*
 * 작성일자: 2023-01-30
 * 작성자: 임창현
 */

// props: btn name, img, alt-img
function AdminMenubtn(props) {
  const img = props.img;
  const altImg = props.altImg;
  const menuName = props.menuName;
  const dropList = props.dropList;
  const dropLink = props.dropLink;
  let list = [];
  const navigate = useNavigate();

  const [isDropdown, setIsDropdown] = useState(false);

  const activeBtn = window.location.pathname.replace("/admin/", "");

  if (dropList.length > 0) {
    for (let i = 0; i < dropList.length; i++) {
      list.push(
        <li
          key={dropList[i]}
          onClick={() => navigate(dropLink[i])}
          className={
            activeBtn === dropLink[i]
              ? `${style.nav_link} ${style.active}`
              : style.nav_link
          }
        >
          <span className={style.dot}></span>&nbsp;&nbsp;&nbsp;&nbsp;
          {dropList[i]}
        </li>
      );
    }
  }

  return (
    <>
      {Array.isArray(dropList) && dropList.length > 0 ? (
        <section className={style.sidebar_menu}>
          <div
            className={style.menu_btn}
            onClick={() => {
              setIsDropdown(!isDropdown);
            }}
          >
            <img src={img} alt={altImg} />
            <span>{menuName}</span>
            <span className={style.dropdown_icon}>
              <img
                src={
                  isDropdown
                    ? "/img/admin/cursor_up.png"
                    : "/img/admin/cursor_down.png"
                }
                alt=""
              />
            </span>
          </div>

          <div
            className={
              isDropdown
                ? `${style.menu_dropdown}`
                : `${style.menu_dropdown} ${style.close}`
            }
          >
            <ul>{list}</ul>
          </div>
        </section>
      ) : (
        <section className={style.sidebar_menu}>
          <div
            className={
              activeBtn === dropLink[0]
                ? `${style.menu_btn} ${style.active}`
                : style.menu_btn
            }
            onClick={() => {
              navigate(dropLink[0]);
            }}
          >
            <img src={img} alt={altImg} />
            <span>{menuName}</span>
          </div>
        </section>
      )}
    </>
  );
}

export default AdminMenubtn;
