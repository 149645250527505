import React from "react";
import style from "css/template.module.css";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
/**
 * Version : 1.0
 * 작성일자 : 2023-12-11
 * 작성자 : 권도훈
 * 설명 : 공통 템플릿 영역
 */
function Footer() {
  //navigate
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const submenucompany = (val) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "company", {
      state: {
        title: val,
      },
    });
  };

  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  const check = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <>
      {pathname.includes("admin") ? (
        <></>
      ) : (
        <div className={style.footer}>
          <div className={style.footer_content_wrap}>
            <div className={style.footer_left_area}>
              <div className={style.company_info_area}>
                <img alt="" src="/img/common/logo.png" />
                <div className={style.company_info}>
                  <div className={style.company_info_item}>
                    <div className={style.company_info_label}>Address</div>
                    <div className={style.company_info_value}>
                      {
                        "137-902 서울시 서초구 강남대로 101길 43\n유영빌딩 6층 (잠원동 9-4)"
                      }
                    </div>
                  </div>
                  <div className={style.company_info_item}>
                    <div className={style.company_info_label}>TEL</div>
                    <div className={style.company_info_value}>02-595-7782</div>
                  </div>
                  <div className={style.company_info_item}>
                    <div className={style.company_info_label}>FAX</div>
                    <div className={style.company_info_value}>02-595-7009</div>
                  </div>
                  <div className={style.company_info_item}>
                    <div className={style.company_info_label}>
                      사업자등록번호
                    </div>
                    <div className={style.company_info_value}>114-86-93156</div>
                  </div>
                </div>

                {isMobile ? (
                  <div className={style.company_footer}>
                    <div className={style.icon_area}>
                      <img
                        className="cursor"
                        alt=""
                        src={
                          check
                            ? "/img/common/email_m.png"
                            : "/img/common/email.png"
                        }
                        onClick={() => {
                          window.open("mailto:admin@bluetns.co.kr");
                        }}
                      />
                      <img
                        onClick={() =>
                          window.open("https://blog.naver.com/blue_tns")
                        }
                        className="cursor"
                        alt=""
                        src={
                          check
                            ? "/img/common/blog_m.png"
                            : "/img/common/blog.png"
                        }
                      />
                      <img
                        onClick={() => {
                          window.open("https://www.youtube.com/@zwcadea5313");
                        }}
                        className="cursor"
                        alt=""
                        src={
                          check
                            ? "/img/common/youtube_m.png"
                            : "/img/common/youtube.png"
                        }
                      />
                    </div>
                    <img alt="" src="/img/common/distributor.png" />
                  </div>
                ) : (
                  <div className={style.icon_area}>
                    <img
                      className="cursor"
                      alt=""
                      src="/img/common/email.png"
                      onClick={() => {
                        window.open("mailto:admin@bluetns.co.kr");
                      }}
                    />
                    <img
                      onClick={() =>
                        window.open("https://blog.naver.com/blue_tns")
                      }
                      className="cursor"
                      alt=""
                      src="/img/common/blog.png"
                    />
                    <img
                      onClick={() => {
                        window.open("https://www.youtube.com/@zwcadea5313");
                      }}
                      className="cursor"
                      alt=""
                      src="/img/common/youtube.png"
                    />
                  </div>
                )}
              </div>

              {isMobile ? (
                <></>
              ) : (
                <div className={style.sitemap_area}>
                  <div className={style.menu_list_wrapper}>
                    <div className={style.menu_list}>
                      <div className={style.menu_label}>ZWCAD</div>
                      <div className={style.menu_item_list}>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("zwcad")}
                        >
                          ZWCAD 2025
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("zw3d")}
                        >
                          ZW3D 2025
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("zdream")}
                        >
                          ZDREAM
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("cad_compare")}
                        >
                          CAD 제품 비교
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("zw3d_compare")}
                        >
                          ZW3D 제품 비교
                        </div>
                      </div>
                    </div>
                    <div className={style.menu_list}>
                      <div className={style.menu_label}>Foxit PDF</div>
                      <div className={style.menu_item_list}>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("foxit_pdf_editor")}
                        >
                          Foxit PDF Editor
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("foxit_pdf_esign")}
                        >
                          Foxit PDF eSign
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("pdf_reader")}
                        >
                          PDF Reader
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => {
                            window.open("https://developers.foxit.com");
                          }}
                        >
                          {" "}
                          PDF SDK
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => {
                            window.open(
                              "https://developers.foxit.com/developer-hub/"
                            );
                          }}
                        >
                          SDK 문서
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => {
                            window.open(
                              "https://www.foxit.kr/enterprise-automation/"
                            );
                          }}
                        >
                          변환서버
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => {
                            window.open(
                              "https://www.foxit.kr/solution/paperless-office/"
                            );
                          }}
                        >
                          페어퍼리스 오피스
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => {
                            window.open(
                              "https://www.foxit.kr/solution/document_security/"
                            );
                          }}
                        >
                          PDF문서보안
                        </div>
                      </div>
                    </div>
                    <div className={style.menu_list}>
                      <div className={style.menu_label}>Company</div>
                      <div className={style.menu_item_list}>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => submenucompany(1)}
                        >
                          인사말
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => submenucompany(2)}
                        >
                          조직도
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => submenucompany(3)}
                        >
                          오시는길
                        </div>
                      </div>
                    </div>
                    <div className={style.menu_list}>
                      <div className={style.menu_label}>지원 및 커뮤니티</div>
                      <div className={style.menu_item_list}>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("faq")}
                        >
                          FAQ
                        </div>
                        {/* <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("onsite_support")}
                        >
                          방문지원
                        </div> */}
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("online_support")}
                        >
                          원격지원
                        </div>

                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("announcement")}
                        >
                          공지사항
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("event")}
                        >
                          이벤트
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() => menuHandler("case")}
                        >
                          도입사례
                        </div>
                        <div
                          className={`${style.menu_item} cursor`}
                          onClick={() =>
                            window.open("https://blog.naver.com/blue_tns")
                          }
                        >
                          블로그
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={style.contact_menu_area}>
                    <div className={style.contact_menu}>
                      <div
                        className={`${style.contact_menu_item} cursor`}
                        onClick={() => menuHandler("free")}
                      >
                        무료체험 신청
                      </div>
                      <div
                        className={`${style.contact_menu_item} cursor`}
                        onClick={() => menuHandler("request")}
                      >
                        견적 신청
                      </div>
                    </div>
                    <div className={style.distributor_area}>
                      <img alt="" src="/img/common/distributor.png" />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isMobile ? (
              <div className={style.contact_menu}>
                <div
                  className={`${style.contact_menu_item} cursor`}
                  onClick={() => menuHandler("free")}
                >
                  무료체험
                </div>
                <div
                  className={`${style.contact_menu_item} cursor`}
                  onClick={() => menuHandler("request")}
                >
                  견적 신청
                </div>
              </div>
            ) : (
              // <div className={style.distributor_area}>
              //   <img alt="" src="/img/common/distributor.png" />
              // </div>
              <></>
            )}
          </div>
          <div className={style.copyright}>
            {isMobile
              ? "© 2024 BLUETNS. CO.,LTD.\nAll rights reserved."
              : "© 2024 BLUETNS. CO.,LTD. All rights reserved."}
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
