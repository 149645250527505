import React, { useCallback, useEffect, useState } from "react";
import style from "css/foxit.module.css";
import Underbar from "components/common/underbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

function Foxitpdfeditor() {
  const navigate = useNavigate();
  const ispc = useMediaQuery({
    query: "(min-width: 1300px)",
  });
  const [data, setdata] = useState({
    LINK: "",
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TITLE: "FOXITPDFEDITOR",
      },
    };
    axios.get("/open/product_fileinfo", config).then((res) => {
      if (res.status === 200) {
        if (res.data.TYPE === "FILE") {
          setdata({ LINK: res.data.FILE_URL });
        } else {
          setdata({ LINK: res.data.LINK });
        }
      }
    });
  }, []);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const makeTag = (padding, contents, margin) => {
    return (
      <div
        style={{ padding: "0px " + padding + "px", marginRight: margin + "px" }}
        className={style.function_info_tag}
      >
        {contents}
      </div>
    );
  };

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    { TITLE: "Foxit PDF eSign", LINK: "foxit_pdf_esign" },

    { TITLE: "PDF Reader", LINK: "pdf_reader" },
  ];
  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={
            isMobile
              ? {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/pdf_bg_m.png')",
                }
              : {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/editor_bg.png')",
                }
          }
        >
          {" "}
          {isMobile ? <img alt="" src="/img/foxit/pdf_img_m.png" /> : <></>}
        </div>

        <div className={style.foxit_area}>
          <div className={style.foxit_title_wrap}>
            <div className={style.foxit_title}>Foxit PDF Editor</div>
            <div className={style.foxit_subtitle}>ALL-IN-ONE 편집기</div>
            <div className={style.foxit_title_buttons}>
              <div
                className={style.foxit_title_button1 + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "free")
                }
              >
                무료체험 신청
              </div>
              <div
                className={style.foxit_title_button2 + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "request")
                }
              >
                견적 신청
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.foxit_category}>
        {isTablet ? (
          <Mobilemenu selected="Foxit PDF Editor" list={menulist} type="" />
        ) : (
          <div className={style.foxit_category_buttons}>
            <div
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "foxit_pdf_editor")
              }
              className={
                style.foxit_button + " " + style.button_focus + " cursor"
              }
            >
              Foxit PDF Editor
            </div>
            <div
              className={style.foxit_button + " cursor"}
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "foxit_pdf_esign")
              }
            >
              Foxit PDF eSign
            </div>
            <div
              className={style.foxit_button + " cursor"}
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "pdf_reader")
              }
            >
              PDF Reader
            </div>
          </div>
        )}
      </div>
      <div className={style.foxit_wrapper}>
        <div
          className={style.foxit_contents + " " + style.ir + " " + style.first}
        >
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>
              문서를 생성, 편집, 작성 및 서명할 수 있는 하나의 앱
            </div>
            <div className={style.foxit_info_contents}>
              강력하고 강력한{" "}
              <font className={style.focus_text}>
                Foxit PDF Editor에 통합된
              </font>{" "}
              법적 구속력이 있는 전자 서명
              {ispc ? <br /> : ""}
              기능을 사용하면 동일한 응용 프로그램 내에서 조직 내부 및 외부의
              문서를 {ispc ? <br /> : ""}
              원활하게 생성, 전자 서명 및 공동 작업할 수 있습니다. (별도의 Foxit
              eSign {ispc ? <br /> : ""}
              구매가 필요합니다.)
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(24, "# Foxit eSign 계정 만들기", 12)}
              {makeTag(25, "# 전자적으로 자체 서명된 문서", 0)}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(25, "# 서명 수집 및 작업 흐름 관리", 0)}
            </div>
          </div>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor1.png"}
            />
          </div>
        </div>
        <div className={style.foxit_contents + " " + style.il}>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor2.png"}
            />
          </div>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>
              PDF를 전문가처럼 편집하세요
            </div>
            <div className={style.foxit_info_contents}>
              자신의 PDF 문서를 빠르고 쉽게 업데이트하십시오. 워드 프로세서처럼
              {ispc ? <br /> : ""}
              <font className={style.focus_text}>
                {" "}
                작동 - 단락, 열 및 페이지에 걸쳐 텍스트를 리플로우
              </font>
              합니다.
              {ispc ? <br /> : ""}
              문서 레이아웃 변경, 텍스트 크기, 글꼴 또는 줄 간격 수정,
              멀티미디어 추가.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 17 : 12, "# PDF 텍스트편집", 0)}
              {makeTag(ispc ? 16 : 12, "# 문서제작", 0)}
              {makeTag(
                ispc ? 17 : 12,
                "# 비디오, 이미지, 오디오 3D 개체 추가",
                0
              )}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 23 : 18, "# 파일 압축하기", 0)}
              {makeTag(ispc ? 23 : 18, "# 워크플로우 자동화", 0)}
              {makeTag(ispc ? 24 : 18, "# 고급기능, 고급개체 편집", 0)}
            </div>
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.ir}>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>공유와 협업</div>
            <div className={style.foxit_info_contents}>
              팀 동료를 함께 연결하여 유연성과 결과를 높입니다.{" "}
              {ispc ? <br /> : ""}
              주요 콘텐츠 관리 시스템과 통합하고 인기 있는 클라우드 스토리지
              서비스를 {ispc ? <br /> : ""}
              활용하고{" "}
              <font className={style.focus_text}>
                공유 검토를 시작하여 피드백을 제공 및 관리
              </font>
              합니다.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 27 : 20, "# 파일 압축하기", 0)}
              {makeTag(ispc ? 33 : 23, "# 클라우드 스토리지와 연동", 0)}
              {makeTag(ispc ? 27 : 20, "# CM와 연동", 0)}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 29 : 29, "# SharePoint® 연동 및 Review", 0)}
              {makeTag(ispc ? 33 : 33, "# Annotate PDF", 0)}
            </div>
          </div>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor3.png"}
            />
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.il}>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor4.png"}
            />
          </div>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>PDF 내보내기</div>
            <div className={style.foxit_info_contents}>
              PDF를 Word, PowerPoint, Excel, RTF, HTML, 텍스트 및{" "}
              {ispc ? <br /> : ""}
              이미지 형식으로 내보내{" "}
              <font className={style.focus_text}>PDF 콘텐츠를 쉽게 공유</font>할
              수 있습니다.
              {ispc ? <br /> : ""}
              전체 문서를 내보내거나 선택한 영역만 내보냅니다.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(
                ispc ? 25 : 25,
                "# Convert PDF To Word & Other Formats",
                0
              )}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(
                ispc ? 25 : 25,
                "# Create PDF from Microsoft Office, Outlook, and Visio",
                0
              )}
            </div>
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.ir}>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>PDF 만들기와 양식</div>
            <div className={style.foxit_info_contents}>
              거의 모든 파일 형식 또는 웹 페이지에서 PDF, PDF/A, PDF/X 및
              PDF/E를 {ispc ? <br /> : ""}
              만듭니다. PDF 포트폴리오를 만들어 여러 파일을 쉽게 공유하고 양식
              필드
              {ispc ? <br /> : ""} 인식을 사용하여{" "}
              <font className={style.focus_text}>
                대화형 PDF 양식을 빠르게 만들어 데이터를 수집
              </font>
              합니다.
              {ispc ? <br /> : ""} PRC 3D PDF를 렌더링하고, DWG파일에서 3D PDF를
              만듭니다.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 19 : 15, "# 업계 표준 PDF 생성", 0)}
              {makeTag(ispc ? 20 : 15, "# 브라우저 기반 PDF 생성", 0)}
              {makeTag(ispc ? 20 : 15, "# PDF 양식 작성", 0)}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 25 : 25, "# PDF 포트폴리오 생성", 0)}
              {makeTag(ispc ? 24 : 30, "# 호환 PDF 생성", 0)}
            </div>
          </div>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor5.png"}
            />
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.il}>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor6.png"}
            />
          </div>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>PDF 페이지 관리</div>
            <div className={style.foxit_info_contents}>
              작업 흐름을 지원하기 위해{" "}
              <label className={style.focus_text}>
                {" "}
                PDF 페이지를 구성하고 조작
              </label>
              합니다.
              {ispc ? <br /> : ""} 문서 병합 및 분할 파일 내에서 또는 한
              문서에서 다른 문서로 페이지를
              {ispc ? <br /> : ""} 끌어다 놓습니다. 스탬프, 워터마크, 머리글,
              바닥글 등을 추가합니다.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 26 : 21, "# PDF 문서 재구성", 0)}
              {makeTag(ispc ? 26 : 21, "# PDF 관리 및 분할", 0)}
              {makeTag(ispc ? 26 : 21, "# PDF 결합 및 병합", 0)}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 25 : 20, "# PDF 문서 비교", 0)}
              {makeTag(ispc ? 25 : 20, "# 콘텐츠별 PDF 검색", 0)}
              {makeTag(ispc ? 30 : 20, "# 베이츠 번호 매기기", 0)}
            </div>
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.ir}>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>PDF 보안 기능</div>
            <div className={style.foxit_info_contents}>
              기밀 문서의 정보를 보호하십시오. 텍스트와 이미지를 영구적으로 수정
              및 {ispc ? <br /> : ""}제거하고, 암호 암호화 또는{" "}
              <font className={style.focus_text}>
                Microsoft AIP로 문서를 보호
              </font>
              하고, 문서를 {ispc ? <br /> : ""}
              삭제하고, PDF에 디지털 서명합니다.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 26 : 21, "# 보안 기능 일반", 0)}
              {makeTag(ispc ? 29 : 22, "# PDF 암호 보호", 0)}
              {makeTag(ispc ? 28 : 21, "# 블랙마킹(Redaction)", 0)}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 27 : 22, "# 문서 점검 및 숨겨진 정보 제거", 0)}
              {makeTag(ispc ? 29 : 22, "# SoftCamp, MarkAny DRM 지원", 0)}
            </div>
          </div>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor7.png"}
            />
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.il}>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor8.png"}
            />
          </div>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>AI 기반 문서 편집</div>
            <div className={style.foxit_info_contents}>
              Smart Redact는 사용자가 PDF 문서에서 민감한 정보를 간편하고 효율적
              {ispc ? <br /> : ""}
              으로 찾아 수정할 수 있도록 해주는 도구입니다. 사용자가 미리 어떤
              정보를
              {ispc ? <br /> : ""} 수정해야 할지 모를 때,{" "}
              <label className={style.focus_text}>
                {" "}
                Smart Redact를 통해 한번 의 클릭으로
              </label>{" "}
              모든 민감한
              {ispc ? <br /> : ""} 정보를 신속하게 파악할 수 있습니다. 또한,
              대량의 문서에 대한 일괄 수정
              {ispc ? <br /> : ""} 기능을 제공하여 여러 문서의 민감한 정보를
              동시에 처리할 수 있습니다.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 25 : 35, "# 효율성", 0)}
              {makeTag(ispc ? 25 : 35, "# 정확성", 0)}
              {makeTag(ispc ? 25 : 35, "# 창의성", 0)}
            </div>
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.ir}>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>PDF 접근성 지원</div>
            <div className={style.foxit_info_contents}>
              정보에 접근하기 위해 보조 기술을 사용하는 장애가 있는 사람들을
              위해
              {ispc ? <br /> : ""}PDF 문서 접근성을 활성화합니다.{" "}
              <label className={style.focus_text}>
                {" "}
                문서가 섹션 508 기술 표준을 준수
              </label>
              하는지 {ispc ? <br /> : ""}
              확인하려면 PDF 파일의 액세스 가능성을 확인하십시오.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 19 : 10, "# Foxt의 TAA 규정 준수", 0)}
              {makeTag(ispc ? 19 : 10, "# Foxit의 ADA 규정 준수", 0)}
              {makeTag(ispc ? 19 : 10, "# SOC 2 인증", 0)}
            </div>
          </div>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor9.png"}
            />
          </div>
        </div>{" "}
        <div className={style.foxit_contents + " " + style.il}>
          <div className={style.pdfeditor_imgbox}>
            <img
              className={style.pdfeditor_img}
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/editor10.png"}
            />
          </div>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>문서 스캔과 OCR</div>
            <div className={style.foxit_info_contents}>
              종이 문서 또는 이미지를 스캔하여 PDF로 변환합니다.{" "}
              {ispc ? <br /> : ""}
              <label className={style.focus_text}>
                텍스트 인식(OCR)을 실행
              </label>
              하여 선택 및 검색 가능한 텍스트를 만듭니다.
              {ispc ? <br /> : ""}
              텍스트를 편집 가능하게 만들고 디지털 문서처럼 하드카피를 업데이트
              하십시오.
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 32 : 25, "# 스캔하여 PDF 생성", 0)}
              {makeTag(
                ispc ? 37 : 28,
                "# 스캔한 문서를 PDF로 만들어서 편집 가능",
                0
              )}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(ispc ? 24 : 25, "# 문서스캔", 0)}
              {makeTag(ispc ? 25 : 25, "# PDF OCR", 0)}
              {makeTag(ispc ? 25 : 25, "# OCR 텍스트 수정", 0)}
            </div>
          </div>
        </div>
      </div>
      <Underbar
        c1="Foxit PDF Editor"
        c1_use={true}
        c1_link=""
        c2="Foxit PDF Editor"
        c2_use={true}
        c2_link={data.LINK}
        c3="Foxit PDF Editor"
        c3_use={true}
        c3_link=""
        c4="Foxit PDF Editor"
        c4_use={true}
        c4_link=""
      />
    </>
  );
}

export default Foxitpdfeditor;
