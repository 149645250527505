import { Jodit } from "jodit-react";
import React, { useEffect, useRef } from "react";
import "css/common.css";
function Joditeditor(props) {
  /*    사용법
        <Joditeditor
          value="" //초기값
          onChange={(contents) => setcontents(contents)} // 값 
        />
    */
  const MyEditor = () => {
    const editorRef = useRef(null);

    useEffect(() => {
      if (editorRef.current) {
        const editor = new Jodit(editorRef.current, {
          // 이미지 업로드 설정
          uploader: {
            // insertImageAsBase64URI: false, // 이미지를 base64로 삽입하지 않음
            url: "http://bluetns.co.kr/api/open/boardimg", // 이미지를 업로드할 서버 엔드포인트
            placeholder: "내용을 입력하세요", // 플레이스홀더 설정
            format: "json", // 업로드 응답 형식
            method: "POST", // 업로드 요청 메서드

            headers: {}, // 필요한 경우 헤더 추가
            isSuccess: function (response) {
              // 업로드 성공 여부 확인 로직

              let imageurl = response.data.fileURL;
              for (var i = 0; i < imageurl.length; i++) {
                var data = imageurl[i];
                editor.selection.insertHTML(
                  " <img src= 'http://bluetns.co.kr/" + data + "' />"
                );
              }
              return response.status === 200;
            },
            // process: function (response) {
            //   // 업로드 후 처리할 로직

            //   let arrayData = [];
            //   return {
            //     files: arrayData,
            //   };
            // },
            error: function (response) {},
            getMessage: function (e) {
              return "업로드 되었습니다.";
            },
          },
        });

        editor.events.on("change", (content) => {
          // 컨텐츠 변경 감지
          props.onChange(content);
        });
        editor.value = props.value; // 초기값 설정
      }
    }, [props.value]);
    return (
      <textarea
        placeholder="내용을 입력하세요"
        style={{ height: "400px" }}
        ref={editorRef}
      />
    );
  };

  return <div>{MyEditor()}</div>;
}
export default Joditeditor;
