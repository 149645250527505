import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert, openAlert } from "redux/slices/alertSlice";

import ReactModal from "react-modal";
import style from "css/board.module.css";
import axios from "axios";

function Adminpopuppop(props) {
  const dispatch = useDispatch();

  const [boardInfo, setboardInfo] = useState({
    LINK: "",
    IMG: [],
    START_DTTM: "",
    END_DTTM: "",
    ACTIVE: true,
    ORDER_NUM: 0,
    ID: "",
  });

  const [totalImg, settotalImg] = useState([]);
  const [oldImgFiles, setOldImgFiles] = useState([]);

  const onChangeImgFile = (e) => {
    let tmpArr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].type.replace(/\/.*/, "") !== "image") {
        alert("형식 오류");
        return;
      }
      if (oldImgFiles.length > 0) {
        setOldImgFiles([]);
      }
      tmpArr.push(e.target.files[i]);
    }
    console.log(tmpArr);
    settotalImg(tmpArr);
    setboardInfo({ ...boardInfo, IMG: tmpArr });
    e.target.value = "";

    console.log("total img" + totalImg[0]);
  };
  const onRemoveImg = (file) => {
    setOldImgFiles([]);
    setboardInfo({
      ...boardInfo,
      IMG: [],
    });
  };

  const writeBoard = () => {
    let boardInfoObj = {
      START_DTTM: boardInfo.START_DTTM,
      END_DTTM: boardInfo.END_DTTM,
      ACTIVE_YN: boardInfo.ACTIVE,
      ORDER: boardInfo.ORDER_NUM,
      LINK: boardInfo.LINK,
    };
    const formData = new FormData();
    formData.append("boardInfo", JSON.stringify(boardInfoObj));

    if (Array.isArray(boardInfo.IMG) && boardInfo.IMG.length > 0) {
      for (let i = 0; i < boardInfo.IMG.length; i++) {
        formData.append("img", boardInfo.IMG[i]);
      }
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post("/board/writepop", formData, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            openAlert({
              title: "Notice",
              content: "팝업이 등록되었습니다.",
              submitEventHandler: () => props.submit(),
            })
          );
        }
      })
      .catch(() => {});
  };

  const updateBoard = () => {
    let boardInfoObj = {
      START_DTTM: boardInfo.START_DTTM,
      END_DTTM: boardInfo.END_DTTM,
      ACTIVE_YN: boardInfo.ACTIVE,
      ORDER: boardInfo.ORDER_NUM,
      LINK: boardInfo.LINK,
      ID: boardInfo.ID,
    };

    if (Array.isArray(oldImgFiles) && oldImgFiles.length > 0) {
      boardInfoObj = {
        ...boardInfoObj,
        img_info: oldImgFiles,
      };
    }
    const formData = new FormData();
    formData.append("boardInfo", JSON.stringify(boardInfoObj));

    if (Array.isArray(boardInfo.IMG) && boardInfo.IMG.length > 0) {
      for (let i = 0; i < boardInfo.IMG.length; i++) {
        formData.append("img", boardInfo.IMG[i]);
      }
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post("/board/updatepop", formData, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            openAlert({
              title: "Notice",
              content: "팝업이 수정되었습니다.",
              submitEventHandler: () => props.submit(),
            })
          );
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (props.data.TYPE === "OLD") {
      console.log(props);
      // 파일 등록
      let imgFileInfo = props.data.ORI_FILE_NM;
      if (imgFileInfo !== "") {
        let tmp = [];
        tmp.push({
          FILE_NM: props.data.IMG,
          ORI_FILE_NM: props.data.ORI_FILE_NM,
          type: "loaded",
        });

        setOldImgFiles(tmp);
      }
      // 정보 등록

      setboardInfo({
        LINK: props.data.LINK,
        START_DTTM: props.data.START_DTTM,
        END_DTTM: props.data.END_DTTM,
        ACTIVE: props.data.ACTIVE === "Y" ? true : false,
        ORDER_NUM: props.data.ORDER_NUM,
        ID: props.data.ID,
        IMG: [],
      });
    } else {
      console.log();
      setOldImgFiles([]);
      setboardInfo({
        LINK: "",
        IMG: [],
        START_DTTM: "",
        END_DTTM: "",
        ACTIVE: true,
        ORDER_NUM: 0,
        ID: "",
      });
    }
  }, [props.data]);

  const submit = () => {
    if (check()) {
      if (props.data.TYPE === "OLD") {
        updateBoard();
      } else {
        writeBoard();
      }
    }
  };

  const check = () => {
    if (!boardInfo.IMG.length > 0 && !oldImgFiles.length > 0) {
      dispatch(
        openAlert({
          title: "Notice",
          content: "이미지를 등록해주세요.",
          submitEventHandler: () => console.log(""),
        })
      );
      return false;
    } else {
      return true;
    }
  };

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className={style.popupmodal} //모달 ClassName
      overlayClassName={style.popupmodal_overlay} ///모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <div className={style.popupmodal_wrapper}>
        <div className={style.pm_title}>
          {" "}
          {props.data.TYPE === "OLD" ? "팝업 수정" : "팝업 등록"}
        </div>
        <div className={style.pm_body}>
          <div className={style.pm_line}>
            <div className={style.pm_header}>배경 이미지 </div>
            <div className={style.pop_img} style={{ display: "flex" }}>
              <div className={style.contents_file_pop}>
                {oldImgFiles.length === 0 && boardInfo.IMG.length > 0 ? (
                  <div className={style.file_list_single}>
                    <div className={style.files_line}>
                      <div
                        className={style.img_name}
                        title={boardInfo.IMG[0].name}
                      >
                        {boardInfo.IMG[0].name}
                      </div>
                      <div className={style.file_remove + " cursor"}>
                        <img
                          src="/img/common/remove.png"
                          alt=""
                          className={style.file_remove}
                          onClick={() => onRemoveImg()}
                        />
                      </div>
                    </div>
                  </div>
                ) : oldImgFiles.length > 0 && boardInfo.IMG.length === 0 ? (
                  <div className={style.file_list_single}>
                    <div className={style.files_line}>
                      <div
                        className={style.img_name}
                        title={oldImgFiles[0].ORI_FILE_NM}
                      >
                        {oldImgFiles[0].ORI_FILE_NM}
                      </div>
                      <div className={style.file_remove + " cursor"}>
                        <img
                          src="/img/common/remove.png"
                          alt=""
                          className={style.file_remove}
                          onClick={() => onRemoveImg()}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={style.file_pop_none}>
                    배경이 등록되지 않았습니다.
                  </div>
                )}
              </div>
              <div className={style.contents_files_btn}>
                <label htmlFor="IMG" className={style.files_btn}>
                  <div className="cursor"> 이미지 등록</div>
                </label>
                <input
                  id="IMG"
                  className="new-class-file-input"
                  type="file"
                  style={{ display: "none" }}
                  name="IMG"
                  onChange={onChangeImgFile}
                  alt=""
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
            </div>
          </div>
          <div className={style.pm_line}>
            <div className={style.pm_header}>링크 </div>
            <div>
              <input
                className={style.pop_input}
                type="text"
                placeholder="링크를 입력하세요."
                value={boardInfo.LINK}
                onChange={(e) =>
                  setboardInfo({ ...boardInfo, LINK: e.target.value })
                }
              />
            </div>
          </div>
          <div className={style.pm_line}>
            <div className={style.pm_header}>시작시간 </div>
            <div>
              <input
                className={style.pop_input}
                type="datetime-local"
                value={boardInfo.START_DTTM}
                onChange={(e) =>
                  setboardInfo({ ...boardInfo, START_DTTM: e.target.value })
                }
              />
            </div>
          </div>
          <div className={style.pm_line}>
            <div className={style.pm_header}>종료시간 </div>
            <div>
              {" "}
              <input
                className={style.pop_input}
                type="datetime-local"
                value={boardInfo.END_DTTM}
                onChange={(e) =>
                  setboardInfo({ ...boardInfo, END_DTTM: e.target.value })
                }
              />
            </div>
          </div>
          <div className={style.pm_line}>
            <div className={style.pm_header}>순서 </div>
            <div>
              {" "}
              <input
                className={style.pop_input}
                type="number"
                value={boardInfo.ORDER_NUM}
                min={0}
                onChange={(e) =>
                  setboardInfo({ ...boardInfo, ORDER_NUM: e.target.value })
                }
              />
            </div>
          </div>
          <div className={style.pm_line}>
            <div className={style.pm_header}>활성화 여부 </div>
            <div>
              <input
                type="checkbox"
                checked={boardInfo.ACTIVE}
                onChange={(e) =>
                  setboardInfo({ ...boardInfo, ACTIVE: e.target.checked })
                }
              />
            </div>
          </div>

          <div className={style.aup_footer}>
            <div
              className={style.aup_close + " cursor"}
              onClick={() => props.close()}
            >
              닫기
            </div>
            <div
              className={style.aup_submit + " cursor"}
              onClick={() => submit()}
            >
              확인
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Adminpopuppop;
