import React, { useState } from "react";
import style from "css/support.module.css";
import Inputonsite from "./inputonsite";
import Postcode from "./postcode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/slices/alertSlice";
import Alertsubmit from "components/common/alertsubmit";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";
function Onsitesupport() {
  const [info, setinfo] = useState({
    partner_name: "",
    partner_manager: "",
    partner_pn: "",
    partner_cn: "",
    company_name: "",
    company_manager: "",
    company_pn: "",
    company_cn: "",
    email: "",
    location: "",
    location_detail: "",
    date: "",
    time: "00:00",
    version: "ko",
    type: "64",
    usecase: "",
    contents: "",
  });
  const [openpost, setopenpost] = useState(false);
  const postchange = (data) => {
    setinfo({ ...info, location: data.address });
    setopenpost(false);
  };

  const clospostpop = () => {
    setopenpost(false);
  };
  const openpostpop = () => {
    setopenpost(true);
  };

  const [submitalert, setsubmitalert] = useState(false);
  const closesubmit = () => {
    setsubmitalert(false);
    setinfo({
      partner_name: "",
      partner_manager: "",
      partner_pn: "",
      partner_cn: "",
      company_name: "",
      company_manager: "",
      company_pn: "",
      company_cn: "",
      email: "",
      location: "",
      location_detail: "",
      date: "",
      time: "00:00",
      version: "ko",
      type: "64",
      usecase: "",
      contents: "",
    });
  };

  const setdate = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
    setinfo({ ...info, date: e.target.value });
  };
  const setccn = (e) => {
    e = e
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
    setinfo({ ...info, company_cn: e });
  };
  const setpcn = (e) => {
    e = e
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
    setinfo({ ...info, partner_cn: e });
  };

  const selectversion = [
    { value: "ko", name: "한글판" },
    { value: "en", name: "영문판" },
  ];
  const selecttype = [
    { value: "64", name: "64bit" },
    { value: "32", name: "32bit" },
  ];

  const navigate = useNavigate();
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  const dispatch = useDispatch();
  const onsite = () => {
    if (check()) {
      axios
        .post("/open/onsite", info)
        .then((res) => {
          if (res.status === 200) {
            setsubmitalert(true);
          }
        })
        .catch((err) => {
          dispatch(
            openAlert({
              title: "Notice",
              content: err.response.data,
            })
          );
        });
    }
  };

  const check = () => {
    if (info.partner_name === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "파트너명을 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.partner_manager === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "파트너 담당자를 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.partner_pn === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "파트너 전화번호를 입력주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.partner_cn === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "파트너 핸드폰 번호를 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.company_name === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "회사명을 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.company_manager === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "회사 담당자를 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.company_pn === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "회사 연락처를 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.company_cn === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "회사 핸드폰 번호를 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.email === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "이메일을 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.location === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "주소를 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.location_detail === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "상세주소를 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.date === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "날짜를 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.time === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "시간을 선택해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.usecase === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "사용현황을 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.contents === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "요청사항을 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else {
      return true;
    }
  };

  const checkline = useMediaQuery({
    query: "(min-width: 1301px)",
  });
  const checkline2 = useMediaQuery({
    query: "(max-width: 1301px) and (min-width : 768px)",
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    { TITLE: "FAQ", LINK: "faq" },

    { TITLE: "원격지원", LINK: "online_support" },
  ];
  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/support/support_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.support_area}>
          <div className={style.support_title_wrap}>
            <div className={style.support_title}>지원</div>
            <div className={style.support_subtitle}>Support</div>
          </div>
        </div>
      </div>

      <div className={style.support_category}>
        {isTablet ? (
          <Mobilemenu selected="방문지원" list={menulist} type="" />
        ) : (
          <div className={style.support_category_buttons}>
            <div
              className={style.support_button + " cursor"}
              onClick={() => {
                menuHandler("faq");
              }}
            >
              FAQ
            </div>
            <div
              className={
                style.support_button + " " + style.button_focus + " cursor"
              }
            >
              방문지원
            </div>
            <div
              className={style.support_button + " cursor"}
              onClick={() => {
                menuHandler("online_support");
              }}
            >
              원격지원
            </div>
          </div>
        )}
      </div>

      <div className={style.onsite_wrapper}>
        <div className={style.onsite_body}>
          <div className={style.onsite_contents}>
            <div className={style.onsite_header}>
              <div className={style.onsite_title}>기술지원 요청서</div>
              <div className={style.onsite_subtitle}>
                <font className={style.onsite_subtitle2}>
                  {" "}
                  (주)블루티엔에스는 ZWCAD 모든 제품을 고객사
                </font>
                에 직접 판매합니다. {checkline2 ? <p /> : ""} 교육 서비스와 기술
                지원을 포함하는 최고의 서비스를 제공하고 있습니다.
                <p /> 구매 및 최고의 서비스를 받으시기를 위하시면{" "}
                {checkline ? <p /> : ""}
                다음 항목을 작성해서 정성껏 응대하겠습니다.
              </div>
            </div>
            <div className={style.onsite_input_section}>
              <div className={style.onsite_quote}>
                <font className={style.onsite_quote_mark}>*</font> 필수 입력
                항목
              </div>
              <div className={style.onsite_input_line}>
                <Inputonsite
                  header="파트너명"
                  placeholder="파트너사명을 입력하세요"
                  value={info.partner_name}
                  changetext={(value) =>
                    setinfo({ ...info, partner_name: value })
                  }
                />
                <Inputonsite
                  header="담당자"
                  placeholder="ex) 홍길동"
                  value={info.partner_manager}
                  changetext={(value) =>
                    setinfo({ ...info, partner_manager: value })
                  }
                />
              </div>
              <div className={style.onsite_input_line}>
                <Inputonsite
                  header="전화번호"
                  placeholder="ex) 02-1234-5678"
                  value={info.partner_pn}
                  changetext={(value) =>
                    setinfo({ ...info, partner_pn: value })
                  }
                />
                <Inputonsite
                  header="핸드폰"
                  placeholder="ex) 010-1234-5678"
                  value={info.partner_cn}
                  changetext={(e) => {
                    setpcn(e);
                  }}
                />
              </div>

              <div className={style.onsite_line} />

              <div className={style.onsite_input_line}>
                <Inputonsite
                  header="회사명"
                  placeholder="ex) 블루티엔에스"
                  value={info.company_name}
                  changetext={(value) =>
                    setinfo({ ...info, company_name: value })
                  }
                />
                <Inputonsite
                  header="담당자"
                  placeholder="ex) 홍길동"
                  value={info.company_manager}
                  changetext={(value) =>
                    setinfo({ ...info, company_manager: value })
                  }
                />
              </div>
              <div className={style.onsite_input_line}>
                <Inputonsite
                  header="연락처"
                  placeholder="ex) 02-1234-5678"
                  value={info.company_pn}
                  changetext={(value) =>
                    setinfo({ ...info, company_pn: value })
                  }
                />
                <Inputonsite
                  header="핸드폰"
                  placeholder="ex) 010-1234-5678"
                  value={info.company_cn}
                  changetext={(e) => {
                    setccn(e);
                  }}
                />
              </div>
              <div className={style.onsite_input_line}>
                <Inputonsite
                  header="이메일"
                  placeholder="ex) sample@email.com"
                  value={info.email}
                  changetext={(value) => setinfo({ ...info, email: value })}
                />
              </div>
              <div className={style.onsite_input_line}>
                {openpost ? (
                  <Postcode
                    open={openpost}
                    close={clospostpop}
                    locationchange={(value) => postchange(value)}
                  />
                ) : null}
                <div className={style.onsite_input_wrapper}>
                  <div className={style.onsite_input_header}>
                    주소
                    <span className={style.onsite_quote_mark}> *</span>
                  </div>
                  <div
                    className={style.onsite_input_post + " cursor"}
                    onClick={() => openpostpop()}
                  >
                    <input
                      className={style.onsite_post}
                      value={info.location}
                      type="text"
                      placeholder="ex) 서울시 서초구 강남대로 101길 43"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className={style.onsite_input_line}>
                <div className={style.onsite_input_wrapper}>
                  <div className={style.onsite_input_header}>
                    상세 주소
                    <span className={style.onsite_quote_mark}> *</span>
                  </div>
                  <div className={style.onsite_input_post}>
                    <input
                      className={style.onsite_post}
                      value={info.location_detail}
                      onChange={(e) =>
                        setinfo({ ...info, location_detail: e.target.value })
                      }
                      type="text"
                      placeholder="ex) 서울시 서초구 강남대로 101길 43"
                    />
                  </div>
                </div>
              </div>

              <div className={style.onsite_input_line}>
                <div className={style.onsite_input_wrapper}>
                  <div className={style.onsite_input_header}>
                    요청 일자
                    <span className={style.onsite_quote_mark}> *</span>
                  </div>
                  <div className={style.onsite_input_body}>
                    <input
                      className={style.onsite_input}
                      value={info.date}
                      onChange={(e) => setdate(e)}
                      type="text"
                      placeholder="0000-00-00"
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className={style.onsite_input_wrapper}>
                  <div className={style.onsite_input_header}>
                    요청 시간
                    <span className={style.onsite_quote_mark}> *</span>
                  </div>
                  <div className={style.onsite_input_body}>
                    <input
                      className={style.onsite_input_time}
                      value={info.time}
                      onChange={(e) =>
                        setinfo({ ...info, time: e.target.value })
                      }
                      type="time"
                      step="3600"
                      min="00:00"
                      max="23:59"
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  style.onsite_input_line + " " + style.onsite_input_flex
                }
              >
                <div
                  className={
                    style.onsite_input_wrapper + " " + style.onsite_sb1
                  }
                >
                  <div className={style.onsite_input_header}>
                    버전
                    <span className={style.onsite_quote_mark}> *</span>
                  </div>

                  <select
                    className={style.selectbox_onsite + " cursor "}
                    onChange={(e) =>
                      setinfo({ ...info, version: e.target.value })
                    }
                    value={info.version}
                  >
                    {selectversion.map((item) => (
                      <option
                        value={item.value}
                        key={item.value}
                        className={style.selectbox_list}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className={
                    style.onsite_input_wrapper + " " + style.onsite_sb2
                  }
                >
                  <div className={style.onsite_input_header_none}></div>

                  <select
                    className={
                      style.selectbox_onsite + " cursor" + style.onsite_sb2
                    }
                    onChange={(e) => setinfo({ ...info, type: e.target.value })}
                    value={info.type}
                  >
                    {selecttype.map((item) => (
                      <option
                        value={item.value}
                        key={item.value}
                        className={style.selectbox_list}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={style.onsite_input_line}>
                <div className={style.onsite_input_wrapper}>
                  <div className={style.onsite_input_header}>
                    사용 현황
                    <span className={style.onsite_quote_mark}> *</span>
                  </div>
                  <div className={style.onsite_input_post}>
                    <input
                      className={style.onsite_post}
                      value={info.usecase}
                      onChange={(e) =>
                        setinfo({ ...info, usecase: e.target.value })
                      }
                      type="text"
                      placeholder="사용현황을 입력해주세요"
                    />
                  </div>
                </div>
              </div>
              <div className={style.onsite_input_line}>
                <div className={style.onsite_input_wrapper}>
                  <div className={style.onsite_input_header}>
                    요청 내용
                    <span className={style.onsite_quote_mark}> *</span>
                  </div>
                  <div className={style.onsite_input_textarea}>
                    <textarea
                      className={style.onsite_textarea}
                      value={info.contents}
                      onChange={(e) =>
                        setinfo({ ...info, contents: e.target.value })
                      }
                      type="text"
                      placeholder="요청사항을 입력해주세요"
                    />
                  </div>
                </div>
              </div>
              <Alertsubmit
                title="접수 완료"
                text1="방문신청이 접수되었습니다."
                text2="담당자가 곧 연락드리겠습니다."
                text3="감사합니다."
                open={submitalert}
                close={closesubmit}
              />
              <div
                className={style.send_button + " cursor"}
                onClick={() => onsite()}
              >
                방문지원 신청
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Onsitesupport;
