import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/board.module.css";
import axios from "axios";
import Paging from "components/common/paging";
import { useDispatch } from "react-redux";
import { openAlert, openConfirm } from "redux/slices/alertSlice";
import State from "pages/common/state";
import Adminrequest from "./adminrequest";
import Adminrequestdata from "./adminrequestdata";

function Adminonsite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boardlist, setboardlist] = useState([]);
  const [init, setInit] = useState(false);
  const [pagerun, setpagerun] = useState(true);
  const [open, setopen] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 10,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "onsite",
        ROW_COUNT: page.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/board/supportlist", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const getcount = useCallback(() => {
    let config = {
      params: {
        TYPE: "onsite",
      },
    };

    axios.get("/board/supportcounts", config).then((res) => {
      if (res.status === 200) {
        setPage({
          ...page,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun(false);
        setInit(true);
      }
    });
  }, [page]);

  useEffect(() => {
    if (pagerun) {
      getcount();
    }
  }, [getcount, pagerun]);

  const clickline = (index) => {
    for (let i = 0; i < open.length; i++) {
      if (open[i] === index) {
      }
    }
  };

  return (
    <div className={style.request_wrapper}>
      <div className={style.request_body}>
        <div className={style.request_list_title}>
          <div>방문지원 확인 페이지</div>
        </div>
        <div>
          <div className={style.free_list_header}>
            <div className={style.r10}>신청자</div>
            <div className={style.r10}>연락처</div>
            <div className={style.r10}>회사명</div>
            <div className={style.r10}>이메일</div>
            <div className={style.r10}>요청 일자</div>
            <div className={style.r10}>요청 시간</div>
            <div className={style.r20}>사용 현황</div>
            <div className={style.r10}>접수일</div>

            <div className={style.r10}>접수상태</div>
          </div>
          <div className={style.board_list_body}>
            {boardlist.length > 0
              ? boardlist.map((data, index) => (
                  // <div>
                  //   <div
                  //     className={style.free_list_body_line}
                  //     onClick={() => {
                  //       clickline(index);
                  //     }}
                  //   >
                  //     <div className={style.r10}>{data.COMPANY_MANAGER}</div>
                  //     <div className={style.r10}>{data.COMPANY_CN}</div>
                  //     <div className={style.r10}>{data.COMPANY_NAME}</div>
                  //     <div className={style.r10}>{data.EMAIL}</div>
                  //     <div className={style.r10}>{data.DATE}</div>
                  //     <div className={style.r10}>{data.TIME}</div>
                  //     <div className={style.r20}>{data.USECASE}</div>
                  //     <div className={style.r10}>{data.INPUT_DT}</div>
                  //     <div
                  //       className={style.r10}
                  //       onClick={
                  //         ((e) => e.preventDefault(),
                  //         (e) => e.stopPropagation())
                  //       }
                  //     >
                  //       <div>
                  //         <State
                  //           state={data.STATUS}
                  //           TYPE="ONSITE"
                  //           ID={data.ID}
                  //           action={() => getlist()}
                  //         />
                  //       </div>
                  //     </div>
                  // </div>

                  // </div>
                  <Adminrequestdata data={data} getlist={() => getlist()} />
                ))
              : ""}
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Paging
              init={init}
              setInit={setInit}
              totalCount={page.totalCount}
              rowCount={page.rowCount}
              currentPage={page.currentPage}
              maxPage={page.maxPage}
              onClick={(res) => {
                setPage({ ...page, currentPage: res });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminonsite;
