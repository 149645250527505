import React, { useEffect, useState } from "react";
import style from "css/common.module.css";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
function Underbar(props) {
  const [ref, inView] = useInView({
    triggerOnce: false, // 기본 : false
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollbar, setScrollbar] = useState(false);
  // Scroll 위치를 감지
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition < 100) {
      setScrollbar(false);
    } else {
      if (inView) {
        setScrollbar(false);
      } else {
        setScrollbar(true);
      }
    } // eslint-disable-next-line
  }, [scrollPosition]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const click_button = (type) => {
    switch (type) {
      case "c1":
        navigate(props.c1_link);
        break;
      case "c2":
        if (props.c2_link !== "" && props.c2_link !== undefined) {
          window.open(props.c2_link);
        } else {
          dispatch(
            openAlert({
              title: "Notice",
              content: "준비 중 입니다.",
              submitEventHandler: () => {},
            })
          );
        }
        break;
      case "c3":
        navigate(process.env.REACT_APP_CLIENT_URL + "free");
        break;
      case "c4":
        navigate(process.env.REACT_APP_CLIENT_URL + "request");
        break;
      case "c5":
        if (props.c5_link !== "" && props.c5_link !== undefined) {
          window.open(props.c5_link);
        } else {
          dispatch(
            openAlert({
              title: "Notice",
              content: "준비 중 입니다.",
              submitEventHandler: () => {},
            })
          );
        }

        break;
      case "c6":
        window.open(props.c6_link);
        break;
      case "c7":
        window.open(props.c7_link);
        break;
      default:
        break;
    }
  };

  const [mobileactive, setmobileactive] = useState(false);

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  return (
    <>
      {mobileactive ? <div className={style.blur} /> : ""}
      <div className={style.ref_bar} ref={ref}></div>
      {isTablet ? (
        <div
          className={
            scrollbar
              ? mobileactive
                ? style.mobilebar_active
                : style.bar_active
              : style.bar_hidden
          }
        >
          {mobileactive ? (
            <div className={style.mobilebar_body}>
              <div
                className={style.close + " cursor"}
                onClick={() => setmobileactive(false)}
              ></div>
              <div className={style.title}>{props.c1}</div>
              <div className={style.downlinxbox}>
                {props.c2_use ? (
                  <div
                    className={style.downlink + " cursor"}
                    onClick={() => click_button("c2")}
                  >
                    제품 소개서 다운로드
                    <img
                      alt=""
                      className={style.download_img}
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/common/bar_down.png"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {props.c5_use ? (
                  <div
                    className={style.downlink + " cursor"}
                    onClick={() => click_button("c5")}
                  >
                    {props.c5}
                    <img
                      alt=""
                      className={style.download_img}
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/common/bar_down.png"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {props.c6_use ? (
                  <div
                    className={style.downlink + " cursor"}
                    onClick={() => click_button("c6")}
                  >
                    {props.c6}
                    <img
                      alt=""
                      className={style.download_img}
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/common/bar_link_m.png"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={style.buttons}>
                <div
                  className={style.freebtn}
                  onClick={() => click_button("c3")}
                >
                  무료체험 신청
                </div>
                <div
                  className={style.requestbtn}
                  onClick={() => click_button("c4")}
                >
                  {" "}
                  견적 신청
                </div>
              </div>
            </div>
          ) : (
            <div className={scrollbar ? style.bar_active : style.bar_active}>
              <div
                className={style.bar_button + " cursor"}
                onClick={() => setmobileactive(!mobileactive)}
              >
                견적 및 무료체험 신청
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={scrollbar ? style.bar_active : style.bar}>
          <div className={style.bar_contents}>
            <div className={style.bar_left}>
              {props.c1_use ? (
                <div
                  className={style.bar_c1 + " cursor"}
                  onClick={() => click_button("c1")}
                >
                  {props.c1}
                </div>
              ) : (
                <></>
              )}
              {props.c2_use ? (
                <div
                  className={style.bar_c2 + " cursor"}
                  onClick={() => click_button("c2")}
                >
                  제품 소개서 다운로드{" "}
                  <img
                    alt=""
                    className={style.download_img}
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/foxit/editor_down.png"
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              {props.c5_use ? (
                <div
                  className={style.bar_c5 + " cursor"}
                  onClick={() => click_button("c5")}
                >
                  {props.c5}
                  <img
                    alt=""
                    className={style.download_img}
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/foxit/editor_down.png"
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              {props.c6_use ? (
                <div
                  className={style.bar_c5 + " cursor"}
                  onClick={() => click_button("c6")}
                >
                  {props.c6}
                  <img
                    alt=""
                    className={style.download_img}
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/common/bar_link.png"
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={style.bar_right}>
              {props.c3_use ? (
                <div
                  className={style.bar_c3 + " cursor"}
                  onClick={() => click_button("c3")}
                >
                  무료체험 신청
                </div>
              ) : (
                <></>
              )}
              {props.c4_use ? (
                <div
                  className={style.bar_c4 + " cursor"}
                  onClick={() => click_button("c4")}
                >
                  견적 신청
                </div>
              ) : (
                <></>
              )}
              {props.c7_use ? (
                <div
                  className={style.bar_c4 + " cursor"}
                  onClick={() => click_button("c7")}
                >
                  다운 로드
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Underbar;
