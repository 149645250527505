import React, { useState } from "react";

import style from "css/contact.module.css";
import Inputcontact from "./inputonsite";
import Postcode from "./postcode";
import axios from "axios";
import { openAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import Contactalert from "components/common/contactalert";
import { useMediaQuery } from "react-responsive";

function Freeexperience() {
  const [info, setinfo] = useState({
    manager: "",
    phone: "",
    company: "",
    email: "",
    location: "",
    location_detail: "",
    version: "ko",
    type: "64",
    product: "",
  });
  const [openpost, setopenpost] = useState(false);
  const postchange = (data) => {
    setinfo({ ...info, location: data.address });
    setopenpost(false);
  };

  const clospostpop = () => {
    setopenpost(false);
  };
  const openpostpop = () => {
    setopenpost(true);
  };

  const selectversion = [
    { value: "ko", name: "한글판" },
    { value: "en", name: "영문판" },
  ];
  const selecttype = [
    { value: "64", name: "64bit" },
    { value: "32", name: "32bit" },
  ];
  const selectproduct = [
    { value: "", name: "제품 선택" },
    { value: "ZWCAD2025", name: "ZWCAD2025" },
    { value: "ZDREAM", name: "ZDREAM" },
    { value: "FOXITEDITOR", name: "Foxit PDF Editor" },
  ];

  const setccn = (e) => {
    e = e
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
    setinfo({ ...info, phone: e });
  };

  const dispatch = useDispatch();

  const free = () => {
    if (check()) {
      axios
        .post("/open/free", info)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              openAlert({
                title: "Notice",
                content: "무료체험 요청이 완료되었습니다.",
                submitEventHandler: () =>
                  setinfo({
                    manager: "",
                    phone: "",
                    company: "",
                    email: "",
                    location: "",
                    location_detail: "",
                    version: "ko",
                    type: "64",
                    product: "",
                  }),
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            openAlert({
              title: "Notice",
              content: err.response.data,
            })
          );
        });
    }
  };

  const check = () => {
    if (info.product === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "제품을 선택해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.manager === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "신청자를 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.phone === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "연락처를 입력주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.company === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "회사명을 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.location === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "주소를 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.location_detail === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "상세 주소를 입력해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.version === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "버전을 선택해주세요.",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else {
      return true;
    }
  };

  const [opencontect, setopencontect] = useState(false);
  const [contactinfo, setcontactinfo] = useState({
    header: "",
    body: "",
  });
  const closecontectpop = () => {
    setopencontect(false);
  };
  const opencontectpop = () => {
    setopencontect(true);
  };
  const checkline = useMediaQuery({
    query: "(min-width: 1301px)",
  });

  const ismobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  return (
    <>
      <Contactalert
        open={opencontect}
        close={closecontectpop}
        header={contactinfo.header}
        body={contactinfo.body}
      />
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/contact/free_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.contact_area}>
          <div className={style.contact_title_wrap}>
            <div className={style.contact_title}>무료체험</div>
            <div className={style.contact_subtitle}>
              Application for free experience
            </div>
            <div className={style.contact_subtitle2}>
              ZWCAD2025 | ZDREAM | Foxit PDF Editor 전용
            </div>
          </div>
        </div>
      </div>
      <div className={style.contact_wrapper}>
        <div className={style.contact_body}>
          <div className={style.contact_contents}>
            <div className={style.contact_header}>
              <div className={style.contact_title_2}>
                NEW EVENT <p />{" "}
                <label className={style.contact_title_3}>첫 구매 </label>
                고객에게 {checkline ? <p /> : ""}
                특별한 {ismobile ? <p /> : ""} 혜택을{checkline ? <p /> : ""}{" "}
                제공합니다.
              </div>
            </div>
            <div className={style.contact_body_wrapper}>
              <div className={style.contact_quote}>
                <font className={style.contact_quote_mark}>*</font> 필수 입력
                항목
              </div>
              <div className={style.contact_input_line}>
                <div className={style.contact_input_wrapper}>
                  <div className={style.contact_input_header}>
                    제품
                    <span className={style.contact_quote_mark}> *</span>
                  </div>

                  <select
                    className={style.selectbox_free + " cursor"}
                    onChange={(e) =>
                      setinfo({ ...info, product: e.target.value })
                    }
                    value={info.product}
                  >
                    {selectproduct.map((item) => (
                      <option
                        value={item.value}
                        key={item.value}
                        className={style.selectbox_list + " cursor"}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                className={
                  ismobile ? style.cotact_input_flex : style.contact_input_line
                }
              >
                <div
                  className={
                    style.contact_input_wrapper + " " + style.input_wrapper2
                  }
                >
                  <div className={style.contact_input_header}>
                    버전
                    <span className={style.contact_quote_mark}> *</span>
                  </div>

                  <select
                    className={style.selectbox_free + " cursor"}
                    onChange={(e) =>
                      setinfo({ ...info, version: e.target.value })
                    }
                    value={info.version}
                  >
                    {selectversion.map((item) => (
                      <option
                        value={item.value}
                        key={item.value}
                        className={style.selectbox_list + " cursor"}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className={
                    style.contact_input_wrapper + " " + style.input_wrapper3
                  }
                >
                  <div className={style.contact_input_header_none}></div>

                  <select
                    className={style.selectbox_free + " cursor"}
                    onChange={(e) => setinfo({ ...info, type: e.target.value })}
                    value={info.type}
                  >
                    {selecttype.map((item) => (
                      <option
                        value={item.value}
                        key={item.value}
                        className={style.selectbox_list + " cursor"}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={style.contact_input_line}>
                <Inputcontact
                  header="신청자"
                  placeholder="ex) 홍길동"
                  value={info.manager}
                  changetext={(value) => setinfo({ ...info, manager: value })}
                />
                <Inputcontact
                  header="연락처"
                  placeholder="ex) 010-1234-5678"
                  value={info.phone}
                  changetext={(e) => {
                    setccn(e);
                  }}
                />
              </div>
              <div className={style.contact_input_line}>
                <Inputcontact
                  header="회사명"
                  placeholder="ex) 블루티엔에스"
                  value={info.company}
                  changetext={(value) => setinfo({ ...info, company: value })}
                />
                <Inputcontact
                  header="이메일"
                  placeholder="ex) sample@email.com"
                  value={info.email}
                  check={true}
                  changetext={(value) => setinfo({ ...info, email: value })}
                />
              </div>
              <div className={style.contact_input_line}></div>
              <div className={style.contact_input_line}>
                {openpost ? (
                  <Postcode
                    open={openpost}
                    close={clospostpop}
                    locationchange={(value) => postchange(value)}
                  />
                ) : null}
                <div className={style.contact_input_wrapper}>
                  <div className={style.contact_input_header}>
                    주소
                    <span className={style.contact_quote_mark}> *</span>
                  </div>
                  <div
                    className={style.contact_input_post + " cursor"}
                    onClick={() => openpostpop()}
                  >
                    <input
                      className={style.contact_post}
                      value={info.location}
                      type="text"
                      placeholder="ex) 서울시 서초구 강남대로 101길 43"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className={style.contact_input_line}>
                <div className={style.contact_input_wrapper}>
                  <div className={style.contact_input_header}>
                    상세 주소
                    <span className={style.contact_quote_mark}> *</span>
                  </div>
                  <div className={style.contact_input_post}>
                    <input
                      className={style.contact_post}
                      value={info.location_detail}
                      onChange={(e) =>
                        setinfo({ ...info, location_detail: e.target.value })
                      }
                      type="text"
                      placeholder="ex) 서울시 서초구 강남대로 101길 43"
                    />
                  </div>
                </div>
              </div>
              <div
                className={style.send_button + " cursor"}
                onClick={() => free()}
              >
                무료체험 신청
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Freeexperience;
