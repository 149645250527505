import React from "react";
import style from "css/landing.module.css";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";

/**
 * 파일명: NoticeListItem.js
 * 작성자: 강연승
 * 작성일자: 24.02.15
 * 설명: landing page - NoticeArea - NoticeListItem
 */

function NoticeListItem(props) {
  const convertHTML = (value) => {
    return (
      <div
        className={style.innertext}
        dangerouslySetInnerHTML={{ __html: value }}
      ></div>
    );
  };
  const navigate = useNavigate();

  return (
    <div
      className={`${style.notice_item} cursor`}
      onClick={() =>
        navigate(
          process.env.REACT_APP_CLIENT_URL +
            "community?id=" +
            props.notice.BOARD_ID +
            "&type=notice"
        )
      }
    >
      <div className={style.notice_info_wrap}>
        <div className={style.notice_info_container}>
          <div className={style.notice_label}>공지사항</div>
          <div className={style.notice_title}>{props.notice.TITLE}</div>
          <div className={style.notice_text}>
            {convertHTML(props.notice.TEXT)}
          </div>
        </div>
        <div className={style.notice_input_date}>{props.notice.INPUT_DT}</div>
      </div>

      {props.notice.THUMBNAIL === "" ? (
        <Skeleton.Image active style={{ width: "100%", height: "260px" }} />
      ) : (
        <img
          className={style.notice_img}
          alt=""
          src={process.env.REACT_APP_CLIENT_URL + props.notice.THUMBNAIL}
        />
      )}
    </div>
  );
}

export default NoticeListItem;
