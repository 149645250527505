import React, { useEffect, useState } from "react";
import style from "css/zwcad.module.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

/**
 * Version : 1.0
 * 작성일자 : 2024-03-06
 * 작성자 : 강연승
 * 설명 : CAD 제품 비교 페이지
 */

function CadCompare() {
  //navigate
  const navigate = useNavigate();
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const [index1, setindex1] = useState(true);
  const [index2, setindex2] = useState(false);
  const [index3, setindex3] = useState(false);
  const [index4, setindex4] = useState(false);
  const [index5, setindex5] = useState(false);
  const [index6, setindex6] = useState(false);
  const [index7, setindex7] = useState(false);
  const [index8, setindex8] = useState(false);

  const compareList = [
    {
      index: "1",
      header: "지원 파일",
      open: index1,
      contents: [
        {
          text: "DWG / DXF R14 / 2000 / 2004 / 2007 / 2010 / 2013 / 2018",
          value: [true, true, true, true],
        },
        {
          text: "DXF R12 / 2000 / 2004 / 2007 / 2010 / 2013",
          value: [true, true, true, true],
        },
        {
          text: "DWG / DXF 2018",
          value: [true, true, true, true],
        },
        {
          text: "DWS, DWT 지원",
          value: [true, true, true, true],
        },
        {
          text: "DGN",
          value: [true, true, true, false],
        },
      ],
    },
    {
      index: "2",
      header: "인터페이스",
      open: index2,
      contents: [
        {
          text: "올가미 선택",
          value: [true, true, true, true],
        },
        {
          text: "적응형 그리드",
          value: [true, true, true, true],
        },
        {
          text: "클래식 메뉴 및 도구 바",
          value: [true, true, true, true],
        },
        {
          text: "사용자화 인터페이스 (CUI)",
          value: [true, true, true, true],
        },
        {
          text: "디자인 센터",
          value: [true, true, true, true],
        },
        {
          text: "도면 탭 전환",
          value: [true, true, true, true],
        },
        {
          text: "동적 입력",
          value: [true, true, true, true],
        },
        {
          text: "동적 UCS",
          value: [true, true, true, true],
        },

        {
          text: "다중 CPU 속도 향상",
          value: [true, true, true, true],
        },
        {
          text: "부분 CUI",
          value: [true, true, true, true],
        },
        {
          text: "특성 팔레트",
          value: [true, true, true, true],
        },
        {
          text: "리본 인터페이스",
          value: [true, true, true, true],
        },
        {
          text: "도구 팔레트",
          value: [true, true, true, true],
        },
        {
          text: "투명 명령",
          value: [true, true, true, true],
        },
      ],
    },
    {
      index: "3",
      header: "2D 도면 및 주석",
      open: index3,
      contents: [
        {
          text: "좌표 치수",
          value: [true, true, false, false],
        },
        {
          text: "주석 축척",
          value: [true, true, true, true],
        },
        {
          text: "연관 치수",
          value: [true, true, true, true],
        },
        {
          text: "향상된 그립 편집 메뉴",
          value: [true, true, true, true],
        },
        {
          text: "필드",
          value: [true, true, true, true],
        },
        {
          text: "해치 및 해치 그라데이션",
          value: [true, true, true, true],
        },
        {
          text: "지시선 및 다중 지시선",
          value: [true, true, true, true],
        },
        {
          text: "다중 지시선 정렬",
          value: [true, true, true, true],
        },

        {
          text: "폴리선 및 해치 경계 그립",
          value: [true, true, true, true],
        },
        {
          text: "구름 주석 기호",
          value: [true, true, true, true],
        },
        {
          text: "슈퍼 해치",
          value: [true, true, true, true],
        },
        {
          text: "테이블 및 테이블 스타일",
          value: [true, true, true, true],
        },
        {
          text: "문자 및 다중 문자",
          value: [true, true, true, true],
        },
        {
          text: "문자 편집모드",
          value: [true, true, true, true],
        },
      ],
    },
    {
      index: "4",
      header: "외부참조, 블록 및 속성",
      open: index4,
      contents: [
        {
          text: "속성 블록",
          value: [true, true, true, true],
        },
        {
          text: "블록 정렬",
          value: [true, true, true, true],
        },
        {
          text: "플렉시 블록 (동적 블록 대체 기능)",
          value: [true, true, true, true],
        },
        {
          text: "블록 편집기",
          value: [true, true, true, true],
        },
        {
          text: "블록 교체",
          value: [true, true, true, true],
        },
        {
          text: "DGN 언더레이",
          value: [true, true, true, true],
        },
        {
          text: "DGN 내보내기",
          value: [true, true, true, true],
        },
        {
          text: "DWF 언더레이",
          value: [true, true, true, true],
        },

        {
          text: "DWFx 언더레이",
          value: [true, true, true, true],
        },
        {
          text: "다중 블록 삽입",
          value: [true, true, false, true],
        },
        {
          text: "OLE 객체 삽입",
          value: [true, true, true, true],
        },
        {
          text: "PDF 삽입",
          value: [true, true, true, true],
        },
        {
          text: "PDF 언더레이",
          value: [true, true, true, true],
        },
        {
          text: "래스터 이미지",
          value: [true, true, true, true],
        },
        {
          text: "외부 참조",
          value: [true, true, true, true],
        },
      ],
    },
    {
      index: "5",
      header: "프린트",
      open: index5,
      contents: [
        {
          text: "배치 플롯",
          value: [true, true, false, false],
        },
        {
          text: "CTB 및 STB 플롯 스타일",
          value: [true, true, true, true],
        },
        {
          text: "내보내기",
          value: [true, true, true, true],
        },
        {
          text: "Mvsetup",
          value: [true, true, true, true],
        },
        {
          text: "음영 플롯",
          value: [true, true, true, true],
        },
        {
          text: "SVG 플롯",
          value: [true, true, false, false],
        },
        {
          text: "출력",
          value: [true, true, true, true],
        },
        {
          text: "DWF/DWFx 게시",
          value: [true, true, true, true],
        },

        {
          text: "PDF 게시",
          value: [true, true, true, true],
        },
      ],
    },
    {
      index: "6",
      header: "3D 모델링 & 화면 표시 기능",
      open: index6,
      contents: [
        {
          text: "Flatshot",
          value: [false, true, false, true],
        },
        {
          text: "3D 궤도",
          value: [true, true, false, true],
        },
        {
          text: "ACIS 미리보기",
          value: [true, true, true, true],
        },
        {
          text: "3D 모델링 & 편집",
          value: [false, true, false, true],
        },
        {
          text: "렌더링",
          value: [false, true, false, true],
        },
        {
          text: "Solprof(3D 파일 2D 변환)",
          value: [false, true, false, true],
        },
        {
          text: "STL 내보내기",
          value: [false, true, false, true],
        },
        {
          text: "뷰 스타일 (숨기기 & 음영)",
          value: [true, true, false, true],
        },

        {
          text: "Z축 추적",
          value: [true, true, false, "(1)"],
        },
      ],
    },
    {
      index: "7",
      header: "도구",
      open: index7,
      contents: [
        {
          text: "빠른 특성 패널",
          value: [true, true, true, true],
        },
        {
          text: "포인트 클라우드",
          value: [false, true, false, true],
        },
        {
          text: "면적 테이블",
          value: [true, true, false, false],
        },
        {
          text: "시트 세트 관리자",
          value: [true, true, true, true],
        },
        {
          text: "3D 마우스 지원",
          value: [true, true, true, true],
        },
        {
          text: "ArcGIS 모듈",
          value: [false, true, false, false],
        },
        {
          text: "향상된 배열(경로 배열)",
          value: [true, true, true, true],
        },
        {
          text: "CAD 표준",
          value: [true, true, true, true],
        },

        {
          text: "데이터링크",
          value: [true, true, true, true],
        },
        {
          text: "데이터 추출",
          value: [true, true, false, true],
        },
        {
          text: "DGN Purge",
          value: [false, true, true, true],
        },
        {
          text: "dwfx 디지털 서명",
          value: [true, true, false, false],
        },
        {
          text: "dwg 디지털 서명",
          value: [true, true, true, true],
        },
        {
          text: "전자전송",
          value: [true, true, true, true],
        },
        {
          text: "dwg 파일비교",
          value: [true, true, true, true],
        },
        {
          text: "테이블 수식",
          value: [true, true, true, true],
        },
        {
          text: "그룹",
          value: [true, true, true, true],
        },
        {
          text: "배치 내보내기",
          value: [true, true, true, true],
        },
        {
          text: "잠금 및 잠금 해제",
          value: [true, true, false, false],
        },
        {
          text: "특성 일치(도면간)",
          value: [true, true, true, true],
        },
        {
          text: "다중 실행 취소/다시 실행",
          value: [true, true, true, true],
        },
        {
          text: "빠른 계산기",
          value: [true, true, true, true],
        },
        {
          text: "빠른 선택",
          value: [true, true, true, true],
        },
        {
          text: "영역(Region)",
          value: [false, true, true, true],
        },
        {
          text: "설정 마이그레이션",
          value: [true, true, true, true],
        },
        {
          text: "스마트 마우스",
          value: [true, true, false, false],
        },
        {
          text: "스마스 선택",
          value: [true, true, false, false],
        },
        {
          text: "스마트 음성",
          value: [true, true, false, false],
        },
        {
          text: "스마트 플롯",
          value: [true, true, false, false],
        },
      ],
    },
    {
      index: "8",
      header: "외부참조, 블록 및 속성",
      open: index8,
      contents: [
        {
          text: "ActiveX, including in-place editing",
          value: [true, true, true, true],
        },
        {
          text: "Full LISP with vl-, vlr-, vla- and vlax- support",
          value: [true, true, true, true],
        },
        {
          text: "LISP Debugger",
          value: [true, true, true, true],
        },
        {
          text: "DCL engine",
          value: [true, true, true, true],
        },
        {
          text: "Visual Basic for Applications (VBA 32/64-bit)",
          value: [false, true, false, true],
        },
        {
          text: "Runtime extension (ZRX/ARX)",
          value: [false, true, false, true],
        },
        {
          text: ".NET",
          value: [false, true, false, true],
        },
      ],
    },
  ];

  const make_compare = (contents) => {
    return (
      <div className={style.contents_box}>
        <div
          className={style.tite_line + " cursor"}
          onClick={
            contents.index === "1"
              ? () => setindex1(!index1)
              : contents.index === "2"
              ? () => setindex2(!index2)
              : contents.index === "3"
              ? () => setindex3(!index3)
              : contents.index === "4"
              ? () => setindex4(!index4)
              : contents.index === "5"
              ? () => setindex5(!index5)
              : contents.index === "6"
              ? () => setindex6(!index6)
              : contents.index === "7"
              ? () => setindex7(!index7)
              : contents.index === "8"
              ? () => setindex8(!index8)
              : () => setindex1(!index1)
          }
        >
          <div className={style.title_text}>
            <div className={style.title_index}>{contents.index}</div>
            <div className={style.title_name}>{contents.header}</div>
          </div>
          <div>
            {contents.open ? (
              <img
                alt=""
                className={style.title_arrow}
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/common/arrow_up.png"
                }
              />
            ) : (
              <img
                alt=""
                className={style.title_arrow}
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/common/arrow_down.png"
                }
              />
            )}
          </div>
        </div>
        <div className={contents.open ? style.listopen : style.listhidden}>
          {contents.contents.map((contents, index) => (
            <div className={style.zdream_detail}>
              <div className={style.contents_text}>{contents.text}</div>
              {contents.value.map((value, index) => (
                <div
                  className={
                    isTablet
                      ? style.zdream_detail_contents + " " + style.wid15
                      : isMobile
                      ? style.zdream_detail_contents + " " + style.wid15
                      : style.zdream_detail_contents
                  }
                >
                  {index < 2 ? (
                    <div className={style.contents_blue}>
                      {value ? (
                        <img alt="" src="/img/zwcad/compare_check.png" />
                      ) : value === false ? (
                        <img alt="" src="/img/zwcad/compare_none.png" />
                      ) : (
                        value
                      )}
                    </div>
                  ) : (
                    <div className={style.contents_sky}>
                      {value === "(1)" ? (
                        "(1)"
                      ) : value ? (
                        <img alt="" src="/img/zwcad/compare_check.png" />
                      ) : (
                        <img alt="" src="/img/zwcad/compare_none.png" />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const menulist = [
    { TITLE: "ZWCAD 2025", LINK: "zwcad" },
    { TITLE: "ZW3D 2025", LINK: "zw3d" },
    { TITLE: "ZDREAM", LINK: "zdream" },
    { TITLE: "ZW3D 제품 비교", LINK: "zw3d_compare" },
  ];

  // const isTablet = useMediaQuery({
  //   query: "(max-width: 1024px)",
  // });
  return (
    <>
      <div className={style.wrapper}>
        <div
          className={style.background}
          style={
            isMobile
              ? {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/compare_m_bg.png')",
                }
              : {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/compare_bg.png')",
                }
          }
        >
          {isMobile ? (
            <img alt="" src="/img/zwcad/compare_m_bg_img.png" />
          ) : (
            <></>
          )}
        </div>
        <div className={style.zwcad_area}>
          <div className={style.zwcad_title_wrap}>
            <div className={style.zwcad_title}>CAD 제품 비교</div>
            <div className={style.zwcad_subtitle}>
              Flexible Design, Fast Machining
            </div>
            <div className={style.zwcad_title_btn_wrap}></div>
          </div>
        </div>
      </div>

      {isTablet ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="CAD 제품 비교" list={menulist} type="zwcad" />
        </div>
      ) : isMobile ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="CAD 제품 비교" list={menulist} type="zwcad" />
        </div>
      ) : (
        <div className={style.zwcad_category}>
          <div className={style.zwcad_category_btn_wrap}>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zwcad")}
            >
              ZWCAD 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d")}
            >
              ZW3D 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zdream")}
            >
              ZDREAM
            </div>
            <div
              className={
                style.zwcad_category_btn + " " + style.button_focus + " cursor"
              }
              onClick={() => menuHandler("cad_compare")}
            >
              CAD 제품 비교
            </div>

            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d_compare")}
            >
              ZW3D 제품 비교
            </div>
          </div>
        </div>
      )}

      <div className={style.compare_wrapper}>
        <div className={style.compare_header}>
          <font className={style.ch_1}>ZWCAD 2025</font>
          <font className={style.ch_2}> VS</font>{" "}
          <font className={style.ch_1}>A사 CAD</font> 비교표
        </div>
        <div className={style.compare_body}>
          <div className={style.compare_line}>
            <div className={style.compare_content1}>제품명</div>
            <div className={style.compare_content2}>ZWCAD 2025</div>
            <div className={style.compare_content3}>A사 CAD 2025</div>
          </div>
          <div className={style.compare_line}>
            <div className={style.compare_content5}>버전</div>
            <div className={style.compare_content4}>
              <div className={style.compare_blue1}>LT</div>
              <div className={style.compare_blue2}>FULL</div>
            </div>
            <div className={style.compare_content4}>
              <div className={style.compare_sky1}>LT</div>
              <div className={style.compare_sky2}>FULL</div>
            </div>
          </div>
          <div className={style.compare_list}>
            {compareList.map((data, index) => make_compare(data))}
          </div>
        </div>
        <div className={style.compare_quote}>
          ※ (1) AutoCAD FULL에서는 불가능하며 Architecture 버전에서만 가져오기할
          수 있습니다.
        </div>
      </div>
    </>
  );
}

export default CadCompare;
