import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "redux/slices/alertSlice";
import userSlice from "redux/slices/userSlice";

/**
 * 파일명: store.js
 * 작성자: 권도훈
 * 작성일자: 23.04.06
 * 설명: redux store
 */
const store = configureStore({
  reducer: {
    alert: alertSlice.reducer,
    user: userSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
