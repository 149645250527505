import React from "react";
import style from "css/support.module.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

function Onlinesupport() {
  const navigate = useNavigate();
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    { TITLE: "FAQ", LINK: "faq" },

    // { TITLE: "방문지원", LINK: "onsite_support" },
  ];
  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/support/support_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.support_area}>
          <div className={style.support_title_wrap}>
            <div className={style.support_title}>지원</div>
            <div className={style.support_subtitle}>Support</div>
          </div>
        </div>
      </div>

      <div className={style.support_category}>
        {isTablet ? (
          <Mobilemenu selected="원격지원" list={menulist} type="" />
        ) : (
          <div className={style.support_category_buttons}>
            <div
              className={style.support_button + " cursor"}
              onClick={() => {
                menuHandler("faq");
              }}
            >
              FAQ
            </div>
            {/* <div
              className={style.support_button + " cursor"}
              onClick={() => {
                menuHandler("onsite_support");
              }}
            >
              방문지원
            </div> */}
            <div
              className={
                style.support_button + " " + style.button_focus + " cursor"
              }
            >
              원격지원
            </div>
          </div>
        )}
      </div>
      <div className={style.online_wrapper}>
        <div className={style.online_title}>블루티엔에스 고객 전용</div>
        <div className={style.online_body}>
          <div className={style.online_info}>
            <img
              alt=""
              className={style.online_icon}
              src={
                process.env.REACT_APP_CLIENT_URL + "img/support/online_icon.png"
              }
            />
            <div className={style.online_text}>
              <div className={style.tech_support}>
                ZWCAD 기술문의 : 070-4618-3596
              </div>
              <div className={style.info_text}>
                원격지원 서비스는 상담원이{" "}
                <font className={style.info_text2}>
                  고객의 화면을 함께 보면서
                </font>
                <p /> 신속, 정확하게 도와드리는 고객지원 서비스입니다.
              </div>
              <div className={style.info_box}>
                <div>
                  <div className={style.info_box_header}>실시간 원격지원</div>
                  <div className={style.info_box_info}>
                    운영시간:
                    <font className={style.info_box_info2}>
                      {" "}
                      AM 09:00 - PM 18:00 (월-금)
                    </font>
                  </div>
                </div>
                <div
                  className={style.info_box_button + " cursor"}
                  onClick={() => {
                    window.open("https://113366.com/7782 ");
                  }}
                >
                  입장하기
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Onlinesupport;
