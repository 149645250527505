import React from "react";
import style from "css/support.module.css";
function Inputonsite(props) {
  return (
    <div className={style.onsite_input_wrapper}>
      <div className={style.onsite_input_header}>
        {props.header}{" "}
        {props.check ? (
          <></>
        ) : (
          <span className={style.onsite_quote_mark}>*</span>
        )}{" "}
      </div>
      <div className={style.onsite_input_body}>
        <input
          className={style.onsite_input}
          value={props.value}
          type="text"
          onChange={(e) => props.changetext(e.target.value)}
          placeholder={props.placeholder}
        />
      </div>
      {props.check ? (
        <div className={style.onsite_email_text}>
          이메일 기입 시, 메일로 다운로드 링크를 전송해드립니다.
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Inputonsite;
