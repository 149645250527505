import React, { useState } from "react";
import style from "css/community.module.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";
function Case() {
  const navigate = useNavigate();
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  // <img
  //   alt=""
  //   src={
  //     process.env.REACT_APP_CLIENT_URL + "img/case/case" + i + ".png"
  //   }
  //   />;

  const data = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27,
  ];
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    { TITLE: "공지사항", LINK: "announcement" },
    { TITLE: "EVENT", LINK: "event" },
  ];
  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/support/community_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.community_area}>
          <div className={style.community_title_wrap}>
            <div className={style.community_title}>커뮤니티</div>
            <div className={style.community_subtitle}>community</div>
          </div>
        </div>
      </div>
      <div className={style.community_category}>
        {isTablet ? (
          <Mobilemenu selected="도입사례" list={menulist} type="" />
        ) : (
          <div className={style.community_category_buttons}>
            <div
              className={style.community_button + " cursor"}
              onClick={() => {
                menuHandler("announcement");
              }}
            >
              공지사항
            </div>
            <div
              className={style.community_button + " cursor"}
              onClick={() => {
                menuHandler("event");
              }}
            >
              EVENT
            </div>
            <div
              className={
                style.community_button + " " + style.button_focus + " cursor"
              }
              onClick={() => {
                menuHandler("case");
              }}
            >
              도입사례
            </div>
          </div>
        )}
      </div>

      <div className={style.case_wrapper}>
        <div className={style.case_body}>
          <div className={style.case_title}>CUSTOMER</div>
          <div className={style.case_subtitle}>블루티엔에스 주요 고객사</div>

          <div className={style.case_contents}>
            {data.map((index) => (
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/case/case" +
                  (index + 1) +
                  ".png"
                }
                className={style.case_img}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Case;
