import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/board.module.css";
import axios from "axios";
import Paging from "components/common/paging";
import { useDispatch } from "react-redux";
import { openAlert, openConfirm } from "redux/slices/alertSlice";
import State from "pages/common/state";

function Adminfree() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boardlist, setboardlist] = useState([]);
  const [init, setInit] = useState(false);
  const [pagerun, setpagerun] = useState(true);

  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 10,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "FREE",
        ROW_COUNT: page.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/board/supportlist", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const getcount = useCallback(() => {
    let config = {
      params: {
        TYPE: "FREE",
      },
    };

    axios.get("/board/supportcounts", config).then((res) => {
      if (res.status === 200) {
        setPage({
          ...page,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun(false);
        setInit(true);
      }
    });
  }, [page]);

  useEffect(() => {
    if (pagerun) {
      getcount();
    }
  }, [getcount, pagerun]);

  return (
    <div className={style.request_wrapper}>
      <div className={style.request_body}>
        <div className={style.request_list_title}>
          <div>무료체험 확인 페이지</div>
        </div>
        <div>
          <div className={style.free_list_header}>
            <div className={style.free_item}>제품</div>
            <div className={style.free_version}>버전</div>
            <div className={style.free_user}>신청자</div>
            <div className={style.free_phone}>연락처</div>
            <div className={style.free_company}>회사명</div>
            <div className={style.free_mail}>이메일</div>
            <div className={style.free_loc1}>주소</div>
            <div className={style.free_dt}>접수일</div>
            <div className={style.free_state}>접수상태</div>
          </div>
          <div className={style.board_list_body}>
            {boardlist.length > 0
              ? boardlist.map((data) => (
                  <div className={style.free_list_body_line} onClick={() => {}}>
                    <div className={style.free_item}>{data.PRODUCT}</div>
                    <div className={style.free_version}>
                      {data.VERSION === "ko" ? "한글판" : "영문판"} -{" "}
                      {data.TYPE}bit
                    </div>
                    <div className={style.free_user}>{data.MANAGER}</div>
                    <div className={style.free_phone}>{data.PHONE}</div>
                    <div className={style.free_company}>{data.COMPANY}</div>
                    <div className={style.free_mail}>{data.EMAIL}</div>
                    <div className={style.free_loc1}>
                      {data.LOCATION}
                      <p />
                      {data.LOCATION_DETAIL}
                    </div>
                    <div className={style.free_dt}>{data.INPUT_DT}</div>

                    <div
                      className={style.free_state}
                      onClick={
                        ((e) => e.preventDefault(), (e) => e.stopPropagation())
                      }
                    >
                      <div>
                        <State
                          state={data.STATUS}
                          TYPE="FREE"
                          ID={data.ID}
                          action={() => getlist()}
                        />
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
          <div>
            <Paging
              init={init}
              setInit={setInit}
              totalCount={page.totalCount}
              rowCount={page.rowCount}
              currentPage={page.currentPage}
              maxPage={page.maxPage}
              onClick={(res) => {
                setPage({ ...page, currentPage: res });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminfree;
