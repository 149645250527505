import React, { useCallback, useEffect, useState } from "react";
import style from "css/zwcad.module.css";
import { useNavigate } from "react-router-dom";
import Underbar from "components/common/underbar";
import Zw3dProcedureListItem from "components/zwcad/zw3dProcedureListItem";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

/**
 * Version : 1.0
 * 작성일자 : 2024-03-04
 * 작성자 : 강연승
 * 설명 : ZW3D 페이지
 */

function Zw3d() {
  //navigate
  const navigate = useNavigate();
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [data, setdata] = useState({
    LINK: "",
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TITLE: "ZW3D",
      },
    };
    axios.get("/open/product_fileinfo", config).then((res) => {
      if (res.status === 200) {
        if (res.data.TYPE === "FILE") {
          setdata({ LINK: res.data.FILE_URL });
        } else {
          setdata({ LINK: res.data.LINK });
        }
      }
    });
  }, []);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const procedureList = [
    {
      index: "01",
      align: "left",
      img: "1.png",
      title: "제품 개발 계획",
      sub_title: "Product development plan",
      text: (
        <>
          강력한 데이터 호환 모듈을 통해 기존에 사용하던
          <br />
          모든 2D/3D 도면을 활용할 수 있습니다.
        </>
      ),
    },
    {
      index: "02",
      align: "right",
      img: "2.png",
      title: "제품 설계 / 디자인",
      sub_title: "Product design / design",
      text: (
        <>
          손쉬운 3D 설계 모듈은
          <br />
          제품 설계 시간을 단축시켜 줍니다.
        </>
      ),
    },
    {
      index: "03",
      align: "left",
      img: "3.png",
      title: "설계 검증",
      sub_title: "Design verification",
      text: (
        <>
          설계 품질을 검증하기 위한
          <br />
          다양한 방법을 제공합니다.
        </>
      ),
    },
    {
      index: "04",
      align: "right",
      img: "4.png",
      title: "샘플 제작",
      sub_title: "Sample production",
      text: (
        <>
          시제품 제작에 필요한 협력사와의 협업을 위한
          <br />
          다양한 도구를 제공합니다.
        </>
      ),
    },
    {
      index: "05",
      align: "left",
      img: "5.png",
      title: "금형설계",
      sub_title: "Mold design",
      text: (
        <>
          금형 제작에 필요한
          <br />
          편리한 솔루션을 제공합니다.
        </>
      ),
    },
    {
      index: "06",
      align: "right",
      img: "6.png",
      title: "CAM 가공",
      sub_title: "CAM Processing",
      text: (
        <>
          지능형 2-5축 가공으로
          <br />
          제조용 NC데이터를 생성합니다.
        </>
      ),
    },
  ];

  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const menulist = [
    { TITLE: "ZWCAD 2025", LINK: "zwcad" },
    { TITLE: "ZDREAM", LINK: "zdream" },
    { TITLE: "CAD 제품 비교", LINK: "cad_compare" },
    { TITLE: "ZW3D 제품 비교", LINK: "zw3d_compare" },
  ];
  return (
    <>
      <div className={style.wrapper}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/zwcad/zw3d_bg.png')",
          }}
        />
        <div className={style.zwcad_area}>
          <div className={style.zwcad_title_wrap}>
            <div className={style.zwcad_title}>ZW3D 2025</div>
            <div className={style.zwcad_subtitle}>
              Flexible Design, Fast Machining
            </div>
            <div className={style.zwcad_title_btn_wrap}>
              <div
                className={style.zwcad_title_btn + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "free")
                }
              >
                무료체험 신청
              </div>
              <div
                className={style.zwcad_title_btn + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "request")
                }
              >
                견적 신청
              </div>
            </div>
          </div>
        </div>
      </div>

      {isTablet ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="ZW3D 2025" list={menulist} type="zwcad" />
        </div>
      ) : isMobile ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="ZW3D 2025" list={menulist} type="zwcad" />
        </div>
      ) : (
        <div className={style.zwcad_category}>
          <div className={style.zwcad_category_btn_wrap}>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zwcad")}
            >
              ZWCAD 2025
            </div>
            <div
              className={
                style.zwcad_category_btn + " " + style.button_focus + " cursor"
              }
              onClick={() => menuHandler("zw3d")}
            >
              ZW3D 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zdream")}
            >
              ZDREAM
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("cad_compare")}
            >
              CAD 제품 비교
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d_compare")}
            >
              ZW3D 제품 비교
            </div>
          </div>
        </div>
      )}

      <div className={style.zw3d_info_wrap}>
        <div className={style.zw3d_info_title}>ZW3D 란?</div>
        {isMobile ? (
          <>
            <div className={style.zw3d_info_description}>
              금형설계에 필요한 자동 파팅(Parting)과 2축-5축 CAM 경로 시뮬레이션
              등 제품 개발을 위해 필요한 모든 기능을 하나의 시스템에서 통합한
              올인원 솔루션입니다.<br></br>
              <span className={style.highlight}>CAD/CAM 통합 환경</span>
              으로&nbsp;
              <span className={style.highlight}>
                빠른 속도의 솔리드-서피스(곡면) 설계를 동시에 지원
              </span>
              하는&nbsp;
              <span className={style.highlight}>
                하이브리드 모델링을 통해 원하는 형상을 3D 모델로 구현
              </span>
              할 수 있습니다.
            </div>
            <div className={style.zw3d_info_guarantee}>
              <span>※ 합리적인 도입 비용</span>으로 개발 <span>기간 단축</span>
              &nbsp;및<br></br>개발 <span>비용 절감</span> 등 최고의 효율성을
              보장합니다.
            </div>
          </>
        ) : (
          <>
            <div className={style.zw3d_info_description}>
              금형설계에 필요한 자동 파팅(Parting)과 2축-5축 CAM 경로 시뮬레이션
              등
              <br />
              제품 개발을 위해 필요한 모든 기능을 하나의 시스템에서 통합한
              올인원 솔루션입니다.
              <br />
              <span className={style.highlight}>CAD/CAM 통합 환경</span>으로
              <br />
              <span className={style.highlight}>
                빠른 속도의 솔리드-서피스(곡면) 설계를 동시에 지원
              </span>
              하는
              <br />
              <span className={style.highlight}>
                하이브리드 모델링을 통해 원하는 형상을 3D 모델로 구현
              </span>
              할 수 있습니다.
            </div>
            <div className={style.zw3d_info_guarantee}>
              <span>※ 합리적인 도입 비용</span>으로 개발 <span>기간 단축</span>{" "}
              및 개발 <span>비용 절감</span> 등 최고의 효율성을 보장합니다.
            </div>
          </>
        )}
      </div>

      <div className={style.zw3d_procedure_wrap}>
        <div className={style.zw3d_procedure_title_wrap}>
          <div className={style.zw3d_procedure_label}>PROCEDURES</div>
          <div className={style.zw3d_procedure_title}>
            제품 <span>절차</span>
          </div>
        </div>
        <div className={style.zw3d_prodcedure_list}>
          {procedureList.map((procedure, index) => (
            <Zw3dProcedureListItem procedure={procedure} key={index} />
          ))}
        </div>
      </div>

      <Underbar
        c1="ZW3D 2025"
        c1_use={true}
        c1_link=""
        c2="ZW3D 2025"
        c2_use={true}
        c2_link={data.LINK}
        c3="ZW3D 2025"
        c3_use={false}
        c3_link=""
        c4="ZW3D 2025"
        c4_use={true}
        c4_link=""
        c5="ZW3D 제품 비교"
        c5_use={false}
        c5_link=""
        c6="ZW3D TV"
        c6_use={true}
        c6_link="https://www.youtube.com/playlist?list=PLIFLHLfQV9CClLCHh9FaoeTolwzX82TtO"
      />
    </>
  );
}

export default Zw3d;
