import React from "react";
import style from "css/admin.module.css";
import { Cookies } from "react-cookie";

function AdminHeader() {
  const cookies = new Cookies();
  const logout = () => {
    const authNm = process.env.REACT_APP_AUTH_TOKEN;
    const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
    const domain = process.env.REACT_APP_DOMAIN;
    cookies.remove(authNm, { path: "/", domain: domain });
    cookies.remove(refreshNm, { path: "/", domain: domain });
    window.location.href = process.env.REACT_APP_CLIENT_URL;
  };
  return (
    <div className={style.header}>
      <div className={style.header_main}>
        <div className={style.header_left_area}>
          <img
            className="cursor"
            alt=""
            src="/img/common/logo.png"
            // onClick={() => navigate(process.env.REACT_APP_CLIENT_URL)}
          />
        </div>

        <div className={style.header_center_area}></div>

        <div className={style.header_right_area}>
          <div className={style.btn_logout} onClick={() => logout()}>
            로그아웃
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
