import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/board.module.css";
import axios from "axios";
import Paging from "components/common/paging";
import { useDispatch } from "react-redux";
import { openAlert, openConfirm } from "redux/slices/alertSlice";
function Adminfaq() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boardlist, setboardlist] = useState([]);
  const [init, setInit] = useState(false);
  const [pagerun, setpagerun] = useState(true);

  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 10,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0403",
        ROW_COUNT: page.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/board/boardlist", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const getcount = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0403",
      },
    };

    axios.get("/board/boardcount", config).then((res) => {
      if (res.status === 200) {
        setPage({
          ...page,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun(false);
        setInit(true);
      }
    });
  }, [page]);

  useEffect(() => {
    if (pagerun) {
      getcount();
    }
  }, [getcount, pagerun]);

  const delbutton = (id) => {
    dispatch(
      openConfirm({
        title: "Notice",
        content: "삭제하시겠습니까 ?",
        submitEventHandler: () => deleteboard(id),
      })
    );
  };

  const deleteboard = (id) => {
    let config = {
      params: {
        BOARD_ID: id,
      },
    };
    axios
      .put("/board/delete", null, config)
      .then((res) => {
        if (res.status === 200) {
          setpagerun(true);
          getlist();
        }
      })
      .finally(
        dispatch(
          openAlert({
            title: "Notice",
            content: "삭제되었습니다.",
            submitEventHandler: () => {},
          })
        )
      );
  };

  return (
    <div className={style.board_list_wrapper}>
      <div className={style.board_list_title}>
        <div>FAQ 관리 페이지</div>
        <div
          className={style.board_list_input + " cursor"}
          onClick={() =>
            navigate(process.env.REACT_APP_CLIENT_URL + "admin/board", {
              state: {
                board_id: "NEW",
                type: "CD0403",
              },
            })
          }
        >
          등록
        </div>
      </div>
      <div>
        <div className={style.board_list_header}>
          <div className={style.board_num}>관리번호</div>
          <div className={style.board_title}>제목</div>
          <div className={style.board_writer}>작성자</div>
          <div className={style.board_date}>등록일자</div>
          <div className={style.board_date}>수정일자</div>
          <div className={style.board_del}>삭제</div>
        </div>
        <div className={style.board_list_body}>
          {boardlist.length > 0
            ? boardlist.map((data) => (
                <div
                  className={style.board_list_body_line}
                  onClick={() =>
                    navigate(process.env.REACT_APP_CLIENT_URL + "admin/board", {
                      state: {
                        board_id: data.BOARD_ID,
                        type: "CD0403",
                      },
                    })
                  }
                >
                  <div className={style.board_num}>{data.BOARD_ID}</div>
                  <div className={style.board_title}>{data.TITLE}</div>
                  <div className={style.board_writer}>{data.INPUT_ID}</div>
                  <div className={style.board_date}>{data.INPUT_DT}</div>
                  <div className={style.board_date}>{data.UPDT_DT}</div>

                  <div
                    className={style.board_del}
                    onClick={
                      ((e) => e.preventDefault(), (e) => e.stopPropagation())
                    }
                  >
                    <div onClick={() => delbutton(data.BOARD_ID)}>삭제</div>
                  </div>
                </div>
              ))
            : ""}
        </div>
        <div>
          <Paging
            init={init}
            setInit={setInit}
            totalCount={page.totalCount}
            rowCount={page.rowCount}
            currentPage={page.currentPage}
            maxPage={page.maxPage}
            onClick={(res) => {
              setPage({ ...page, currentPage: res });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Adminfaq;
