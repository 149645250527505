import React, { useState } from "react";
import style from "css/mobilemenu.module.css";
import { useNavigate } from "react-router-dom";
function Mobilemenu(props) {
  const [open, setopen] = useState(false);
  const navigate = useNavigate();
  const clickopen = () => {
    setopen(!open);
  };
  const menuHandler = (e) => {
    setopen(false);
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  return (
    <div className={style.menu_wrapper}>
      <div className={style.menu_box}>
        <div
          className={style.menu_selected + " cursor"}
          onClick={() => clickopen()}
        >
          <div style={{ width: "52px" }} />
          <div> {props.selected}</div>
          <div>
            {" "}
            <img
              className={open ? style.arrow_up : style.arrow_down}
              alt=""
              style={{}}
              src={process.env.REACT_APP_CLIENT_URL + "img/common/dropdown.png"}
            />
          </div>
        </div>
        <div
          className={
            open
              ? props.type === "zwcad"
                ? style.zw_menu_list + "  cursor"
                : style.menu_list + "  cursor"
              : style.menu_list_off + "  cursor"
          }
        >
          {props.list.map((data) => (
            <div
              onClick={() => menuHandler(data.LINK)}
              className={open ? style.menu_select : style.menu_select_off}
            >
              {data.TITLE}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Mobilemenu;
