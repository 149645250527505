import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import style from "css/template.module.css";
import AdminLogin from "./adminLogin";
import AdminMain from "./adminMain";
import AdminMenu from "../../components/admin/adminMenu";
import Adminfaq from "./adminfaq";
import Adminevent from "./adminevent";
import Board from "./board";
import Adminnotice from "./adminnotice";
import Adminuser from "./adminuser";
import Adminrequest from "./adminrequest";
import Adminfree from "./adminfree";
import Adminonsite from "./adminonsite";
import Adminproduct from "./adminproduct";
import Adminpopup from "./adminpopup";

/**
 * 파일명: Admin.js
 * 작성자: 강연승
 * 작성일자: 24.02.16
 * 설명: Admin page
 */

function Admin() {
  const location = useLocation();

  return (
    <div
      className={
        location.pathname === "/admin"
          ? style.admin_login_container
          : style.admin_container
      }
    >
      {location.pathname === "/admin" ? <></> : <AdminMenu />}
      <Routes>
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL}
          element={<AdminLogin />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "main"}
          element={<AdminMain />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "event"}
          element={<Adminevent />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "board"}
          element={<Board />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "notice"}
          element={<Adminnotice />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "faq"}
          element={<Adminfaq />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "user"}
          element={<Adminuser />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "request"}
          element={<Adminrequest />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "onlinesupport"}
          element={<Adminonsite />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "free"}
          element={<Adminfree />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "product"}
          element={<Adminproduct />}
        />
        <Route
          exact
          path={process.env.REACT_APP_CLIENT_URL + "popup"}
          element={<Adminpopup />}
        />
      </Routes>
    </div>
  );
}

export default Admin;
