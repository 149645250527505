import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/contact.module.css";
import Inputcontact from "./inputonsite";
import Postcode from "./postcode";
import axios from "axios";
import { openAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

function Request() {
  const [info, setinfo] = useState({
    product: "ZWCAD",
    manager: "",
    phone: "",
    company: "",
    email: "",
    version1: "",
    version2: "",
    promotion1: false,
    promotion2: false,
    count: "",
    date: "",
  });

  const selectversion1 = [
    { value: "", name: "제품을 선택하세요" },
    { value: "ZWCAD", name: "ZWCAD" },
    { value: "ZW3D", name: "ZW3D" },
    { value: "ZDREAM", name: "ZDREAM" },
  ];
  const selectversion2 = [
    { value: "", name: "제품을 선택하세요" },
    { value: "Editor", name: "Foxit PDF Editor" },
    { value: "eSign", name: "Foxit PDF eSign" },
    { value: "Reader", name: "PDF Reader" },
  ];
  const selectdate = [
    { value: "", name: "구입 예상 시기 선택" },
    { value: "1", name: "1개월 이내" },
    { value: "1to3", name: "1~3개월 이내" },
    { value: "3", name: "3개월 이상" },
    { value: "other", name: "기타" },
  ];

  const setccn = (e) => {
    e = e
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
    setinfo({ ...info, phone: e });
  };
  const setcount = (e) => {
    e = e.replace(/[^0-9]/g, "");
    setinfo({ ...info, count: e });
  };
  const dispatch = useDispatch();

  const request = () => {
    if (check()) {
      axios
        .post("/open/request", info)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              openAlert({
                title: "Notice",
                content: "견적 요청이 완료되었습니다.",
                submitEventHandler: () =>
                  setinfo({
                    product: "ZWCAD",
                    manager: "",
                    phone: "",
                    company: "",
                    email: "",
                    version1: "",
                    version2: "",
                    promotion1: false,
                    promotion2: false,
                    count: "",
                    date: "",
                  }),
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            openAlert({
              title: "Notice",
              content: err.response.data,
            })
          );
        });
    }
  };

  const check = () => {
    if (info.manager === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "신청자를 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.phone === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "연락처를 입력주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.company === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "회사명을 입력해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else if (info.version1 === "" && info.version2 === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "제품을 선택해주세요",
          submitEventHandler: () => {},
        })
      );
      return false;
    } else {
      return true;
    }
  };
  const clicktab = (value) => {
    if (!(value === info.product)) {
      setinfo({
        ...info,
        product: value,
        version1: "",
        version2: "",
        promotion1: false,
        promotion2: false,
      });
    }
  };
  const checkline = useMediaQuery({
    query: "(min-width: 1301px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/contact/request_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.contact_area}>
          <div className={style.contact_title_wrap}>
            <div className={style.contact_title}>견적요청</div>
            <div className={style.contact_subtitle}>Request for estimate</div>
          </div>
        </div>
      </div>
      <div className={style.contact_wrapper}>
        <div className={style.contact_body}>
          <div className={style.contact_contents}>
            <div className={style.contact_header}>
              <div className={style.contact_title_2}>
                견적요청 후{isMobile ? <p /> : ""}
                {checkline ? <p /> : ""}{" "}
                <label className={style.contact_title_3}>
                  10일 이내 구매 시
                </label>{" "}
                <p /> 특별한 혜택을 {checkline ? <p /> : ""}
                드립니다.
              </div>
            </div>
            <div className={style.contact_body_wrapper}>
              <div className={style.contact_quote}>
                <font className={style.contact_quote_mark}>*</font> 필수 입력
                항목
              </div>

              <div className={style.contact_input_line}>
                <div className={style.contact_input_wrapper}>
                  <div className={style.contact_input_header}>
                    품목
                    <span className={style.contact_quote_mark}> *</span>
                  </div>
                  {/* 버튼 3개 */}
                  <div className={style.contact_select_product}>
                    <div
                      className={
                        info.product === "ZWCAD"
                          ? style.selected
                          : style.nonselected
                      }
                      onClick={() => clicktab("ZWCAD")}
                    >
                      ZWCAD / ZW3D
                    </div>
                    <div
                      className={
                        info.product === "Foxit PDF"
                          ? style.selected
                          : style.nonselected
                      }
                      onClick={() => clicktab("Foxit PDF")}
                    >
                      Foxit PDF
                    </div>
                    <div
                      className={
                        info.product === "Z+F"
                          ? style.selected
                          : style.nonselected
                      }
                      onClick={() => clicktab("Z+F")}
                    >
                      통합 구매(Z+F)
                    </div>
                  </div>{" "}
                </div>
              </div>

              <div className={style.contact_input_line}>
                <Inputcontact
                  header="신청자"
                  placeholder="ex) 홍길동"
                  value={info.manager}
                  changetext={(value) => setinfo({ ...info, manager: value })}
                />
                <Inputcontact
                  header="연락처"
                  placeholder="ex) 010-1234-5678"
                  value={info.phone}
                  changetext={(e) => {
                    setccn(e);
                  }}
                />
              </div>
              <div className={style.contact_input_line}>
                <Inputcontact
                  header="회사명"
                  placeholder="ex) 블루티엔에스"
                  value={info.company}
                  changetext={(value) => setinfo({ ...info, company: value })}
                />
                <Inputcontact
                  header="이메일"
                  placeholder="ex) sample@email.com"
                  value={info.email}
                  check={true}
                  changetext={(value) => setinfo({ ...info, email: value })}
                />
              </div>

              <div className={style.contact_input_line}>
                {info.product === "ZWCAD" ? (
                  <div className={style.contact_input_wrapper}>
                    <div className={style.contact_input_header}>제품</div>

                    <div className={style.contact_promotion}>
                      <select
                        className={
                          style.selectbox_contact +
                          " cursor " +
                          style.select_box_contact
                        }
                        onChange={(e) =>
                          setinfo({ ...info, version1: e.target.value })
                        }
                        value={info.version1}
                      >
                        {selectversion1.map((item) => (
                          <option
                            value={item.value}
                            key={item.value}
                            className={style.selectbox_list}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <div className={style.contact_promotion_box}>
                        <input
                          type="checkbox"
                          checked={info.promotion1}
                          style={{ width: "21px", height: "21px" }}
                          onChange={({ target: { checked } }) =>
                            setinfo({ ...info, promotion1: checked })
                          }
                        />
                        프로모션
                      </div>
                    </div>
                  </div>
                ) : info.product === "Foxit PDF" ? (
                  <div className={style.contact_input_wrapper}>
                    <div className={style.contact_input_header}>제품</div>

                    <div className={style.contact_promotion}>
                      <select
                        className={
                          style.selectbox_contact +
                          " cursor " +
                          style.select_box_contact
                        }
                        onChange={(e) =>
                          setinfo({ ...info, version2: e.target.value })
                        }
                        value={info.version2}
                      >
                        {selectversion2.map((item) => (
                          <option
                            value={item.value}
                            key={item.value}
                            className={style.selectbox_list}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <div className={style.contact_promotion_box}>
                        <input
                          type="checkbox"
                          style={{ width: "21px", height: "21px" }}
                          checked={info.promotion2}
                          onChange={({ target: { checked } }) =>
                            setinfo({ ...info, promotion2: checked })
                          }
                        />
                        프로모션
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {" "}
                    <div className={style.contact_input_wrapper}>
                      <div className={style.contact_input_header}>제품</div>
                      <div className={style.contact_promotion}>
                        <select
                          className={
                            style.selectbox_contact +
                            " cursor " +
                            style.select_box_contact
                          }
                          onChange={(e) =>
                            setinfo({ ...info, version1: e.target.value })
                          }
                          value={info.version1}
                        >
                          {selectversion1.map((item) => (
                            <option
                              value={item.value}
                              key={item.value}
                              className={style.selectbox_list}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <div className={style.contact_promotion_box}>
                          <input
                            type="checkbox"
                            checked={info.promotion1}
                            style={{ width: "21px", height: "21px" }}
                            onChange={({ target: { checked } }) =>
                              setinfo({ ...info, promotion1: checked })
                            }
                          />
                          프로모션
                        </div>
                      </div>
                      <div className={style.contact_promotion2}>
                        <select
                          className={
                            style.selectbox_contact +
                            " cursor " +
                            style.select_box_contact
                          }
                          onChange={(e) =>
                            setinfo({ ...info, version2: e.target.value })
                          }
                          value={info.version2}
                        >
                          {selectversion2.map((item) => (
                            <option
                              value={item.value}
                              key={item.value}
                              className={style.selectbox_list}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <div className={style.contact_promotion_box}>
                          <input
                            type="checkbox"
                            style={{ width: "21px", height: "21px" }}
                            checked={info.promotion2}
                            onChange={({ target: { checked } }) =>
                              setinfo({ ...info, promotion2: checked })
                            }
                          />
                          프로모션
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={style.contact_input_line}>
                <Inputcontact
                  header="수량"
                  placeholder="구입 수량을 입력하세요"
                  value={info.count}
                  check={true}
                  changetext={(value) => setcount(value)}
                />
                <div className={style.contact_input_wrapper}>
                  <div className={style.contact_input_header}>구매 시기</div>

                  <select
                    className={style.selectbox_contact + " cursor"}
                    onChange={(e) => setinfo({ ...info, date: e.target.value })}
                    value={info.date}
                  >
                    {selectdate.map((item) => (
                      <option
                        value={item.value}
                        key={item.value}
                        className={style.selectbox_list}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                className={style.send_button + " cursor"}
                onClick={() => request()}
              >
                견적 요청
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Request;
