import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/board.module.css";
import axios from "axios";
import Paging from "components/common/paging";
import { useDispatch } from "react-redux";
import { openAlert, openConfirm } from "redux/slices/alertSlice";
import Adminproductpop from "./adminproductpop";
function Adminproduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boardlist, setboardlist] = useState([]);

  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 10,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {},
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/board/product_filelist", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const delbutton = (id) => {
    dispatch(
      openConfirm({
        title: "Notice",
        content: "삭제하시겠습니까 ?",
        submitEventHandler: () => deleteboard(id),
      })
    );
  };

  const deleteboard = (id) => {
    const boardInfoObj = {
      LINK: "",
      TYPE: "",
      TITLE: id,
      FUNC: "D",
    };
    const formData = new FormData();
    formData.append("boardInfo", JSON.stringify(boardInfoObj));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .put("/board/product_fileupload", formData, config)
      .then((res) => {
        if (res.status === 200) {
          getlist();
        }
      })
      .finally(
        dispatch(
          openAlert({
            title: "Notice",
            content: "삭제되었습니다.",
            submitEventHandler: () => {},
          })
        )
      );
  };

  const [TITLE, setTITLE] = useState("");
  const [TYPE, setTYPE] = useState("");
  const [LINK, setLINK] = useState("");
  const [FILE, setFILE] = useState("");
  const [open, setopen] = useState(false);
  const setclose = () => {
    setopen(false);
    getlist();
  };

  const openpop = (data) => {
    setTITLE(data.TITLE);
    setTYPE(data.TYPE);
    setLINK(data.LINK);
    setFILE(data.FILE_NM);
    setopen(true);
  };
  return (
    <div className={style.board_list_wrapper}>
      <Adminproductpop
        open={open}
        close={() => setclose()}
        TITLE={TITLE}
        TYPE={TYPE}
        LINK={LINK}
        FILE={FILE}
      />
      <div className={style.board_list_title}>
        <div>제품소개서 관리 페이지</div>
      </div>
      <div>
        <div className={style.board_list_header}>
          <div className={style.r8}>관리번호</div>
          <div className={style.r10}>제목</div>
          <div className={style.r8}>타입</div>
          <div className={style.r20}>파일명</div>
          <div className={style.r20}>URL</div>
          <div className={style.r14}>수정일자</div>
          <div className={style.r10}>삭제</div>
          <div className={style.r10}>수정</div>
        </div>
        <div className={style.board_list_body}>
          {boardlist.length > 0
            ? boardlist.map((data, index) => (
                <div className={style.board_list_body_line}>
                  <div className={style.r8}>{index + 1}</div>
                  <div className={style.r10}>{data.TITLE}</div>
                  <div className={style.r8}>{data.TYPE}</div>
                  <div
                    title={data.FILE_NM}
                    className={style.r20}
                    style={{ overflow: "hidden", height: "15px" }}
                    onClick={() => {
                      if (data.FILE_URL !== "" && data.FILE_URL !== undefined) {
                        window.open(
                          process.env.REACT_APP_CLIENT_URL + data.FILE_URL
                        );
                      }
                    }}
                  >
                    {data.FILE_NM}
                  </div>
                  <div
                    title={data.LINK}
                    className={style.r20}
                    style={{ overflow: "hidden", height: "15px" }}
                    onClick={() => {
                      if (data.LINK !== "" && data.LINK !== undefined) {
                        window.open(data.LINK);
                      }
                    }}
                  >
                    {data.LINK}
                  </div>
                  <div className={style.r14}>{data.UPDT_DT}</div>

                  <div
                    className={style.r10}
                    onClick={
                      ((e) => e.preventDefault(), (e) => e.stopPropagation())
                    }
                  >
                    <div
                      className={style.del}
                      onClick={() => delbutton(data.TITLE)}
                    >
                      삭제
                    </div>
                  </div>
                  <div
                    className={style.r10}
                    onClick={
                      ((e) => e.preventDefault(), (e) => e.stopPropagation())
                    }
                  >
                    <div className={style.del} onClick={() => openpop(data)}>
                      수정
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
}

export default Adminproduct;
