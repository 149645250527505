import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert, openAlert } from "redux/slices/alertSlice";

import ReactModal from "react-modal";
import style from "css/board.module.css";
import axios from "axios";
function Adminproductpop(props) {
  const [boardInfo, setboardInfo] = useState({
    TITLE: props.TITLE,
    FILE: [],
    LINK: props.LINK,
    TYPE: props.TYPE,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setboardInfo({
      ...boardInfo,
      TITLE: props.TITLE,
      TYPE: props.TYPE,
      LINK: props.LINK,
    });
  }, [props]);
  const [totalFiles, setTotalFiles] = useState([]);

  const onChangeFile = (e) => {
    let tmpArr = [];
    let totalTmp = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (boardInfo.FILE.find((file) => file.name === e.target.files[i].name)) {
        alert("파일이 이미 존재합니다.");
        return;
      }
      tmpArr.push(e.target.files[i]);
      totalTmp.push({ FILE_NM: e.target.files[i].name, type: "new" });
    }
    setboardInfo({ ...boardInfo, FILE: tmpArr });
    setTotalFiles(totalTmp);
    e.target.value = "";
  };
  const setchange = (e) => {
    setboardInfo({ ...boardInfo, TYPE: e });
  };

  const send = () => {
    const boardInfoObj = {
      LINK: boardInfo.LINK,
      TYPE: boardInfo.TYPE,
      TITLE: boardInfo.TITLE,
      FUNC: "U",
    };

    if (boardInfoObj.TYPE === "FILE" && boardInfo.FILE.length < 1) {
      if (props.FILE !== "" && props.FILE !== undefined) {
      } else {
        dispatch(
          openAlert({
            title: "Notice",
            content: "타입 설정을 확인해주세요.",
            submitEventHandler: () => {},
          })
        );
        return;
      }
    }
    if (boardInfoObj.TYPE === "URL" && boardInfo.LINK === "") {
      dispatch(
        openAlert({
          title: "Notice",
          content: "타입 설정을 확인해주세요.",
          submitEventHandler: () => {},
        })
      );
      return;
    }

    const formData = new FormData();
    formData.append("boardInfo", JSON.stringify(boardInfoObj));

    if (Array.isArray(boardInfo.FILE) && boardInfo.FILE.length > 0) {
      for (let i = 0; i < boardInfo.FILE.length; i++) {
        formData.append("files", boardInfo.FILE[i]);
      }
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .put("/board/product_fileupload", formData, config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          dispatch(
            openAlert({
              title: "Notice",
              content: "파일이 수정되었습니다.",
              submitEventHandler: () => {},
            })
          );
        }
        close();
      })
      .catch(() => {
        dispatch(
          openAlert({
            title: "Notice",
            content: "에러가 발생했습니다.",
            submitEventHandler: () => {
              close();
            },
          })
        );
      });
  };
  const close = () => {
    setTotalFiles([]);
    setboardInfo({
      TITLE: "",
      FILE: [],
      LINK: "",
      TYPE: "",
    });
    props.close();
  };

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className={style.filemodal} //모달 ClassName
      overlayClassName={style.file_overlay} ///모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <div className={style.aup}>
        <div className={style.product_title}>
          파일 정보 수정{" "}
          <div className={style.product_radio}>
            <input
              type="radio"
              name="type"
              value="FILE"
              checked={boardInfo.TYPE === "FILE"}
              onChange={(e) => setchange(e.target.value)}
            />
            파일
            <input
              type="radio"
              name="type"
              value="URL"
              checked={boardInfo.TYPE === "URL"}
              onChange={(e) => setchange(e.target.value)}
            />
            URL
          </div>
        </div>
        <div className={style.aup_body}>
          <div className={style.product_body}>
            <div className={style.product_file}>
              <div className={style.product_header}>파일 :</div>
              <div className={style.product_files}>
                {Array.isArray(totalFiles) && totalFiles.length > 0 ? (
                  <div className={style.file_list_single_info}>
                    {totalFiles.map((item, idx) => (
                      <div key={idx} className={style.product_line}>
                        <div className={style.files_name}>
                          {item.type === "old"
                            ? item.ORI_FILE_NM
                            : item.FILE_NM}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={style.product_list_none}>
                    {props.FILE !== "" && props.FILE !== undefined
                      ? props.FILE
                      : "파일을 등록해 주세요"}
                  </div>
                )}
              </div>
              <div className={style.product_files_btn}>
                <label htmlFor="FILE" className={style.product_btn}>
                  <div className="cursor">파일 등록</div>
                </label>
                <input
                  id="FILE"
                  className="new-class-file-input"
                  type="file"
                  style={{ display: "none" }}
                  name="file"
                  onChange={onChangeFile}
                />
              </div>
            </div>
            <div className={style.product_url}>
              <div className={style.product_header}>URL :</div>
              <div>
                <input
                  type="text"
                  className={style.product_url_input}
                  value={boardInfo.LINK}
                  onChange={(e) =>
                    setboardInfo({ ...boardInfo, LINK: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.aup_footer}>
          <div className={style.aup_close + " cursor"} onClick={() => close()}>
            닫기
          </div>
          <div className={style.aup_submit + " cursor"} onClick={() => send()}>
            저장
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Adminproductpop;
