import React, { useCallback, useEffect, useState } from "react";
import style from "css/landing.module.css";
import NoticeListItem from "./noticeListItem";
import axios from "axios";
import { useNavigate } from "react-router-dom";

/**
 * 파일명: NoticeArea.js
 * 작성자: 강연승
 * 작성일자: 24.02.15
 * 설명: landing page - NoticeArea
 */

function NoticeArea() {
  const noticeList1 = [
    {
      TITLE: "ZWCAD 글로벌 TOP 파트너 선정!",
      TEXT: "블루티엔에스 새로운 소식 1건이 추가되었습니다.\n믿고 함께 해 주신 고객사분들 덕분에 좋은 성적을 거두게 되었습니다. 감사합니다!",
      IMG: "20221007_1.png",
      INPUT_DT: "2022.10.07",
    },
    {
      TITLE: "대기업 비즈니스 1위 파트너 선정!!",
      TEXT: "블루티엔에스 새로운 소식 1건이 추가되었습니다.\n대기업 비즈니스 1위 파트너로 선정된 내용입니다.",
      IMG: "20221007_2.png",
      INPUT_DT: "2022.10.07",
    },
    {
      TITLE: "블루티엔에스 창립 10주년",
      TEXT: "블루티엔에스 새로운 소식 1건이 추가되었습니다.\n지난 10년간 블루티엔에스를 믿고 함께 해주신 모든 분들께 감사드립니다.",
      IMG: "20220204.png",
      INPUT_DT: "2022.02.04",
    },
  ];

  const [run, setrun] = useState(true);
  const [noticeList, setnoticeList] = useState([]);
  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0402",
        ROW_COUNT: 3,
        PAGE_COUNT: 0,
        HOLD: "1",
      },
    };

    axios.get("/open/boardlist", config).then((res) => {
      if (res.status === 200) {
        setnoticeList(res.data);
        setrun(false);
      }
    });
  }, []);

  useEffect(() => {
    if (run) {
      getlist();
    }
  }, [getlist, run]);
  const navigate = useNavigate();
  return (
    <div className={style.wrapper}>
      {/* <div
        className={style.background}
        style={{
          backgroundColor: "#eff5fa",
        }}
      >
      </div> */}

      <div className={style.notice_area}>
        <div className={style.notice_area_label}>
          <div className={style.notice_area_title_wrap}>
            <div className={style.notice_area_title}>공지사항</div>
            <div className={style.notice_area_subtitle}>
              블루티엔에스 소식을 확인해 보세요
            </div>
          </div>
          <div className={style.notice_nav}>
            <div
              className={`${style.notice_viewmore_btn} cursor`}
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "announcement")
              }
            >
              View more
            </div>
          </div>
        </div>

        <div className={style.notice_list}>
          {noticeList.map((notice, index) => (
            <NoticeListItem key={index} notice={notice} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NoticeArea;
