import React, { useState, useEffect } from "react";
import style from "css/template.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import AdminHeader from "components/admin/adminHeader";
import { useMediaQuery } from "react-responsive";
import SideMenu from "components/common/sideMenu";

/**
 * Version : 1.0
 * 작성일자 : 2023-12-11
 * 작성자 : 권도훈
 * 설명 : 공통 템플릿 영역
 */
function Header() {
  //navigate
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isHoverMenu, setIsHoverMenu] = useState(false);
  const [hoveringMenu, setHoveringMenu] = useState("");

  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  const menuList = [
    {
      label: "ZWCAD",
      key: "zwcad",
    },
    {
      label: "Foxit PDF",
      key: "foxit_pdf_editor",
    },
    {
      label: "Company",
      key: "company",
    },
    {
      label: "지원 및 커뮤니티",
      key: "faq",
    },
  ];
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
    setIsHoverMenu(false);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollHeader, setScrollHeader] = useState(false);

  // Scroll 위치를 감지
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  // scroll 위치가 100이하라면 투명한 배경색을 지정하고, 아니면 흰색을 지정한다.
  useEffect(() => {
    if (scrollPosition < 100) {
      setScrollHeader(false);
    } else {
      setScrollHeader(true);
    }
  }, [scrollPosition]);

  const submenucompany = (val) => {
    navigate(process.env.REACT_APP_CLIENT_URL + "company", {
      state: {
        title: val,
      },
    });
    setIsHoverMenu(false);
  };
  const foxitpdf = (val) => {
    navigate(process.env.REACT_APP_CLIENT_URL + val);
    setIsHoverMenu(false);
  };

  const renderSubMenu = (menu) => {
    switch (menu) {
      case "zwcad":
        return (
          <div>
            <div className={style.sub_menu_btn_group}>
              <div
                className={style.sub_menu_btn}
                onClick={() => menuHandler("free")}
              >
                무료체험 신청
              </div>
              <div
                className={style.sub_menu_btn + " " + style.sub_menu_btn2}
                onClick={() => menuHandler("request")}
              >
                견적 신청
              </div>
            </div>
            <div className={style.sub_menu}>
              <div className={style.sub_menu_group}>
                <div className={style.sub_menu_title}>제품</div>
                <div className={style.sub_menu_list}>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("zwcad")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      ZWCAD 2025
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      범용 설계 프로그램
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("zw3d")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      ZW3D 2025
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      CAD/CAM 통합 프로그램
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("zdream")}
                  >
                    <div className={style.sub_menu_list_item_name}>ZDREAM</div>
                    <div className={style.sub_menu_list_item_text}>
                      ZWCAD 3rd-Party
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("cad_compare")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      CAD 제품 비교
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      CAD 제품별 상세 비교표
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("zw3d_compare")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      ZW3D 버전 비교
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      ZW3D 제품별 상세 비교표
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "foxit_pdf_editor":
        return (
          <>
            <div className={style.sub_menu_btn_group2}>
              <div
                className={style.sub_menu_btn}
                onClick={() => menuHandler("free")}
              >
                무료체험 신청
              </div>
              <div
                className={style.sub_menu_btn + " " + style.sub_menu_btn2}
                onClick={() => menuHandler("request")}
              >
                견적 신청
              </div>
            </div>

            <div className={style.sub_menu2}>
              <div
                className={style.sub_menu_group}
                style={{ marginRight: "70px" }}
              >
                <div className={style.sub_menu_title}>클라이언트 제품</div>
                <div className={style.sub_menu_list}>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => foxitpdf("foxit_pdf_editor")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      Foxit PDF Editor
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      ALL-IN-ONE 편집기
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => foxitpdf("foxit_pdf_esign")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      Foxit PDF eSign
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      Foxit 전자서명 서비스
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => foxitpdf("pdf_reader")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      PDF Reader
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      PDF 리더기
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={style.sub_menu_group}
                style={{ marginRight: "10px" }}
              >
                <div className={style.sub_menu_title}>SDK 및 변환 서버</div>
                <div className={style.sub_menu_list}>
                  <div className={style.sub_menu_list_item}>
                    <div
                      className={style.sub_menu_list_item_name}
                      onClick={() => {
                        window.open("https://developers.foxit.com");
                      }}
                    >
                      PDF SDK
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      WEB & DESKTOP 전용 SDK
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => {
                      window.open(
                        "https://developers.foxit.com/developer-hub/"
                      );
                    }}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      SDK 문서
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      고객 맞춤형 SDK문서 제공
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => {
                      window.open(
                        "https://www.foxit.kr/enterprise-automation/"
                      );
                    }}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      변환서버
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      조직의 문서 압축, 변환, 수정 및 OCR 지원
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.sub_menu_group}>
                <div className={style.sub_menu_title}>솔루션</div>
                <div className={style.sub_menu_list}>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => {
                      window.open(
                        "https://www.foxit.kr/solution/paperless-office/"
                      );
                    }}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      페이퍼리스 오피스
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      워크플로우 연동 솔루션
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => {
                      window.open(
                        "https://www.foxit.kr/solution/document_security/"
                      );
                    }}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      PDF문서보안
                    </div>
                    <div className={style.sub_menu_list_item_text}>
                      문서보안 솔루션
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "company":
        return (
          <>
            <div className={style.sub_menu_btn_group3}>
              <div
                className={style.sub_menu_btn}
                onClick={() => menuHandler("free")}
              >
                무료체험 신청
              </div>
              <div
                className={style.sub_menu_btn + " " + style.sub_menu_btn2}
                onClick={() => menuHandler("request")}
              >
                견적 신청
              </div>
            </div>
            <div className={style.sub_menu}>
              <div className={style.sub_menu_group}>
                <div className={style.sub_menu_title}>회사 소개</div>
                <div className={style.sub_menu_list}>
                  <div className={style.sub_menu_list_item}>
                    <div
                      className={style.sub_menu_list_item_name}
                      onClick={() => submenucompany(1)}
                    >
                      인사말
                    </div>
                  </div>
                  <div className={style.sub_menu_list_item}>
                    <div
                      className={style.sub_menu_list_item_name}
                      onClick={() => submenucompany(2)}
                    >
                      조직도
                    </div>
                  </div>
                  <div className={style.sub_menu_list_item}>
                    <div
                      className={style.sub_menu_list_item_name}
                      onClick={() => submenucompany(3)}
                    >
                      오시는길
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "faq":
        return (
          <>
            {" "}
            <div className={style.sub_menu_btn_group4}>
              <div
                className={style.sub_menu_btn}
                onClick={() => menuHandler("free")}
              >
                무료체험 신청
              </div>
              <div
                className={style.sub_menu_btn + " " + style.sub_menu_btn2}
                onClick={() => menuHandler("request")}
              >
                견적 신청
              </div>
            </div>
            <div className={style.sub_menu3}>
              <div className={style.sub_menu_group}>
                <div
                  className={style.sub_menu_title}
                  style={{ paddingRight: "15px" }}
                >
                  지원
                </div>
                <div className={style.sub_menu_list}>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("faq")}
                  >
                    <div className={style.sub_menu_list_item_name}>FAQ</div>
                  </div>
                  {/* <div className={style.sub_menu_list_item}>
                    <div
                      className={style.sub_menu_list_item_name}
                      onClick={() => menuHandler("onsite_support")}
                    >
                      방문지원
                    </div>
                  </div> */}
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("online_support")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      원격지원
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.sub_menu_group}>
                <div className={style.sub_menu_title}>커뮤니티</div>
                <div className={style.sub_menu_list}>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("announcement")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      공지사항
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("event")}
                  >
                    <div className={style.sub_menu_list_item_name}>이벤트</div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() => menuHandler("case")}
                  >
                    <div className={style.sub_menu_list_item_name}>
                      도입사례
                    </div>
                  </div>
                  <div
                    className={style.sub_menu_list_item}
                    onClick={() =>
                      window.open("http://blog.naver.com/blue_tns")
                    }
                  >
                    <div className={style.sub_menu_list_item_name}>블로그</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
    if (!isOpenMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  };

  useEffect(() => {
    if (!isMobile && !isTablet && isOpenMenu) {
      setIsOpenMenu(!isOpenMenu);
      document.body.style.overflow = "hidden";
    }
  }, [isMobile, isTablet, isOpenMenu]);

  return (
    <>
      {pathname === "/admin" ? (
        <></>
      ) : pathname.includes("/admin/") ? (
        <AdminHeader />
      ) : (
        <>
          {isOpenMenu ? <SideMenu close={() => handleMenu()} /> : <></>}
          <div
            className={
              scrollHeader || isOpenMenu ? style.scrollHeader : style.header
            }
          >
            <div className={style.header_main}>
              <div className={style.header_left_area}>
                <img
                  className="cursor"
                  alt=""
                  src={
                    scrollHeader || isOpenMenu
                      ? "/img/common/blue_logo.png"
                      : "/img/common/logo.png"
                  }
                  onClick={() => {
                    if (isOpenMenu) {
                      handleMenu();
                    }
                    navigate(process.env.REACT_APP_CLIENT_URL);
                  }}
                />
              </div>

              {isMobile || isTablet ? (
                <></>
              ) : (
                <div className={style.header_center_area}>
                  {menuList.map((menu) => (
                    <div
                      key={menu.key}
                      className={style.menu}
                      onClick={() => menuHandler(menu.key)}
                      onMouseEnter={() => {
                        setIsHoverMenu(true);
                        setHoveringMenu(menu.key);
                      }}
                      onMouseLeave={() => {
                        setIsHoverMenu(false);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}> {menu.label}</span>
                      {isHoverMenu && hoveringMenu === menu.key ? (
                        <div
                          onClick={
                            ((e) => e.preventDefault(),
                            (e) => e.stopPropagation())
                          }
                        >
                          {renderSubMenu(menu.key)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              )}

              <div className={style.header_right_area}>
                <div className={style.product_btn}>제품 소개서</div>
                {isMobile || isTablet ? (
                  <div
                    className={
                      isOpenMenu
                        ? `${style.menu_btn} active`
                        : `${style.menu_btn}`
                    }
                    onClick={() => handleMenu()}
                  >
                    <span></span>
                    <span></span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {!isMobile && isHoverMenu ? (
              <div
                className={style.header_sub}
                onMouseEnter={() => {
                  setIsHoverMenu(true);
                }}
                onMouseLeave={() => {
                  setIsHoverMenu(false);
                }}
              ></div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Header;
