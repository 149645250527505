import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/board.module.css";
import axios from "axios";
import Paging from "components/common/paging";
import { useDispatch } from "react-redux";
import { openAlert, openConfirm } from "redux/slices/alertSlice";
import Adminuserpop from "components/common/adminuserpop";

function Adminuser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boardlist, setboardlist] = useState([]);
  const [init, setInit] = useState(false);
  const [pagerun, setpagerun] = useState(true);

  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 10,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0403",
        ROW_COUNT: page.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/user/userlist", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const getcount = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0403",
      },
    };

    axios.get("/user/counts", config).then((res) => {
      if (res.status === 200) {
        setPage({
          ...page,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun(false);
        setInit(true);
      }
    });
  }, [page]);

  useEffect(() => {
    if (pagerun) {
      getcount();
    }
  }, [getcount, pagerun]);

  const delbutton = (id) => {
    dispatch(
      openConfirm({
        title: "Notice",
        content: "삭제하시겠습니까 ?",
        submitEventHandler: () => deleteboard(id),
      })
    );
  };

  const deleteboard = (id) => {
    let config = {
      params: {
        OLD_ID: id,
      },
    };
    axios
      .put("/user/delete", null, config)
      .then((res) => {
        if (res.status === 200) {
          setpagerun(true);
          getlist();
        }
      })
      .finally(
        dispatch(
          openAlert({
            title: "Notice",
            content: "삭제되었습니다.",
            submitEventHandler: () => {},
          })
        )
      );
  };

  const [open, setopen] = useState(false);
  const setclose = () => {
    setopen(false);
  };
  const [info, setinfo] = useState({
    TYPE: "",
    ID: "",
    PW: "",
    NAME: "",
    OLD_ID: "",
  });
  const submitdata = () => {
    const data = {
      MEMBER_ID: info.ID,
      MEMBER_PW: info.PW,
      DISPLAY_NM: info.NAME,
      TYPE: info.TYPE,
      OLD_ID: info.OLD_ID,
    };

    let config = {
      params: {
        MEMBER_ID: info.ID,
        MEMBER_PW: info.PW,
        DISPLAY_NM: info.NAME,
        TYPE: info.TYPE,
        OLD_ID: info.OLD_ID,
      },
    };
    let url = "";
    if (info.TYPE === "C") {
      data.OLD_ID = info.ID;
      config.params.OLD_ID = info.ID;
      url = "/user/signup";
      axios.post(url, data, config).then((res) => {
        if (res.status === 200) {
          setinfo({
            TYPE: "",
            ID: "",
            PW: "",
            NAME: "",
            OLD_ID: "",
          });
          setclose();
          setpagerun(true);
          getlist();
        }
      }, []);
    } else {
      url = "/user/update";
      axios.put(url, data, config).then((res) => {
        if (res.status === 200) {
          setinfo({
            TYPE: "",
            ID: "",
            PW: "",
            NAME: "",
            OLD_ID: "",
          });
          setclose();
          setpagerun(true);
          getlist();
        }
      }, []);
    }
  };

  const openpop = () => {
    setopen(true);
  };

  const clickpop = (type, data) => {
    setinfo({
      TYPE: type,
      ID: data.MEMBER_ID,
      PW: "",
      NAME: data.DISPLAY_NM,
      OLD_ID: data.MEMBER_ID,
    });
    openpop();
  };

  return (
    <>
      {" "}
      <Adminuserpop
        open={open}
        TYPE={info.TYPE}
        ID={info.ID}
        PW={info.PW}
        NM={info.NAME}
        changeID={(value) => setinfo({ ...info, ID: value })}
        changePW={(value) => setinfo({ ...info, PW: value })}
        changeNM={(value) => setinfo({ ...info, NAME: value })}
        close={setclose}
        submit={() => submitdata()}
      />
      <div className={style.board_list_wrapper}>
        <div className={style.board_list_title}>
          <div>사용자 관리</div>
          <div
            className={style.board_list_input + " cursor"}
            onClick={() => clickpop("C", "")}
          >
            등록
          </div>
        </div>
        <div>
          <div className={style.board_list_header}>
            <div className={style.board_num}>No</div>
            <div className={style.board_title}>아이디</div>
            <div className={style.board_writer}>이름</div>
            <div className={style.board_date}>등록일자</div>
            <div className={style.board_date}>수정일자</div>
            <div className={style.board_del}>삭제</div>
          </div>
          <div className={style.board_list_body}>
            {boardlist.length > 0
              ? boardlist.map((data, index) => (
                  <div
                    className={style.board_list_body_line}
                    onClick={() => clickpop("U", data)}
                  >
                    <div className={style.board_num}>{index + 1}</div>
                    <div className={style.board_title}>{data.MEMBER_ID}</div>
                    <div className={style.board_writer}>{data.DISPLAY_NM}</div>
                    <div className={style.board_date}>{data.INPUT_DT}</div>
                    <div className={style.board_date}>{data.UPDT_DT}</div>

                    <div
                      className={style.board_del}
                      onClick={
                        ((e) => e.preventDefault(), (e) => e.stopPropagation())
                      }
                    >
                      <div onClick={() => delbutton(data.MEMBER_ID)}>삭제</div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
          <div>
            <Paging
              init={init}
              setInit={setInit}
              totalCount={page.totalCount}
              rowCount={page.rowCount}
              currentPage={page.currentPage}
              maxPage={page.maxPage}
              onClick={(res) => {
                setPage({ ...page, currentPage: res });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Adminuser;
