import React, { useCallback, useEffect, useState } from "react";
import style from "css/landing.module.css";
import { AnimatedCircle } from "./circle";
import { useMediaQuery } from "react-responsive";

/**
 * 파일명: SwiperArea.js
 * 작성자: 강연승
 * 작성일자: 24.02.15
 * 설명: landing page - SwiperArea
 */

function SwiperArea() {
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [count, setcount] = useState(0);
  const [imgchange, setimgchange] = useState(true);

  const timer = useCallback(() => {
    let data = count;
    setcount(data + 1);
  }, [count]);

  useEffect(() => {
    const timers = setInterval(() => {
      timer();
    }, 100);
    return () => {
      clearInterval(timers);
    };
  }, [timer]);
  useEffect(() => {
    if ((count / 100) % 2 > 1) {
      if (imgchange === true) {
        setimgchange(false);
      }
    } else {
      if (imgchange === false) {
        setimgchange(true);
      }
    }
  }, [count, imgchange]);

  const clickskip = () => {
    if (imgchange) {
      setcount(101);
      setimgchange(false);
    } else {
      setcount(0);
      setimgchange(true);
    }
  };

  return (
    <div className={style.wrapper}>
      <div
        className={imgchange ? style.background : style.background_none}
        style={{
          backgroundImage:
            "url('" +
            process.env.REACT_APP_CLIENT_URL +
            "img/landing/landing.png')",
        }}
      />
      <div
        className={imgchange ? style.background2 : style.background_none2}
        style={{
          backgroundImage:
            "url('" +
            process.env.REACT_APP_CLIENT_URL +
            "img/landing/landing2.png')",
        }}
      />
      <div className={style.background_blur} />
      <div className={style.donut}>
        <div
          className={
            !imgchange
              ? style.skipbtn + " cursor"
              : style.skbtn_none + " cursor"
          }
          onClick={() => clickskip()}
        />

        <svg viewBox="0 0 200 200" style={{ width: "20px", height: "20px" }}>
          <circle
            cx={"100"}
            cy="100"
            r="90"
            fill="none"
            stroke="beige"
            strokeWidth="17"
          />
          <AnimatedCircle
            cx="100"
            cy="100"
            r="90"
            fill="none"
            stroke="#098d95"
            strokeWidth="17"
            strokeDasharray={`${2 * Math.PI * 90 * ((count % 100) / 100)} ${
              2 * Math.PI * 90 * 1
            }`}
            strokeDashoffset={2 * Math.PI * 90 * 0}
          />
        </svg>
        <div
          className={
            imgchange ? style.skipbtn + " cursor" : style.skbtn_none + " cursor"
          }
          onClick={() => clickskip()}
        />
      </div>
      <div className={style.scrollicon}>
        <img
          alt=""
          src={process.env.REACT_APP_CLIENT_URL + "img/landing/scrollicon.png"}
        />
      </div>
      <div className={imgchange ? style.swiper_area : style.swiper_area_none}>
        <div className={style.swiper_text1}>
          {"모든 디테일이\n중요한 당신을 위한 선택,"}
        </div>
        <div className={style.swiper_text2}>ZWCAD 2025</div>
        <div className={style.swiper_text3}>
          CAD로 당신의 아이디어를 실현하세요
        </div>
      </div>
      <div className={imgchange ? style.swiper_area2 : style.swiper_area_none2}>
        <div className={style.swiper_text1}>
          {"뛰어난 사용자 경험을 제공하는 \n 최고의 PDF 변환 솔루션"}
        </div>
        <div className={style.swiper_text2}>Foxit PDF</div>
        <div className={style.swiper_text3}>
          모든 오피스 문서를 손쉽게 관리하세요.
        </div>
      </div>
    </div>
  );
}

export default SwiperArea;
