import React, { useState } from "react";
import style from "css/template.module.css";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

function SideMenu(props) {
  //navigate
  const navigate = useNavigate();
  const menuHandler = (e) => {
    props.close();
    console.log(e);
    if (e.type === "outside") {
      window.open(e.key);
    } else if (e.type === "company") {
      navigate(process.env.REACT_APP_CLIENT_URL + "company", {
        state: {
          title: e.key,
        },
      });
    } else {
      navigate(process.env.REACT_APP_CLIENT_URL + e.key);
    }
  };
  const menuList = [
    {
      label: "ZWCAD",
      key: "cad",
      children: [
        { label: "ZWCAD 2025", key: "zwcad" },
        { label: "ZW3D 2025", key: "zw3d" },
        { label: "ZDREAM", key: "zdream" },
        { label: "CAD 제품 비교", key: "cad_compare" },
        { label: "ZW3D 제품 비교", key: "zw3d_compare" },
      ],
    },
    {
      label: "Foxit PDF",
      key: "foxit_pdf",
      children: [
        { label: "Foxit PDF Editor", key: "foxit_pdf_editor" },
        { label: "Foxit PDF eSign", key: "foxit_pdf_esign" },
        { label: "PDF Reader", key: "pdf_reader" },
        {
          label: "PDF SDK",
          key: "https://developers.foxit.com",
          type: "outside",
        },
        {
          label: "SDK 문서",
          key: "https://developers.foxit.com/developer-hub/",
          type: "outside",
        },
        {
          label: "변환 서버",
          key: "https://www.foxit.kr/enterprise-automation/",
          type: "outside",
        },
        {
          label: "페이퍼리스 오피스",
          key: "https://www.foxit.kr/solution/paperless-office/",
          type: "outside",
        },
        {
          label: "PDF문서보안",
          key: "https://www.foxit.kr/solution/document_security/",
          type: "outside",
        },
      ],
    },
    {
      label: "Company",
      key: "compnay",
      children: [
        { label: "인사말", key: "1", type: "company" },
        { label: "조직도", key: "2", type: "company" },
        { label: "오시는길", key: "3", type: "company" },
      ],
    },
    {
      label: "지원 및 커뮤니티",
      key: "support&community",
      children: [
        { label: "FAQ", key: "faq" },
        // { label: "방문지원", key: "onsite_support" },
        { label: "원격지원", key: "online_support" },
        { label: "공지사항", key: "announcement" },
        { label: "이벤트", key: "event" },
        { label: "도입사례", key: "case" },
      ],
    },
  ];

  const [openMenu, setOpenMenu] = useState("");
  const handleOpenMenu = (e) => {
    setOpenMenu(e);
  };

  return (
    <div className={style.sidemenu_wrapper}>
      <div className={style.sidemenu_area}>
        {menuList.map((menu, index) => (
          <div
            key={index}
            className={
              openMenu === menu.key
                ? `${style.sidemenu_item_active}`
                : `${style.sidemenu_item}`
            }
            onClick={() =>
              handleOpenMenu(openMenu === menu.key ? "" : menu.key)
            }
          >
            <div className={style.sidemenu_item_title}>
              <div className={style.sidemenu_item_label}>{menu.label}</div>
              <div className={style.sidemenu_item_arrow}>
                <img alt="" src="/img/common/menu_arrow.png" />
              </div>
            </div>
            {openMenu === menu.key ? (
              <div className={style.sidemenu_submenu_list}>
                {menu.children.map((submenu, idx) => (
                  <div
                    key={idx}
                    className={style.sidemenu_submenu_item}
                    onClick={() => menuHandler(submenu)}
                  >
                    {submenu.label}
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
      <div className={style.sidemenu_footer}>
        <div
          className={style.sidemenu_footer_btn}
          onClick={() => menuHandler({ key: "free" })}
        >
          무료체험
        </div>
        <span></span>
        <div
          className={style.sidemenu_footer_btn}
          onClick={() => menuHandler({ key: "request" })}
        >
          견적 신청
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
