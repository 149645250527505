import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import style from "css/admin.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { insertUserInfo } from "redux/slices/userSlice";
import { Cookies } from "react-cookie";
import axios from "axios";
import { Button, ConfigProvider, Input } from "antd";
import { openAlert } from "redux/slices/alertSlice";
/**
 * 파일명: AdminLogin.js
 * 작성자: 강연승
 * 작성일자: 24.02.26
 * 설명: 관리자 로그인
 */
function AdminLogin() {
  //state
  const [loading, setLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [user, setUser] = useState({
    id: "",
    pw: "",
  });

  const { t } = useTranslation();

  //navigate
  const navigate = useNavigate();

  //dispatch
  const dispatch = useDispatch();

  //cookies
  const cookies = new Cookies();

  //ref
  const pwRef = useRef();

  //selector
  const userPayload = useSelector((state) => state.user);

  //env
  const authNm = process.env.REACT_APP_AUTH_TOKEN;
  // const authTime = process.env.REACT_APP_AUTH_TOKEN_TIME;
  const refreshNm = process.env.REACT_APP_REFRESH_TOKEN;
  const refreshTime = process.env.REACT_APP_REFRESH_TOKEN_TIME;
  // const refreshLongTime = process.env.REACT_APP_REFRESH_TOKEN_LONG_TIME;
  const domain = process.env.REACT_APP_DOMAIN;

  useEffect(() => {
    if (userPayload.name !== "") {
      navigate(process.env.REACT_APP_CLIENT_URL + "admin/user");
    }
  }, [navigate, userPayload.name]);

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  const loginHandler = () => {
    setLoading(true);

    const data = {
      MEMBER_ID: user.id,
      MEMBER_PW: user.pw,
    };

    axios
      .post("/open/signin", data)
      .then((res) => {
        if (res.status === 200) {
          const memeberInfo = res.data;
          cookies.set(authNm, memeberInfo.authToken, {
            path: "/",
            // maxAge: isCheck ? authTime : null,
            domain: domain,
          });

          cookies.set(refreshNm, memeberInfo.refreshToken, {
            path: "/",
            maxAge: isCheck ? refreshTime : null,
            domain: domain,
          });

          dispatch(
            insertUserInfo({
              name: memeberInfo.name,
              id: memeberInfo.id,
            })
          );

          //   const allowPages = res.data.ALLOW_PAGES;

          //   dispatch(insertAllowPageInfo({ pages: allowPages }));
          navigate(process.env.REACT_APP_CLIENT_URL + "admin/user");
        }
      })
      .catch((err) => {
        setLoading(false);
        // const props = {
        //   type: "info",
        //   title: t("LABEL.TITLE.MESSAGE"),
        //   content: t("MESSAGE." + err.response.data),
        //   okText: t("common.button.ok"),
        //   img:
        //     process.env.REACT_APP_CLIENT_URL +
        //     "img/common/modal_icon_green.png",
        //   btnEvent: () => setLoading(false),
        // };
        // OpenModal(props);

        dispatch(
          openAlert({
            title: "Notice",
            content: err.response.data,
            submitEventHandler: () =>
              (window.location.href = process.env.REACT_APP_CLIENT_URL),
          })
        );
      });
  };

  return (
    <>
      <div className={style.wrapper}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/landing/landing.png')",
          }}
        />
        <div className={style.login_area}>
          {/* <div className={style.login_title_wrap}>
            <div className={style.login_title}>관리자 로그인</div>
            <div className={style.login_subtitle}>
              고객과의 신뢰를 바탕으로 최적의 소프트웨어·IT 솔루션 파트너
            </div>
          </div> */}
          <section className={style.section}>
            <div>
              <img
                className={style.logo}
                src={process.env.REACT_APP_CLIENT_URL + "img/common/logo.png"}
                alt=""
              />

              <p className={style.title}>관리자 로그인</p>
            </div>

            <div>
              <div className={style.item}>
                <p className={style.item_title}>Admin ID</p>
                <ConfigProvider
                  theme={{
                    components: {
                      Input: {
                        colorBgContainer: "transparent",
                        colorTextPlaceholder: "white",
                        colorText: "white",
                      },
                    },
                  }}
                >
                  <Input
                    className={style.item_input}
                    placeholder="관리자 아이디를 입력하세요."
                    // disabled={loading}
                    onChange={(e) => setUser({ ...user, id: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        pwRef.current.focus();
                      }
                    }}
                  />
                </ConfigProvider>
              </div>

              <div className={style.item}>
                <p className={style.item_title}>Password</p>
                <ConfigProvider
                  theme={{
                    components: {
                      Input: {
                        colorBgContainer: "transparent",
                        colorTextPlaceholder: "white",
                        colorText: "white",
                        colorIcon: "white",
                        colorIconHover: "white",
                      },
                    },
                  }}
                >
                  <Input.Password
                    ref={pwRef}
                    className={style.item_input}
                    placeholder="비밀번호를 입력하세요."
                    // disabled={loading}
                    onChange={(e) => setUser({ ...user, pw: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        loginHandler();
                      }
                    }}
                  />
                </ConfigProvider>
              </div>

              <Button
                className={style.button}
                type="primary"
                loading={loading}
                onClick={() => loginHandler()}
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                로그인
              </Button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
