import React, { useCallback, useEffect, useState } from "react";
import style from "css/support.module.css";
import { useNavigate } from "react-router-dom";
import Faqdata from "./faqdata";
import axios from "axios";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/slices/alertSlice";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

function Faq() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const [listrun, setlistrun] = useState(true);
  const [countrun, setcountrun] = useState(true);
  const [keyword, setkeyword] = useState("");
  const [searchword, setsearchword] = useState("");
  const [counts, setcounts] = useState({
    total: 0,
    now: 5,
  });

  const getlist = useCallback(() => {
    var count = counts.now + 5;
    let config = {
      params: {
        KEY_WORD: searchword,
        ROW_COUNT: count,
        PAGE_COUNT: 0,
        TYPE: "CD0403",
      },
    };

    axios.get("/open/boardlist", config).then((res) => {
      if (res.status === 200) {
        setfaqlist(res.data);
        setlistrun(false);
      }
    });
  }, [counts, searchword]);
  useEffect(() => {
    if (listrun) {
      getlist();
    }
  }, [listrun, getlist]);

  const [faqlist, setfaqlist] = useState([]);

  const getcounts = useCallback(() => {
    let config = {
      params: {
        KEY_WORD: searchword,
        TYPE: "CD0403",
      },
    };
    axios.get("/open/boardcount", config).then((res) => {
      if (res.status === 200) {
        setcounts({ ...counts, total: res.data });
        setcountrun(false);
      }
    });
  }, [searchword, counts]);
  useEffect(() => {
    if (countrun) {
      getcounts();
    }
  }, [countrun, getcounts]);

  const viewmore = () => {
    if (counts.total > counts.now) {
      var plus = counts.now + 5;
      setcounts({ ...counts, now: plus });
      setlistrun(true);
    } else {
      dispatch(
        openAlert({
          title: "Notice",
          content: "모든 항목이 표시되었습니다.",
          submitEventHandler: () => {},
        })
      );
    }
  };

  const search = () => {
    setsearchword(keyword);
    setcounts({ ...counts, now: 5 });
    action();
  };

  const action = () => {
    setlistrun(true);
    setcountrun(true);
  };

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    // { TITLE: "방문지원", LINK: "onsite_support" },
    { TITLE: "원격지원", LINK: "online_support" },
  ];

  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/support/support_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.support_area}>
          <div className={style.support_title_wrap}>
            <div className={style.support_title}>지원</div>
            <div className={style.support_subtitle}>Support</div>
          </div>
        </div>
      </div>
      <div className={style.support_category}>
        {isTablet ? (
          <Mobilemenu selected="FAQ" list={menulist} type="" />
        ) : (
          <div className={style.support_category_buttons}>
            <div
              className={
                style.support_button + " " + style.button_focus + " cursor"
              }
              onClick={() => {
                menuHandler("faq");
              }}
            >
              FAQ
            </div>
            {/* <div
              className={style.support_button + " cursor"}
              onClick={() => {
                menuHandler("onsite_support");
              }}
            >
              방문지원
            </div> */}
            <div
              className={style.support_button + " cursor"}
              onClick={() => {
                menuHandler("online_support");
              }}
            >
              원격지원
            </div>
          </div>
        )}
      </div>

      <div className={style.faqsearchline}>
        <div className={style.faqsearch}>
          <div className={style.faqsearchbox}>
            <input
              type="text"
              placeholder="궁금한점을 검색해보세요"
              className={style.faqsearchinput}
              value={keyword}
              onChange={(e) => setkeyword(e.target.value)}
            />
            <img
              alt=""
              src={
                process.env.REACT_APP_CLIENT_URL + "img/common/search_icon.png"
              }
              className={style.searchicon}
              onClick={() => search()}
            />
          </div>
        </div>
      </div>
      <div className={style.faqlist}>
        {faqlist.map((data, index) => (
          <div className={style.faqline}>
            <Faqdata status={false} data={data} />
          </div>
        ))}
      </div>
      <div className={style.faqmore + " cursor"} onClick={() => viewmore()}>
        더보기 +
      </div>
    </>
  );
}

export default Faq;
