import React from "react";
import style from "css/adminMenu.module.css";
import AdminMenubtn from "./adminMenuBtn";

function AdminMenu() {
  return (
    <div className={style.sidebar_wrap}>
      <div className={`${style.sidebar} ${style.bg_white}`}>
        {/* <AdminMenubtn // SideMenu 기본정보
          img="/img/admin/basicinfo.png"
          altImg="info"
          menuName="기본정보"
          dropList={[]}
          dropLink={["main"]}
        /> */}
        <AdminMenubtn // SideMenu 회원관리
          img="/img/admin/admin_user.png"
          altImg="user"
          menuName="사용자 관리"
          dropList={[]}
          dropLink={["user"]}
        />
        {/* <AdminMenubtn // SideMenu 시스템관리
          img="/img/admin/conference.png"
          altImg="info"
          menuName="제품 소개서 관리"
          dropList={[]}
          dropLink={["product"]}
        /> */}
        <AdminMenubtn // 공지사항
          img="/img/admin/admin_notice.png"
          altImg="notice"
          menuName="공지사항"
          dropList={[]}
          dropLink={["notice"]}
        />
        <AdminMenubtn //이벤트
          img="/img/admin/admin_event.png"
          altImg="event"
          menuName="이벤트"
          dropList={[]}
          dropLink={["event"]}
        />
        {/* <AdminMenubtn // 지원
          img="/img/admin/stats.png"
          altImg="stats"
          menuName="지원"
          dropList={[]}
          dropLink={["support"]}
        /> */}
        <AdminMenubtn // FAQ
          img="/img/admin/admin_faq.png"
          altImg="stats"
          menuName="FAQ"
          dropList={[]}
          dropLink={["faq"]}
        />{" "}
        <AdminMenubtn // FAQ
          img="/img/admin/admin_request.png"
          altImg="stats"
          menuName="견적요청"
          dropList={[]}
          dropLink={["request"]}
        />
        {/* <AdminMenubtn // FAQ
          img="/img/admin/admin_support.png"
          altImg="stats"
          menuName="방문지원"
          dropList={[]}
          dropLink={["onlinesupport"]}
        /> */}
        <AdminMenubtn // FAQ
          img="/img/admin/admin_free.png"
          altImg="stats"
          menuName="무료체험"
          dropList={[]}
          dropLink={["free"]}
        />
        <AdminMenubtn // FAQ
          img="/img/admin/admin_files.png"
          altImg="stats"
          menuName="제품소개서"
          dropList={[]}
          dropLink={["product"]}
        />
        <AdminMenubtn // FAQ
          img="/img/admin/admin_popup.png"
          altImg="stats"
          menuName="팝업관리"
          dropList={[]}
          dropLink={["popup"]}
        />
        {/* <AdminMenubtn // 시스템코드(공통코드)
          img="/img/admin/basicinfo.png"
          altImg="stats"
          menuName="공통 권한 설정"
          dropList={[
            "공통 코드",
            "공통 권한 그룹 관리",
            "공통 메뉴 관리",
            "사용자 허용 IP",
            "메뉴 권한 매핑",
            "사용자 권한 매핑",
          ]}
          dropLink={[
            "sysCode",
            "sysAuthGroup",
            "sysMenu",
            "allowIp",
            "menuAuth",
            "memberAuth",
          ]}
        /> */}
      </div>
    </div>
  );
}

export default AdminMenu;
