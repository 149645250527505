import React from "react";
import ReactModal from "react-modal";
function Contactalert(props) {
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="contact_alert" //모달 ClassName
      overlayClassName="contact_alert" ///모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <div>
        <div>{props.header}</div>
        <div></div>
        <div>확인</div>
      </div>
    </ReactModal>
  );
}

export default Contactalert;
