import React, { useEffect, useRef, useState } from "react";
import style from "css/company.module.css";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

/**
 * Version : 1.0
 * 작성일자 : 2024-02-16
 * 작성자 : 강연승
 * 설명 : Company 페이지
 */

function Company() {
  const [ref, inView] = useInView({
    triggerOnce: false, // 기본 : false
  });

  const [ref2, inView2] = useInView({
    triggerOnce: false, // 기본 : false
  });

  const location = useLocation();
  const contentRef = useRef(null);
  const content1Ref = useRef(null);
  const content2Ref = useRef(null);
  const content3Ref = useRef(null);
  useEffect(() => {
    if (location.state === null) {
      contentRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      if (location.state.title === 1) {
        content1Ref.current?.scrollIntoView({ behavior: "smooth" });
      } else if (location.state.title === 2) {
        content2Ref.current?.scrollIntoView({ behavior: "smooth" });
      } else if (location.state.title === 3) {
        content3Ref.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: " (min-width: 768px) and (max-width: 1024px)",
  });
  const org_box_small = (name) => {
    return (
      <div className={style.org_line5}>
        <div className={style.org_line_h4} />
        <div className={style.org_box_small}>
          <p
            className={
              ios
                ? style.org_box_small_font_on
                : inView
                ? style.org_box_small_font_on
                : style.org_box_small_font
            }
          >
            {name}
          </p>
        </div>
      </div>
    );
  };
  const [ios, setMobile] = useState(false);
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setMobile(false);
    } else {
      // desktop
      setMobile(false);
    }
  }, []);

  return (
    <>
      <div className={style.wrapper_h} ref={contentRef}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/company/company_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.company_area}>
          <div className={style.company_title_wrap}>
            <div className={style.company_title}>
              Introduction {isMobile ? <p /> : ""} of Company
            </div>
            <div className={style.company_subtitle}>
              고객과의 신뢰를 바탕으로 {isMobile ? <p /> : ""}최적의
              소프트웨어·IT 솔루션 파트너
            </div>
          </div>
        </div>
      </div>

      <div className={style.wrapper} ref={content1Ref}>
        <div className={style.introduce}>
          <div className={style.introduce_header}>
            <div className={style.introduce_title}>Trust base is our pride</div>
            <div className={style.introduce_subtitle}>
              신뢰의 기반은 {isMobile ? <p /> : ""}우리의 자부심입니다.
            </div>
          </div>
          <div className={style.introduce_body}>
            <div
              className={style.introduce_bgimg}
              style={{
                backgroundImage:
                  "url('" +
                  process.env.REACT_APP_CLIENT_URL +
                  "img/company/company_introduce.png')",
              }}
            >
              <div className={style.background_blur3} />
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/company/company_logo.png"
                }
                className={
                  inView2
                    ? style.company_introduce_logo_on
                    : style.company_introduce_logo
                }
              />
            </div>
            <div className={style.introduce_body_text}>
              <div className={style.introduce_body_header}>BLUETNS</div>
              <div className={style.introduce_text} ref={ref2}>
                고객의 신뢰를 최우선으로 하는 IT Service Leader
                블루티엔에스입니다!
              </div>
              <div className={style.introduce_text}>
                'Trust base is our pride'라는 슬로건 아래, 2012년부터 ZWCAD EA
                총판으로서 고객들에게 안정적이고 혁신적인 ZWCAD 솔루션을 제공해
                왔습니다.
              </div>
              <div className={style.introduce_text}>
                이제 우리는 Foxit PDF EA &amp; AEC 총판을 함께하게 되어, 더
                다양하고 전문화된 소프트웨어와 IT컨설팅 및 개발 경험을 통해
                고객사의 성공 파트너가 되도록 노력하겠습니다.
              </div>
              <div className={style.introduce_text}>
                앞으로도 많은 성원과 애정 어린 관심으로 지켜봐 주시길 바라며,
                파트너로서 고객 여러분의 발전과 성공에 함께할 것을
                약속드리겠습니다. <br />
                감사합니다.
              </div>
              <div className={style.introduce_footer}>
                (주)블루티엔에스 대표이사 박인호
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.wrapper_org} ref={content2Ref}>
        <div
          className={style.background_org}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/company/company_ogbg.png')",
          }}
        >
          <div className={style.background_blur2}></div>
        </div>
        <div className={style.company_org_area} id="focus2">
          <div className={style.company_org_title_wrap}>
            <div className={style.organization}>
              <div className={style.organization_title}>회사 조직도</div>
              <div className={style.organization_subtitle} ref={ref}>
                ORGANIZATION CHART
              </div>

              <div
                className={
                  ios
                    ? style.organization_action_on
                    : inView
                    ? style.organization_action_on
                    : style.organization_action
                }
              >
                <div className={style.org_box_big}>
                  <p
                    className={
                      ios
                        ? style.org_box_big_font_on
                        : inView
                        ? style.org_box_big_font_on
                        : style.org_box_big_font
                    }
                  >
                    대표이사
                  </p>
                </div>
                <div className={style.org_line_h} />
                <div className={style.org_line2}>
                  <div className={style.org_box_big}>
                    <p
                      className={
                        ios
                          ? style.org_box_big_font_on
                          : inView
                          ? style.org_box_big_font_on
                          : style.org_box_big_font
                      }
                    >
                      자문위원 / 감사
                    </p>
                  </div>
                  <div className={style.org_line_w} />
                  <div className={style.org_line2_1}>
                    <div className={style.org_line_h2} />
                    <div className={style.org_line2_c} />
                    <div className={style.org_line_h2} />
                  </div>
                  <div className={style.org_line_w} />
                  <div className={style.org_box_big}>
                    <p
                      className={
                        ios
                          ? style.org_box_big_font_on
                          : inView
                          ? style.org_box_big_font_on
                          : style.org_box_big_font
                      }
                    >
                      기술연구소
                    </p>
                  </div>
                </div>
                <div className={style.org_line_h} />
                <div className={style.org_line3}>
                  <div className={style.org_line_h3} />
                  <div className={style.org_line_w3} />
                  <div className={style.org_line_h3} />
                  <div className={style.org_line_w3} />
                  <div className={style.org_line_h3} />
                  <div className={style.org_line_w3} />
                  <div className={style.org_line_h3} />
                </div>
                <div className={style.org_line4}>
                  <div className={style.org_line4_h}>
                    {org_box_small("경영지원 사업부")}
                    {org_box_small("경영지원팀")}
                    {org_box_small("영업지원팀")}
                  </div>
                  <div className={style.org_line4_h}>
                    {org_box_small("소프트웨어 사업부")}
                    {org_box_small("영업 1팀")}
                    {org_box_small("영업 2팀")}
                  </div>
                  <div className={style.org_line4_h}>
                    {org_box_small("고객지원 사업부")}
                    {org_box_small(" ZWCAD 기술팀 ")}
                    {org_box_small(" Foxit 기술팀")}
                  </div>{" "}
                  <div className={style.org_line4_h}>
                    {org_box_small("마케팅 사업부")}
                    {org_box_small("마케팅팀")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.wrapper_cometo} ref={content3Ref}>
        <div className={style.cometo}>
          <div className={style.comto_header}>
            <div className={style.cometo_title}>
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/company/company_mark.png"
                }
                className={style.cometo_mark}
              ></img>
              <div className={style.cometo_title_text}>
                <label className={style.cometo_blue}>블루티엔에스 </label>
                <label>
                  오시는 길 <p className={style.comto_black}>안내해드립니다.</p>
                </label>
              </div>
            </div>
            <div className={style.cometo_line}></div>
            <div className={style.cometo_info}>
              <div className={style.cometo_info1}>
                <div className={style.cometo_address}>
                  <div className={style.cometo_info_header}>
                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/company/company_address.png"
                      }
                      className={style.cometo_info_header_img}
                    />
                    <div className={style.cometo_info_header_text}>ADDRESS</div>
                  </div>
                  <div className={style.cometo_info_body}>
                    서울 서초구 강남대로101길 43 유영빌딩 6층 (잠원동 9-4)
                  </div>
                </div>
                <div className={style.cometo_tel}>
                  <div className={style.cometo_info_header}>
                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/company/company_tel.png"
                      }
                      className={style.cometo_info_header_img}
                    />
                    <div className={style.cometo_info_header_text}>TEL</div>
                  </div>
                  <div className={style.cometo_info_body}>
                    대표번호: 02-595-7782
                  </div>
                </div>
              </div>
              <div className={style.cometo_info2}>
                {" "}
                <div className={style.cometo_subway}>
                  <div className={style.cometo_info_header}>
                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/company/company_subway.png"
                      }
                      className={style.cometo_info_header_img}
                    />
                    <div className={style.cometo_info_header_text}>SUBWAY</div>
                  </div>
                  <div className={style.cometo_subway_img}>
                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/company/company_subway_O.png"
                      }
                      className={style.cometo_subway_img_o}
                    />
                    <img
                      alt=""
                      className={style.cometo_subway_img_m}
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/company/company_subway_R.png"
                      }
                    />
                    신사역
                  </div>
                  <div className={style.cometo_info_body2}>
                    신사역 5번출구로 나오신 후 , U턴하여 우측 골목 (GS남서울
                    주유소)으로 300m 내려오시면
                  </div>
                  <div className={style.cometo_info_body2}>
                    좌측에 위치한 파란건물 유영빌딩 6층
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.cometo_body}>
            {isMobile ? (
              <div
                className={style.company_map_wrapper}
                style={{
                  font: "12px AppleSDGothicNeo-Regular, dotum, sans-serif",
                  letterSpacing: "-1px",
                  width: "360px",
                  height: "272px",
                  color: "rgb(51, 51, 51)",
                  position: "relative",
                }}
              >
                <div style={{ height: "240px" }}>
                  <a
                    href="https://map.kakao.com/?urlX=503850&amp;urlY=1116111&amp;itemId=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;srcid=1015547559&amp;map_type=TYPE_MAP&amp;from=roughmap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="map"
                      src="http://t1.daumcdn.net/roughmap/imgmap/f1240c7545acee72ee2b95c4cf07006123f32b76f1dbf4527b440b285b0d93f9"
                      style={{ border: "1px solid rgb(204, 204, 204)" }}
                      alt=""
                    />
                  </a>
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    padding: "7px 11px;",
                    border: "1px solid rgba(0, 0, 0, 0.1);",
                    borderRadius: "0px 0px 2px 2px;",
                    backgroundColor: "rgb(249, 249, 249)",
                  }}
                >
                  <a
                    href="https://map.kakao.com"
                    target="_blank"
                    style={{ float: "left" }}
                    rel="noopener noreferrer"
                  >
                    <img
                      src="//t1.daumcdn.net/localimg/localimages/07/2018/pc/common/logo_kakaomap.png"
                      width="72"
                      height="16"
                      alt="카카오맵"
                      style={{
                        display: "block",
                        width: "72px",
                        height: "16px",
                      }}
                    />
                  </a>
                  <div
                    style={{
                      float: "right",
                      position: "relative",
                      top: "1px",
                      fontSize: "11px",
                    }}
                  >
                    <a
                      target="_blank"
                      href="https://map.kakao.com/?from=roughmap&amp;srcid=1015547559&amp;confirmid=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;rv=on"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      로드뷰
                    </a>
                    <span
                      style={{
                        width: "1px",
                        padding: "0",
                        margin: "0 8px 0 9px",
                        height: "11px",
                        verticalAlign: "top",
                        position: "relative",
                        top: "2px",
                        borderLeft: "1px solid #d0d0d0",
                        float: "left",
                      }}
                    ></span>
                    <a
                      target="_blank"
                      href="https://map.kakao.com/?from=roughmap&amp;eName=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;eX=503850&amp;eY=1116111"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      길찾기
                    </a>
                    <span
                      style={{
                        width: "1px",
                        padding: "0",
                        margin: "0 8px 0 9px",
                        height: "11px",
                        verticalAlign: "top",
                        position: "relative",
                        top: "2px",
                        borderLeft: "1px solid #d0d0d0",
                        float: "left",
                      }}
                    ></span>
                    <a
                      target="_blank"
                      href="https://map.kakao.com?map_type=TYPE_MAP&amp;from=roughmap&amp;srcid=1015547559&amp;itemId=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;urlX=503850&amp;urlY=1116111"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      지도 크게 보기
                    </a>
                  </div>
                </div>
              </div>
            ) : isTablet ? (
              <div
                style={{
                  font: "normal normal 400 12px/normal dotum, sans-serif",
                  width: "720px",
                  height: "342px",
                  color: "#333",
                  position: "relative",
                }}
              >
                <div style={{ height: "310px" }}>
                  <a
                    href="https://map.kakao.com/?urlX=503850.0&amp;urlY=1116111.0&amp;itemId=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;srcid=1015547559&amp;map_type=TYPE_MAP&amp;from=roughmap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="map"
                      src="http://t1.daumcdn.net/roughmap/imgmap/13322e7e76fc49192ccce42349faa090d024e1ee5fd473f68aad6783171e0785"
                      style={{ border: "1px solid #ccc" }}
                      alt=""
                    />
                  </a>
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    padding: "7px 11px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "0px 0px 2px 2px",
                    backgroundColor: "rgb(249, 249, 249)",
                  }}
                >
                  <a
                    href="https://map.kakao.com"
                    target="_blank"
                    style={{ float: "left" }}
                    rel="noopener noreferrer"
                  >
                    <img
                      src="//t1.daumcdn.net/localimg/localimages/07/2018/pc/common/logo_kakaomap.png"
                      alt="카카오맵"
                      style={{
                        display: "block",
                        width: "72px",
                        height: "16px",
                      }}
                    />
                  </a>
                  <div
                    style={{
                      float: "right",
                      position: "relative",
                      top: "1px",
                      fontSize: "11px",
                    }}
                  >
                    <a
                      target="_blank"
                      href="https://map.kakao.com/?from=roughmap&amp;srcid=1015547559&amp;confirmid=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;rv=on"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      로드뷰
                    </a>
                    <span
                      style={{
                        width: "1px",
                        padding: "0",
                        margin: "0 8px 0 9px",
                        height: "11px",
                        verticalAlign: "top",
                        position: "relative",
                        top: "2px",
                        borderLeft: "1px solid #d0d0d0",
                        float: "left",
                      }}
                    ></span>
                    <a
                      target="_blank"
                      href="https://map.kakao.com/?from=roughmap&amp;eName=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;eX=503850.0&amp;eY=1116111.0"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      길찾기
                    </a>
                    <span
                      style={{
                        width: "1px",
                        padding: "0",
                        margin: "0 8px 0 9px",
                        height: "11px",
                        verticalAlign: "top",
                        position: "relative",
                        top: "2px",
                        borderLeft: "1px solid #d0d0d0",
                        float: "left",
                      }}
                    ></span>
                    <a
                      target="_blank"
                      href="https://map.kakao.com?map_type=TYPE_MAP&amp;from=roughmap&amp;srcid=1015547559&amp;itemId=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;urlX=503850.0&amp;urlY=1116111.0"
                      style={{
                        float: "left",
                        mheight: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      지도 크게 보기
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={style.company_map_wrapper}
                style={{
                  font: "12px AppleSDGothicNeo-Regular, dotum, sans-serif",
                  letterSpacing: "-1px",

                  height: "462px",
                  color: "rgb(51, 51, 51)",
                  position: "relative",
                }}
              >
                <div className={style.company_map_wrap}>
                  <a
                    href="https://map.kakao.com/?urlX=503850&amp;urlY=1116111&amp;itemId=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;srcid=1015547559&amp;map_type=TYPE_MAP&amp;from=roughmap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="http://t1.daumcdn.net/roughmap/imgmap/de15c0a69c8090ec29b18edf28d237ca6b52aa29ab798f8abf7e77b299e9deda"
                      // width="1278"
                      // height="428"
                      style={{ border: "1px solid rgb(204, 204, 204)" }}
                      alt=""
                      className={style.company_map}
                    ></img>
                  </a>
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    padding: "7px 11px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "0px 0px 2px 2px",
                    backgroundColor: "rgb(249, 249, 249)",
                  }}
                  className={style.company_mapunder}
                >
                  <a
                    href="https://map.kakao.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ float: "left" }}
                  >
                    <img
                      src="//t1.daumcdn.net/localimg/localimages/07/2018/pc/common/logo_kakaomap.png"
                      width="72"
                      height="16"
                      alt="카카오맵"
                      style={{
                        display: "block",
                        width: "72px",
                        height: "16px",
                      }}
                    ></img>
                  </a>
                  <div
                    style={{
                      float: "right",
                      position: "relative",
                      top: "1px",
                      fontSize: "11px",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://map.kakao.com/?from=roughmap&amp;srcid=1015547559&amp;confirmid=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;rv=on"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                    >
                      로드뷰
                    </a>
                    <span
                      style={{
                        width: "1px",
                        padding: "0",
                        margin: "0 8px 0 9px",
                        height: "11px",
                        verticalAlign: "top",
                        position: "relative",
                        top: "2px",
                        borderLeft: "1px solid #d0d0d0",
                        float: "left",
                      }}
                    ></span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://map.kakao.com/?from=roughmap&amp;eName=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;eX=503850&amp;eY=1116111"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                    >
                      길찾기
                    </a>
                    <span
                      style={{
                        width: "1px",
                        padding: "0",
                        margin: "0 8px 0 9px",
                        height: "11px",
                        verticalAlign: "top",
                        position: "relative",
                        top: "2px",
                        borderLeft: "1px solid #d0d0d0",
                        float: "left",
                      }}
                    ></span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://map.kakao.com?map_type=TYPE_MAP&amp;from=roughmap&amp;srcid=1015547559&amp;itemId=1015547559&amp;q=%EB%B8%94%EB%A3%A8%ED%8B%B0%EC%97%94%EC%97%90%EC%8A%A4&amp;urlX=503850&amp;urlY=1116111"
                      style={{
                        float: "left",
                        height: "15px",
                        paddingTop: "1px",
                        lineHeight: "15px",
                        color: "#000",
                        textDecoration: "none",
                      }}
                    >
                      지도 크게 보기
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Company;
