import React, { useCallback, useEffect, useState } from "react";
import style from "css/foxit.module.css";
import Underbar from "components/common/underbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";
function Foxitpdfesign() {
  const navigate = useNavigate();

  const [data, setdata] = useState({
    LINK: "",
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TITLE: "FOXITPDFESIGN",
      },
    };
  }, []);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const makeTag = (padding, contents, margin) => {
    return (
      <div
        style={{ padding: "0px " + padding + "px", marginRight: margin + "px" }}
        className={style.function_info_tag}
      >
        {contents}
      </div>
    );
  };
  const makeContents = (contents, letterspacing) => {
    return (
      <div className={style.foxit_contents_check}>
        <div className={style.foxit_contents_img}>
          <img
            className={style.foxit_contents_img}
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + "img/foxit/check.png"}
          />
        </div>
        <div
          style={{ letterSpacing: letterspacing + "px" }}
          className={style.foxit_contents_txt}
        >
          {contents}
        </div>
      </div>
    );
  };
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const makeHashtag = (contents, margin) => {
    return <label className={style.hashtag_txt}>{contents}</label>;
  };
  const featurecard = (img, text) => {
    return (
      <div className={style.feature_card}>
        <div
          className={style.feature_img}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/foxit/" +
              img +
              ".png')",
          }}
        ></div>
        <div className={style.feature_text}>{text}</div>
      </div>
    );
  };

  const checking = useMediaQuery({
    query: "(min-width: 1025px) and (max-width: 1300px)",
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    { TITLE: "Foxit PDF Editor", LINK: "foxit_pdf_editor" },

    { TITLE: "PDF Reader", LINK: "pdf_reader" },
  ];
  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={
            isMobile
              ? {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/esign_bg_m.png')",
                }
              : {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/esign_bg.png')",
                }
          }
        >
          {" "}
          {isMobile ? <img alt="" src="/img/foxit/esign_img_m.png" /> : <></>}
        </div>

        <div className={style.foxit_area}>
          <div className={style.foxit_title_wrap}>
            <div className={style.foxit_title}>Foxit PDF eSign</div>
            <div className={style.foxit_subtitle}>Foxit 전자서명 서비스</div>
            <div className={style.foxit_title_buttons}>
              <div
                className={style.foxit_title_button2 + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "request")
                }
              >
                견적 신청
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.foxit_category}>
        {isTablet ? (
          <Mobilemenu selected="Foxit PDF eSign" list={menulist} type="" />
        ) : (
          <div className={style.foxit_category_buttons}>
            <div
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "foxit_pdf_editor")
              }
              className={style.foxit_button + " cursor"}
            >
              Foxit PDF Editor
            </div>
            <div
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "foxit_pdf_esign")
              }
              className={
                style.foxit_button + " " + style.button_focus + " cursor"
              }
            >
              Foxit PDF eSign
            </div>
            <div
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "pdf_reader")
              }
              className={style.foxit_button + " cursor"}
            >
              PDF Reader
            </div>
          </div>
        )}
      </div>
      <div className={style.foxit_wrapper}>
        <div className={style.foxit_introduce}>
          <p className={style.foxit_introduce_p1}>
            강력하고 완벽한 서명 솔루션
          </p>
          <p className={style.foxit_introduce_p2}>Foxit eSign</p>
          <p className={style.foxit_introduce_p3}>
            Foxit eSign은 중요한 서명 문서를 준비하고
          </p>
          <p className={style.foxit_introduce_p3}>
            <label className={style.focus_text}>
              수집하기 위한 법적 효력이 있는 전자 서명 서비스
            </label>
            입니다.
          </p>
          <p className={style.foxit_introduce_p3}>
            <label className={style.focus_text}>
              문서 업로드, 템플릿 생성, 수신자 추가 및 보내기
            </label>
            와 같은 완벽한 전자 서명 솔루션을 제공합니다.
          </p>
          <p className={style.foxit_introduce_p3}>
            Foxit eSign은 워크플로를 자동화하여 사용자가 브라우저를 사용하여{" "}
          </p>
          <p className={style.foxit_introduce_p3}>
            <label className={style.focus_text}>
              {" "}
              서명 프로세스를 전송, 서명, 추적 및 관리할 수 있도록 원활한
              프로세스를 제공
            </label>
            합니다.
          </p>
        </div>
        <div className={style.foxit_contents}>
          <div className={style.foxit_pdf_img_box}>
            <img
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/esign1.png"}
              className={style.foxit_pdf_img}
            />
          </div>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>
              문서, 계약서, 양식, 템플릿 등을 준비 하세요.
            </div>
            <div className={style.foxit_info_contents2}>
              {makeContents(
                "파일을 업로드하고 서명자 필드를 추가하여 몇 분 안에 문서와 계약서를 빠르고 쉽게 만들고 준비하십시오.",
                0
              )}
              {makeContents(
                "더 빠른 사용을 위해 템플릿을 저장하거나 Foxit eSign의 미리 만들어진 템플릿 중 하나를 사용하여 문서 준비 프로세스를 더욱 단순화하십시오.",
                0
              )}
            </div>
            <div className={style.foxit_info_hashtag}>
              {makeHashtag("# 템플릿 제작", 14)}
              {makeHashtag("# 더 쉬운 문서 완성 프로세스 활성화", 16)}
              {makeHashtag("# 서명자 역할 및 서명 순서 정의", 0)}
              {makeHashtag("# 색상 및 로고 사용자 정의", 12)}
              {makeHashtag("# 업로드된 문서 변환", 12)}
              {makeHashtag("# 미리 만들어진 템플릿으로 단순화", 0)}
              {makeHashtag("# 서명 및 데이터 필드 추가", 14)}
            </div>
          </div>
        </div>{" "}
        <div className={style.foxit_contents}>
          <div className={style.foxit_pdf_img_box}>
            <img
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/esign2.png"}
              className={style.foxit_pdf_img}
            />
          </div>
          <div className={style.foxit_info}>
            <div
              className={style.foxit_info_title + " " + style.foxit_info_title2}
            >
              어디에서든 즉시 서명 문서를 보냅니다.
            </div>
            <div className={style.foxit_info_contents2}>
              {makeContents(
                "클릭 몇 번으로 전 세계 어디에서나 거의 모든 장치에서 문서를 보내고 받을 수 있습니다.",
                0
              )}
              {makeContents(
                "발신자는 전체 서명 프로세스를 모니터링하여 자세한 감사를 통해 적시에 완료되도록 할 수 있습니다.",
                -0.16
              )}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(checking ? 8 : 13, "# 서명자 추가", 0)}
              {makeTag(checking ? 8 : 13, "# 서명 요청 문서 전송", 0)}
              {makeTag(checking ? 8 : 13, "# 수신자 알림", 0)}
              {makeTag(checking ? 8 : 13, "# 전체 과정을 감사", 0)}
            </div>
          </div>
        </div>{" "}
        <div className={style.foxit_contents}>
          <div className={style.foxit_pdf_img_box}>
            <img
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/foxit/esign3.png"}
              className={style.foxit_pdf_img}
            />
          </div>
          <div className={style.foxit_info}>
            <div className={style.foxit_info_title}>
              계약 서명 및 문서 처리 완료
            </div>
            <div className={style.foxit_info_contents2}>
              {makeContents(
                "어디서나 편리하게 문서에 서명하고 계약서를 작성하고 수료증을 받을 수 있습니다.",
                -0.2
              )}
              {makeContents(
                "입력한 서명, 저장된 서명 이미지를 사용하거나 그림을 그려 서명을 개인화 하십시오.",
                -0.6
              )}
              {makeContents(
                "완성되고 서명된 PDF 문서, Word 문서 등을 몇 분 안에 다시 받으십시오.",
                -0.5
              )}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(
                checking ? 20 : isTablet ? 10 : 27,
                "# 쉬운 문서 열람과 서명",
                0
              )}
              {makeTag(checking ? 25 : isTablet ? 10 : 27, "# 대면 서명", 0)}
              {makeTag(
                checking ? 20 : isTablet ? 10 : 25,
                "# 보다 정확하게 재확인",
                0
              )}
            </div>
            <div className={style.foxit_info_tags}>
              {makeTag(
                checking ? 20 : isTablet ? 10 : 30,
                "# 서명자 본인 인증",
                0
              )}
              {makeTag(
                checking ? 20 : isTablet ? 10 : 30,
                "# 자신의 서명을 직접 제작",
                0
              )}
              {makeTag(checking ? 20 : isTablet ? 10 : 25, "# 대량 서명", 0)}
            </div>
          </div>
        </div>
        <div className={style.foxit_esign_feature}>
          <div className={style.foxit_esign_feature_header}>특징</div>
          <div className={style.foxit_esign_feature_contents}>
            {featurecard(
              "esign_feature1",
              "전자서명된 문서 · 워크플로우 · 보고서 관리"
            )}
            {featurecard("esign_feature2", "전자서명이 완료된 문서보관")}
            {featurecard("esign_feature3", "문서 보안 강화")}
          </div>
          <div className={style.foxit_esign_feature_contents}>
            {featurecard("esign_feature4", "업계 규제 요구사항 준수")}
            {featurecard(
              "esign_feature5",
              "글로벌 법적 규정 준수 · 전자서명 적용"
            )}
            {featurecard(
              "esign_feature6",
              "팀원 간에 문서 공유와 협업 작업하기"
            )}
          </div>
        </div>
        <div className={style.foxit_esign_adv}>
          <div className={style.foxit_esign_adv_header}>경쟁사 대비 강점</div>
          <div className={style.foxit_esign_adv_contents}>
            <div className={style.foxit_esign_adv_content}>
              <div className={style.foxit_esign_adv_num}>01</div>
              <div className={style.foxit_esign_adv_img}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/esign_adv1.png"
                  }
                />
              </div>
              <div className={style.foxit_esign_adv_title}>협업</div>
              <div className={style.foxit_esign_adv_txt}>
                <p>Foxit eSign으로 빠르고 쉽게 동의를</p>
                <p>얻을 수 있는 협업 공유 템플릿 및</p>
                <p>사용자 주소록을 제공합니다.</p>
              </div>
            </div>
            <div className={style.foxit_esign_adv_content}>
              <div className={style.foxit_esign_adv_num}>02</div>
              <div className={style.foxit_esign_adv_img}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/esign_adv2.png"
                  }
                />
              </div>
              <div className={style.foxit_esign_adv_title}>법적 규정 준수</div>
              <div className={style.foxit_esign_adv_txt}>
                <p>Foxit eSign과</p>
                <p>법적 효력이 있어 </p>
                <p>걱정하지 않아도 됩니다.</p>
              </div>
            </div>{" "}
            <div className={style.foxit_esign_adv_content}>
              <div className={style.foxit_esign_adv_num}>03</div>
              <div className={style.foxit_esign_adv_img}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/esign_adv3.png"
                  }
                />
              </div>
              <div className={style.foxit_esign_adv_title}>
                맞춤형 제공 가능
              </div>
              <div className={style.foxit_esign_adv_txt}>
                <p>Foxit eSign의 API를 사용하시면</p>
                <p>전자 서명을 웹사이트 또는</p>
                <p>플렛폼에 쉽게 통합할 수 있습니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Underbar
        c1="Foxit PDF eSign"
        c1_use={true}
        c1_link=""
        c2="Foxit PDF Editor"
        c2_use={true}
        c2_link={data.LINK}
        c3="Foxit PDF Editor"
        c3_use={false}
        c3_link=""
        c4="Foxit PDF Editor"
        c4_use={true}
        c4_link=""
      />
    </>
  );
}

export default Foxitpdfesign;
