import React, { useCallback, useEffect, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import style from "css/landing.module.css";
import axios from "axios";
function LandingPop(props) {
  const [init, setInit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const popNm = process.env.REACT_APP_LANDING_POPUP;
  const domain = process.env.REACT_APP_DOMAIN;

  const closeHandler = () => {
    if (isCheck) {
      const cookies = new Cookies();
      cookies.set(popNm, true, {
        path: "/",
        maxAge: 60 * 60 * 24,
        domain: domain,
      });
    }
    setIsOpen(false);
  };
  const navigate = useNavigate();
  const clickHandler = (value) => {
    window.open(value);
  };

  const [popinfo, setpopinfo] = useState([
    {
      LINK: "",
      IMG: "",
    },
  ]);

  const [run, setrun] = useState(true);
  useEffect(() => {
    if (run) {
      axios.get("/open/popup_list", "").then((res) => {
        if (res.status === 200) {
          setpopinfo(res.data);

          if (res.data.length === 0) {
            setIsOpen(false);
          } else {
            const cookies = new Cookies();
            const popupCookie = cookies.get(popNm);
            if (popupCookie === undefined) {
              setIsOpen(true);
            }
          }
        }

        setrun(false);
      });
    }
  }, [run]);
  const [num, setnum] = useState(0);

  const [count, setcount] = useState(0);
  const timer = useCallback(() => {
    let data = count;
    setcount(data + 1);
  }, [count]);

  useEffect(() => {
    const timers = setInterval(() => {
      timer();
    }, 1000);
    return () => {
      clearInterval(timers);
    };
  }, [timer]);

  useEffect(() => {
    if (count !== 0 && count % 5 === 0) {
      if (popinfo.length > 1) {
        if (num === popinfo.length - 1) {
          setnum(0);
        } else {
          setnum(num + 1);
        }
      }
    }
  }, [count]);

  useEffect(() => {}, [init, popNm]);

  return (
    <ReactModal
      isOpen={isOpen}
      className="landing_pop_container"
      overlayClassName="landing_pop_wrapper"
      animationType="fade"
      transparent={true}
    >
      <div className={style.pop_contents + " scrollable"}>
        <div
          className={style.pop_img}
          style={{ transform: "translate(-" + num * 100 + "%" }}
        >
          {popinfo.length > 0 && popinfo !== null
            ? popinfo.map((data, index) => (
                <img
                  src={
                    data.IMG // process.env.REACT_APP_CLIENT_URL + "img/landing/popup/popup_img.png"
                  }
                  alt=""
                  onClick={() => clickHandler(data.LINK)}
                  className={style.img_on + " cursor"}
                />
              ))
            : ""}
        </div>
      </div>
      <footer className="landing_pop_footer">
        <div className="landing_pop_footer_chk">
          <input
            type="checkbox"
            checked={isCheck}
            onClick={() => setIsCheck(!isCheck)}
          />
          <p onClick={() => setIsCheck(!isCheck)}>오늘 하루 보지않기</p>
        </div>
        <div className={style.pop_num_btn}>
          {popinfo.length > 1 && popinfo !== null
            ? popinfo.map((data, index) => (
                <p
                  className={
                    index === num ? style.pop_selected : style.pop_nselected
                  }
                  onClick={() => setnum(index)}
                />
              ))
            : ""}
        </div>
        <p onClick={() => closeHandler()}>닫기</p>
      </footer>
    </ReactModal>
  );
}

export default LandingPop;
