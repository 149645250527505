import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paging from "components/common/paging";
import style from "css/community.module.css";
import Announcemnetdata from "./announcemnetdata";
import Eventdata from "./eventdata";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

function Event() {
  const navigate = useNavigate();
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const [keyword, setkeyword] = useState("");
  const [searchword, setsearchword] = useState("");

  const [boardlist, setboardlist] = useState([]);
  const [init, setInit] = useState(false);
  const [pagerun, setpagerun] = useState(true);

  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 12,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0401",
        ROW_COUNT: page.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/open/boardlist", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);

  useEffect(() => {
    getlist();
  }, [getlist]);

  const getcount = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0401",
      },
    };

    axios.get("/open/boardcount", config).then((res) => {
      if (res.status === 200) {
        setPage({
          ...page,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun(false);
        setInit(true);
      }
    });
  }, [page, searchword]);

  useEffect(() => {
    if (pagerun) {
      getcount();
    }
  }, [getcount, pagerun]);

  const searchbtn = () => {
    setsearchword(keyword);
    action();
  };

  const action = () => {
    getlist();
    setpagerun(true);
  };

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    { TITLE: "공지사항", LINK: "announcement" },
    { TITLE: "도입사례", LINK: "case" },
  ];

  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/support/community_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.community_area}>
          <div className={style.community_title_wrap}>
            <div className={style.community_title}>커뮤니티</div>
            <div className={style.community_subtitle}>community</div>
          </div>
        </div>
      </div>
      <div className={style.community_category}>
        {isTablet ? (
          <Mobilemenu selected="EVENT" list={menulist} type="" />
        ) : (
          <div className={style.community_category_buttons}>
            <div
              className={style.community_button + " cursor"}
              onClick={() => {
                menuHandler("announcement");
              }}
            >
              공지사항
            </div>
            <div
              className={
                style.community_button + " " + style.button_focus + " cursor"
              }
              onClick={() => {
                menuHandler("event");
              }}
            >
              EVENT
            </div>
            <div
              className={style.community_button + " cursor"}
              onClick={() => {
                menuHandler("case");
              }}
            >
              도입사례
            </div>
          </div>
        )}
      </div>

      <div className={style.announcement_wrapper}>
        <div className={style.announcement_body}>
          <div className={style.event_body}>
            {boardlist.map((data, index) => (
              <div
                className={style.event_card_wrapper + " cursor"}
                onClick={() =>
                  navigate(
                    process.env.REACT_APP_CLIENT_URL +
                      "community?id=" +
                      data.BOARD_ID +
                      "&type=event"
                  )
                }
              >
                <Eventdata data={data} indexdata={(index + 1) % 2} />
              </div>
            ))}
          </div>
          <div className={style.paging_line}>
            <Paging
              init={init}
              setInit={setInit}
              totalCount={page.totalCount}
              rowCount={page.rowCount}
              currentPage={page.currentPage}
              maxPage={page.maxPage}
              onClick={(res) => {
                setPage({ ...page, currentPage: res });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Event;
