import React from "react";
import style from "css/community.module.css";
import { useMediaQuery } from "react-responsive";
function Announcemnetdata(props) {
  const convertHTML = (value) => {
    return (
      <div
        className={style.innertext}
        dangerouslySetInnerHTML={{ __html: value }}
      ></div>
    );
  };
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <div className={style.announcement_data}>
      <div className={style.announcement_left}>
        <div className={style.announcement_num}>{props.index}</div>
        <div>
          <img
            className={style.announcement_img}
            src={process.env.REACT_APP_CLIENT_URL + props.data.THUMBNAIL}
            alt=""
          />
        </div>
        <div className={style.announcement_th_text}>
          <div className={style.announcement_th_title}>{props.data.TITLE}</div>
          {isMobile ? (
            <div className={style.announcement_date}>{props.data.INPUT_DT}</div>
          ) : (
            <div className={style.announcement_th_contents}>
              {convertHTML(props.data.TEXT)}
            </div>
          )}
        </div>
      </div>
      <div className={style.announcement_date}>{props.data.INPUT_DT}</div>
    </div>
  );
}

export default Announcemnetdata;
