import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "css/landing.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import EventListItem from "./eventListItem";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

/**
 * 파일명: EventArea.js
 * 작성자: 강연승
 * 작성일자: 24.02.15
 * 설명: landing page - EventArea
 */

function EventArea() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });

  const [prevBtnStatus, setPrevBtnStatus] = useState(true);
  const [nextBtnStatus, setNextBtnStatus] = useState(false);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [eventList, seteventList] = useState([]);

  const [run, setrun] = useState(true);

  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "CD0401",
        ROW_COUNT: 3,
        PAGE_COUNT: 0,
        HOLD: "1",
      },
    };

    axios.get("/open/boardlist", config).then((res) => {
      if (res.status === 200) {
        seteventList(res.data);
        setrun(false);
      }
    });
  }, []);

  useEffect(() => {
    if (run) {
      getlist();
    }
  }, [getlist, run]);

  return (
    <div className={style.wrapper + " " + style.wrapperevent}>
      <div
        className={style.background}
        style={{
          backgroundColor: "#eff5fa",
        }}
      >
        {isMobile ? (
          <></>
        ) : (
          <div className={style.event_background_container}>
            <img
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/landing/bluetns.png"}
            />
          </div>
        )}
      </div>
      <div className={style.event_area}>
        <div className={style.event_area_label}>
          <div className={style.event_area_title_wrap}>
            <div className={style.event_area_title}>EVENT</div>
            <div className={style.event_area_subtitle}>블루티엔에스 이벤트</div>
          </div>
          {isMobile ? (
            <></>
          ) : (
            <div className={style.event_nav}>
              <div
                className={
                  prevBtnStatus
                    ? `${style.event_nav_btn} disable cursor`
                    : `${style.event_nav_btn} cursor`
                }
                ref={prevRef}
              >
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    (prevBtnStatus
                      ? "img/common/next_disable.png"
                      : "img/common/arrow_right.png")
                  }
                />
              </div>
              <div
                className={
                  nextBtnStatus
                    ? `${style.event_nav_btn} disable cursor`
                    : `${style.event_nav_btn} cursor`
                }
                ref={nextRef}
              >
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    (nextBtnStatus
                      ? "img/common/next_disable.png"
                      : "img/common/arrow_right.png")
                  }
                />
              </div>
            </div>
          )}
        </div>

        <Swiper
          className={style.event_list}
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          // centeredSlides={true}
          modules={[Navigation]}
          // loop={true}
          slidesPerView={isMobile ? 1.2 : isTablet ? 2 : 2.5}
          spaceBetween={isMobile ? 14 : 30}
          onActiveIndexChange={(swiper) => {
            if (swiper.isBeginning) {
              setPrevBtnStatus(true);
            } else {
              setPrevBtnStatus(false);
            }
            if (swiper.isEnd) {
              setNextBtnStatus(true);
            } else {
              setNextBtnStatus(false);
            }
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
        >
          {eventList.map((event, index) => (
            <SwiperSlide key={index} className={`${style.event_item} cursor`}>
              <EventListItem event={event} />
            </SwiperSlide>
          ))}
        </Swiper>
        {isMobile ? (
          <div className={style.event_nav}>
            <div
              className={
                prevBtnStatus
                  ? `${style.event_nav_btn} disable cursor`
                  : `${style.event_nav_btn} cursor`
              }
              ref={prevRef}
            >
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  (prevBtnStatus
                    ? "img/common/next_disable.png"
                    : "img/common/arrow_right.png")
                }
              />
            </div>
            <div
              className={
                nextBtnStatus
                  ? `${style.event_nav_btn} disable cursor`
                  : `${style.event_nav_btn} cursor`
              }
              ref={nextRef}
            >
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  (nextBtnStatus
                    ? "img/common/next_disable.png"
                    : "img/common/arrow_right.png")
                }
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default EventArea;
