import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/board.module.css";
import axios from "axios";
import Paging from "components/common/paging";
import { useDispatch } from "react-redux";
import { openAlert, openConfirm } from "redux/slices/alertSlice";
import Adminpopuppop from "components/common/adminpopuppop";
function Adminpopup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boardlist, setboardlist] = useState([]);
  const [init, setInit] = useState(false);
  const [pagerun, setpagerun] = useState(true);
  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 4,
    currentPage: 1,
    maxPage: 5,
  });

  const [boardlist2, setboardlist2] = useState([]);
  const [init2, setInit2] = useState(false);
  const [pagerun2, setpagerun2] = useState(true);
  const [page2, setPage2] = useState({
    totalCount: 0,
    rowCount: 4,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        ROW_COUNT: page.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/board/popup_list_y", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const getcount = useCallback(() => {
    axios.get("/board/popup_count_y", "").then((res) => {
      if (res.status === 200) {
        setPage({
          ...page,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun(false);
        setInit(true);
      }
    });
  }, [page]);

  useEffect(() => {
    if (pagerun) {
      getcount();
    }
  }, [getcount, pagerun]);

  const getlist2 = useCallback(() => {
    let config = {
      params: {
        ROW_COUNT: page2.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page2.currentPage - 1) * page2.rowCount;

    axios.get("/board/popup_list_n", config).then((res) => {
      if (res.status === 200) {
        setboardlist2(res.data);
      }
    });
  }, [page2]);
  useEffect(() => {
    getlist2();
  }, [getlist2]);

  const getcount2 = useCallback(() => {
    axios.get("/board/popup_count_n", "").then((res) => {
      if (res.status === 200) {
        setPage2({
          ...page2,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun2(false);
        setInit2(true);
      }
    });
  }, [page2]);

  useEffect(() => {
    if (pagerun2) {
      getcount2();
    }
  }, [getcount2, pagerun2]);

  const actbutton = (type, id) => {
    if (type === "Y") {
      dispatch(
        openConfirm({
          title: "Notice",
          content: "활성화 하시겠습니까 ?",
          submitEventHandler: () => activepop(type, id),
        })
      );
    } else if (type === "N") {
      dispatch(
        openConfirm({
          title: "Notice",
          content: "비활성화하시겠습니까 ?",
          submitEventHandler: () => activepop(type, id),
        })
      );
    }
  };
  const activepop = (type, id) => {
    let config = {
      params: {
        ACTIVE: type,
        ID: id,
      },
    };
    axios
      .put("/board/popup_act", null, config)
      .then((res) => {
        if (res.status === 200) {
          setpagerun(true);
          getlist();
          setpagerun2(true);
          getlist2();
        }
      })
      .finally(
        dispatch(
          openAlert({
            title: "Notice",
            content: "수정되었습니다.",
            submitEventHandler: () => {},
          })
        )
      );
  };

  const delbutton = (type, id) => {
    dispatch(
      openConfirm({
        title: "Notice",
        content: "삭제하시겠습니까 ?",
        submitEventHandler: () => deleteboard(type, id),
      })
    );
  };

  const deleteboard = (type, id) => {
    let config = {
      params: {
        ID: id,
      },
    };
    axios
      .put("/board/popup_del", null, config)
      .then((res) => {
        if (res.status === 200) {
          if (type === "Y") {
            setpagerun(true);
            getlist();
          } else {
            setpagerun2(true);
            getlist2();
          }
        }
      })
      .finally(
        dispatch(
          openAlert({
            title: "Notice",
            content: "삭제되었습니다.",
            submitEventHandler: () => {},
          })
        )
      );
  };

  const [boardInfo, setboardInfo] = useState({
    LINK: "",
    START_DTTM: "",
    END_DTTM: "",
    ACTIVE: "Y",
    ORDER_NUM: 0,
    ID: "",
    TYPE: "NEW",
    IMG: "",
    ORI_FILE_NM: "",
  });

  const insertpop = () => {
    setboardInfo({
      LINK: "",
      START_DTTM: "",
      END_DTTM: "",
      ACTIVE: true,
      ORDER_NUM: 0,
      ID: "",
      TYPE: "NEW",
      IMG: [],
      ORI_FILE_NM: "",
    });

    openpop();
  };

  const updatepop = (data) => {
    setboardInfo({
      LINK: data.LINK,
      START_DTTM: data.START_W,
      END_DTTM: data.END_W,
      ACTIVE: data.ACTIVE_YN,
      ORDER_NUM: data.ORDER_NUM,
      ID: data.ID,
      TYPE: "OLD",
      IMG: data.ORI_FILE_NM,
      ORI_FILE_NM: data.ORI_FILE_NM,
    });
    openpop();
  };

  const [open, setopen] = useState(false);
  const setclose = () => {
    setopen(false);
  };
  const openpop = () => {
    setopen(true);
  };

  const refreshdata = () => {
    setpagerun(true);
    getlist();
    setpagerun2(true);
    getlist2();

    setclose();
  };

  return (
    <div className={style.board_list_wrapper}>
      <Adminpopuppop
        data={boardInfo}
        close={setclose}
        open={open}
        submit={() => refreshdata()}
      />
      <div className={style.board_list_title}>
        <div>팝업 관리 페이지</div>
        <div
          className={style.board_list_input + " cursor"}
          onClick={() => insertpop()}
        >
          등록
        </div>
      </div>
      <div style={{ height: "320px" }}>
        <div className={style.popup_sub_title}>사용중인 팝업</div>
        <div className={style.board_list_header}>
          <div className={style.r5}>순서</div>
          <div className={style.r20}>이미지 명</div>
          <div className={style.r20}>연결 링크</div>
          <div className={style.r15}>등록 일</div>
          <div className={style.r12}>시작 일시</div>
          <div className={style.r12}>종료 일시</div>
          <div className={style.r8}>활성화</div>
          <div className={style.r8}>삭제</div>
        </div>
        <div className={style.board_list_body}>
          {boardlist.length > 0
            ? boardlist.map((data) => (
                <div
                  className={style.board_list_body_line}
                  onClick={() => updatepop(data)}
                >
                  <div className={style.r5}>{data.ORDER_NUM}</div>
                  <div className={style.r20}>{data.ORI_FILE_NM}</div>
                  <div className={style.r20}>{data.LINK}</div>
                  <div className={style.r15}>{data.INPUT_DT}</div>
                  <div className={style.r12}>{data.START_DTTM}</div>
                  <div className={style.r12}>{data.END_DTTM}</div>
                  <div
                    className={style.r8}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={
                      ((e) => e.preventDefault(), (e) => e.stopPropagation())
                    }
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        actbutton("N", data.ID);
                      }}
                    >
                      <p className={style.state_green} />
                    </div>
                  </div>
                  <div
                    className={style.r8}
                    onClick={
                      ((e) => e.preventDefault(), (e) => e.stopPropagation())
                    }
                  >
                    <p
                      onClick={() => {
                        delbutton("Y", data.ID);
                      }}
                    >
                      {" "}
                      삭제
                    </p>
                  </div>
                </div>
              ))
            : ""}
        </div>
        <div>
          <Paging
            init={init}
            setInit={setInit}
            totalCount={page.totalCount}
            rowCount={page.rowCount}
            currentPage={page.currentPage}
            maxPage={page.maxPage}
            onClick={(res) => {
              setPage({ ...page, currentPage: res });
            }}
          />
        </div>
      </div>

      <div style={{ height: "320px" }}>
        <div className={style.popup_sub_title}>미사용 팝업</div>
        <div className={style.board_list_header}>
          <div className={style.r5}>순서</div>
          <div className={style.r20}>이미지 명</div>
          <div className={style.r20}>연결 링크</div>
          <div className={style.r15}>등록 일</div>
          <div className={style.r12}>시작 일시</div>
          <div className={style.r12}>종료 일시</div>
          <div className={style.r8}>활성화</div>
          <div className={style.r8}>삭제</div>
        </div>
        <div className={style.board_list_body}>
          {boardlist2.length > 0
            ? boardlist2.map((data) => (
                <div
                  className={style.board_list_body_line}
                  onClick={() => updatepop(data)}
                >
                  <div className={style.r5}>{data.ORDER_NUM}</div>
                  <div className={style.r20}>{data.ORI_FILE_NM}</div>
                  <div className={style.r20}>{data.LINK}</div>
                  <div className={style.r15}>{data.INPUT_DT}</div>
                  <div className={style.r12}>{data.START_DTTM}</div>
                  <div className={style.r12}>{data.END_DTTM}</div>
                  <div
                    className={style.r8}
                    style={{}}
                    onClick={
                      ((e) => e.preventDefault(), (e) => e.stopPropagation())
                    }
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        actbutton(data.ACTIVE_YN === "Y" ? "N" : "Y", data.ID);
                      }}
                    >
                      <p
                        className={
                          data.ACTIVE_YN === "Y"
                            ? style.state_green
                            : style.state_red
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={style.r8}
                    onClick={
                      ((e) => e.preventDefault(), (e) => e.stopPropagation())
                    }
                  >
                    <p
                      onClick={() => {
                        delbutton("N", data.ID);
                      }}
                    >
                      {" "}
                      삭제
                    </p>
                  </div>
                </div>
              ))
            : ""}
        </div>
        <div>
          <Paging
            init={init2}
            setInit={setInit2}
            totalCount={page2.totalCount}
            rowCount={page2.rowCount}
            currentPage={page2.currentPage}
            maxPage={page2.maxPage}
            onClick={(res) => {
              setPage2({ ...page2, currentPage: res });
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default Adminpopup;
