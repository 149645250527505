import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import ReactModal from "react-modal";
import style from "css/support.module.css";
import { useMediaQuery } from "react-responsive";
function Postcode(props) {
  const isTablet = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <>
      <ReactModal
        isOpen={props.open} //모달 Open(Boolean)
        className={style.postmodal} //모달 ClassName
        overlayClassName={style.postmodal_overlay} ///모달오버레이 ClassName
        onRequestClose={() => {
          props.close();
        }}
        shouldCloseOnEsc={true}
        animationType="fade"
        transparent={true}
        closeTimeoutMS={200}
        ariaHideApp={false}
      >
        <DaumPostcodeEmbed
          onComplete={props.locationchange}
          className={style.post_daum}
        />
      </ReactModal>
    </>
  );
}

export default Postcode;
