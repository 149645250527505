import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "css/community.module.css";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

function Communitydetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [run, setrun] = useState(true);
  const [fileopen, setfileopen] = useState(false);
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const [detail, setdetail] = useState({
    NEXT: "",
    PREV: "",
  });

  let getParameter = (key) => {
    return new URLSearchParams(location.search).get(key);
  };

  const id = getParameter("id");

  let type = "";
  if (getParameter("type") === "event") {
    type = "CD0401";
  } else {
    type = "CD0402";
  }

  const [files, setfiles] = useState({});
  const detailinfo = useCallback(() => {
    let config = {
      params: {
        BOARD_ID: id,
        TYPE: type,
      },
    };
    axios.get("/open/detail", config).then((res) => {
      if (res.status === 200) {
        setdetail(res.data);

        // 파일 처리
        let fileInfo = res.data.FILE_INFO;
        let rawfileInfo = [];
        let fileInfoList = [];
        if (fileInfo !== "") {
          const fileList = fileInfo.split("|");
          for (let i = 0; i < fileList.length; i++) {
            rawfileInfo = fileList[i].split(";");
            const fileInfo = {
              fileNm: rawfileInfo[0],
              fileoriNm: rawfileInfo[1],
              fileURL: rawfileInfo[2],
            };
            fileInfoList.push(fileInfo);
          }
        }
        setfiles(fileInfoList);

        setrun(false);
      }
    });
  }, [location, id, type]);
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  useEffect(() => {
    if (id !== "" && id !== null && run) detailinfo();
  }, [detailinfo, id, run]);

  const convertHTML = () => {
    return <div dangerouslySetInnerHTML={{ __html: detail.TEXT }}></div>;
  };

  const gopage = (info) => {
    if (info !== "") {
      navigate(
        process.env.REACT_APP_CLIENT_URL +
          "community?id=" +
          info +
          "&type=" +
          (type === "CD0401" ? "event" : "notice")
      );
      setrun(true);
    }
  };

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist1 = [
    { TITLE: "EVENT", LINK: "event" },
    { TITLE: "도입사례", LINK: "case" },
  ];
  const menulist2 = [
    { TITLE: "공지사항", LINK: "announcement" },
    { TITLE: "도입사례", LINK: "case" },
  ];

  const category_btn = () => {
    if (type === "CD0401") {
      return isTablet ? (
        <Mobilemenu selected="EVENT" list={menulist2} type="" />
      ) : (
        <div className={style.community_category_buttons}>
          <div
            className={style.community_button + " cursor"}
            onClick={() => {
              menuHandler("announcement");
            }}
          >
            공지사항
          </div>

          <div
            className={
              style.community_button + " " + style.button_focus + " cursor"
            }
            onClick={() => {
              menuHandler("event");
            }}
          >
            EVENT
          </div>
          <div
            className={style.community_button + " cursor"}
            onClick={() => {
              menuHandler("case");
            }}
          >
            도입사례
          </div>
        </div>
      );
    } else if (type === "CD0402") {
      return isTablet ? (
        <Mobilemenu selected="공지사항" list={menulist1} type="" />
      ) : (
        <div className={style.community_category_buttons}>
          <div
            className={
              style.community_button + " " + style.button_focus + " cursor"
            }
            onClick={() => {
              menuHandler("announcement");
            }}
          >
            공지사항
          </div>

          <div
            className={style.community_button + " cursor"}
            onClick={() => {
              menuHandler("event");
            }}
          >
            EVENT
          </div>
          <div
            className={style.community_button + " cursor"}
            onClick={() => {
              menuHandler("case");
            }}
          >
            도입사례
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {" "}
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={{
            backgroundImage:
              "url('" +
              process.env.REACT_APP_CLIENT_URL +
              "img/support/support_bg.png')",
          }}
        >
          <div className={style.background_blur}></div>
        </div>
        <div className={style.community_area}>
          <div className={style.community_title_wrap}>
            <div className={style.community_title}>커뮤니티</div>
            <div className={style.community_subtitle}>community</div>
          </div>
        </div>
      </div>
      <div className={style.community_category}>{category_btn()}</div>
      <div className={style.detail_wrapper}>
        <div className={style.detail_body}>
          <div className={style.detail_title}>{detail.TITLE}</div>
          <div className={style.detail_sub}>
            <div className={style.detail_iddt}>
              {detail.INPUT_ID} | {detail.INPUT_DT}
            </div>
            <div className={style.detail_iddt}>
              조회수 <font className={style.detail_count}>{detail.COUNT}</font>
            </div>
          </div>
          <div className={style.detail_files}>
            {files.length === 0 ? (
              <div className={style.file_header}> 첨부파일이 없습니다</div>
            ) : (
              <div className={style.file_header}>
                <font
                  onClick={() => setfileopen(!fileopen)}
                  className={"cursor"}
                >
                  첨부파일이 {files.length}개 있습니다.
                </font>
                {fileopen ? (
                  <div className={style.file_list}>
                    <div className={style.file_close}>
                      <div>첨부파일 목록</div>
                      <div
                        className={style.file_closeicon + " cursor"}
                        onClick={() => setfileopen(false)}
                      >
                        X
                      </div>
                    </div>
                    <div>
                      {files.map((item, index) => (
                        <div
                          className={style.file_line + " cursor"}
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_CLIENT_URL + item.fileURL
                            )
                          } //item.fileURL
                        >
                          <div className={style.file_oriNm}>
                            {item.fileoriNm}
                          </div>
                          <div>
                            <img
                              alt=""
                              src={
                                process.env.REACT_APP_CLIENT_URL +
                                "img/admin/download.png"
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div className={style.detail_text}>{convertHTML()}</div>
          <div className={style.detail_ln}>
            <div
              className={style.prev + " cursor"}
              onClick={() => gopage(detail.PREV_URL)}
            >
              <img
                alt=""
                className={style.detail_la}
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/admin/arrow_left.png"
                }
              />
              이전글
              <font className={style.gl}>|</font>
              <font className={style.move_lr}>
                {isMobile
                  ? ""
                  : detail.PREV === "NONE"
                  ? "이전글이 없습니다."
                  : detail.PREV_TITLE}
              </font>
            </div>
            <div
              className={style.next + " cursor"}
              onClick={() => gopage(detail.NEXT_URL)}
            >
              <font className={style.move_lr}>
                {isMobile
                  ? ""
                  : detail.NEXT === "NONE"
                  ? "다음글이 없습니다."
                  : detail.NEXT_TITLE}
              </font>
              <font className={style.gl}>|</font>
              다음글
              <img
                alt=""
                className={style.detail_ra}
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/admin/arrow_right.png"
                }
              />
            </div>
          </div>
          <div className={style.tolist}>
            <div
              className={style.list_button + " cursor"}
              onClick={() =>
                navigate(
                  process.env.REACT_APP_CLIENT_URL +
                    (type === "CD0401" ? "event" : "announcement")
                )
              }
            >
              목록
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Communitydetail;
