import React, { useState } from "react";
import style from "css/floatingButton.module.css";
// import { useMediaQuery } from "react-responsive";
import { ConfigProvider, FloatButton } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function FloatingButton() {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  //   const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {pathname.includes("admin") ? (
        <></>
      ) : (
        <ConfigProvider
          theme={{
            components: {
              FloatButton: {
                fontFamily: "Pretendard",
                colorText: "#ffffff",
              },
            },
          }}
        >
          {isMobile || isTablet ? (
            <>
              <FloatButton.Group
                // rootClassName={style.float_root_btn}
                // className={style.float_btn_area}
                // onOpenChange={(e) => setIsOpen(e)}
                // className="floatButtonGroup"
                style={
                  isMobile
                    ? { right: isMobile ? 5 : 8 }
                    : {
                        bottom: isTablet ? 100 : 150,
                        right: isTablet ? 8 : 10,
                        //   width: isMobile ? 53 : isTablet ? 56 : 79,
                      }
                }
              >
                <FloatButton.Group
                  rootClassName={style.float_root_btn}
                  className={style.float_btn_area}
                  // onOpenChange={(e) => setIsOpen(e)}
                  // className="floatButtonGroup"
                  trigger="click"
                  shape="square"
                  icon={
                    <div className={style.floating_open_btn}>
                      <span></span>
                    </div>
                  }
                  style={
                    isMobile
                      ? { right: isMobile ? 5 : 8 }
                      : {
                          // bottom: isTablet ? 200 : 150,
                          right: isTablet ? 8 : 10,
                          //   width: isMobile ? 53 : isTablet ? 56 : 79,
                        }
                  }
                >
                  <div className={style.ea_btn}>
                    <img
                      alt=""
                      src={
                        process.env.REACT_APP_CLIENT_URL +
                        "img/floatingButton/zwcad-foxit.png"
                      }
                    />
                    <div className={style.ea_label}>EA 단독총판</div>
                  </div>
                  <div className={style.floatinb_btn_list}>
                    <div className={style.floating_btn_item}>
                      <FloatButton
                        className={style.floating_btn}
                        onClick={() =>
                          navigate(
                            process.env.REACT_APP_CLIENT_URL + "announcement"
                          )
                        }
                        icon={
                          <img
                            alt=""
                            src={
                              process.env.REACT_APP_CLIENT_URL +
                              "img/floatingButton/notice.png"
                            }
                            style={{
                              width: "26px",
                            }}
                          />
                        }
                      />
                      {isTablet ? (
                        <div className={style.floating_btn_label}>공지사항</div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className={style.floating_btn_item}
                      onClick={() =>
                        window.open("https://blog.naver.com/blue_tns")
                      }
                    >
                      <FloatButton
                        className={style.floating_btn}
                        //   onClick={() => window.open(instagramUrl)}
                        icon={
                          <img
                            alt=""
                            src={
                              process.env.REACT_APP_CLIENT_URL +
                              "img/floatingButton/blog.png"
                            }
                            style={{
                              width: "28px",
                            }}
                          />
                        }
                      />
                      {isTablet ? (
                        <div className={style.floating_btn_label}>블로그</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={style.floating_btn_item}>
                      <FloatButton
                        className={style.floating_btn}
                        onClick={() =>
                          window.open(
                            "https://indd.adobe.com/view/8eebd1a7-dbac-4cd8-800e-95fb5748534e"
                          )
                        }
                        icon={
                          <img
                            alt=""
                            src={
                              process.env.REACT_APP_CLIENT_URL +
                              "img/floatingButton/proposal.png"
                            }
                            style={{
                              width: "31px",
                            }}
                          />
                        }
                      />
                      {isTablet ? (
                        <div className={style.floating_btn_label}>제안서</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={style.floating_btn_item}>
                      <FloatButton
                        className={style.floating_btn}
                        onClick={() =>
                          navigate(
                            process.env.REACT_APP_CLIENT_URL + "online_support"
                          )
                        }
                        icon={
                          <img
                            alt=""
                            style={{
                              width: "28px",
                            }}
                            src={
                              process.env.REACT_APP_CLIENT_URL +
                              "img/floatingButton/support.png"
                            }
                          />
                        }
                      />
                      {isTablet ? (
                        <div className={style.floating_btn_label}>기술지원</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={style.floating_btn_item}>
                      <FloatButton
                        className={style.floating_btn}
                        onClick={() =>
                          navigate(process.env.REACT_APP_CLIENT_URL + "request")
                        }
                        icon={
                          <img
                            alt=""
                            style={{
                              width: "28px",
                            }}
                            src={
                              process.env.REACT_APP_CLIENT_URL +
                              "img/floatingButton/inquiry.png"
                            }
                          />
                        }
                      />
                      {isTablet ? (
                        <div className={style.floating_btn_label}>
                          견적 신청
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* <div
                className={style.top_btn}
                onClick={() => window.scrollTo(0, 0)}
              >
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/floatingButton/top.png"
                  }
                />
                <div className={style.top_btn_label}>TOP</div>
              </div> */}
                </FloatButton.Group>
                <div
                  className={style.top_btn}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  <img
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/floatingButton/top.png"
                    }
                  />
                  <div className={style.top_btn_label}>TOP</div>
                </div>
              </FloatButton.Group>
            </>
          ) : (
            <FloatButton.Group
              rootClassName={style.float_root_btn}
              className={style.float_btn_area}
              // className="floatButtonGroup"
              shape="square"
              icon={null}
              style={
                isMobile
                  ? { right: isMobile ? 5 : 8 }
                  : {
                      bottom: isTablet ? 200 : 150,
                      right: isTablet ? 8 : 10,
                      //   width: isMobile ? 53 : isTablet ? 56 : 79,
                    }
              }
            >
              <div className={style.ea_btn}>
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/floatingButton/zwcad-foxit.png"
                  }
                />
                <div className={style.ea_label}>EA 단독총판</div>
              </div>
              <div className={style.floatinb_btn_list}>
                <div className={style.floating_btn_item}>
                  <FloatButton
                    className={style.floating_btn}
                    onClick={() =>
                      navigate(
                        process.env.REACT_APP_CLIENT_URL + "announcement"
                      )
                    }
                    icon={
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/floatingButton/notice.png"
                        }
                        style={{
                          width: "30px",
                        }}
                      />
                    }
                  />
                  <div className={style.floating_btn_label}>공지사항</div>
                </div>

                <div
                  className={style.floating_btn_item}
                  onClick={() => window.open("https://blog.naver.com/blue_tns")}
                >
                  <FloatButton
                    className={style.floating_btn}
                    //   onClick={() => window.open(instagramUrl)}
                    icon={
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/floatingButton/blog.png"
                        }
                        style={{
                          width: "33px",
                        }}
                      />
                    }
                  />
                  <div className={style.floating_btn_label}>블로그</div>
                </div>
                <div className={style.floating_btn_item}>
                  <FloatButton
                    className={style.floating_btn}
                    onClick={() =>
                      window.open(
                        "https://indd.adobe.com/view/8eebd1a7-dbac-4cd8-800e-95fb5748534e"
                      )
                    }
                    icon={
                      <img
                        alt=""
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/floatingButton/proposal.png"
                        }
                        style={{
                          width: "33px",
                        }}
                      />
                    }
                  />
                  <div className={style.floating_btn_label}>제안서</div>
                </div>
                <div className={style.floating_btn_item}>
                  <FloatButton
                    className={style.floating_btn}
                    onClick={() =>
                      navigate(
                        process.env.REACT_APP_CLIENT_URL + "online_support"
                      )
                    }
                    icon={
                      <img
                        alt=""
                        style={{
                          width: "33px",
                        }}
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/floatingButton/support.png"
                        }
                      />
                    }
                  />
                  <div className={style.floating_btn_label}>기술지원</div>
                </div>
                <div className={style.floating_btn_item}>
                  <FloatButton
                    className={style.floating_btn}
                    onClick={() =>
                      navigate(process.env.REACT_APP_CLIENT_URL + "request")
                    }
                    icon={
                      <img
                        alt=""
                        style={{
                          width: "33px",
                        }}
                        src={
                          process.env.REACT_APP_CLIENT_URL +
                          "img/floatingButton/inquiry.png"
                        }
                      />
                    }
                  />
                  <div className={style.floating_btn_label}>견적 신청</div>
                </div>
              </div>
              <div
                className={style.top_btn}
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                <img
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/floatingButton/top.png"
                  }
                />
                <div className={style.top_btn_label}>TOP</div>
              </div>
            </FloatButton.Group>
          )}
        </ConfigProvider>
      )}
    </>
  );
}

export default FloatingButton;
