import React from "react";
import style from "css/landing.module.css";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
/**
 * 파일명: EventListItem.js
 * 작성자: 강연승
 * 작성일자: 24.02.15
 * 설명: landing page - EventArea - EventListItem
 */

function EventListItem(props) {
  const navigate = useNavigate();
  return (
    <>
      {props.event.THUMBNAIL === "" ? (
        <Skeleton.Image active style={{ width: "100%", height: "260px" }} />
      ) : (
        <div
          className={style.event_img_wrapper}
          onClick={() =>
            navigate(
              process.env.REACT_APP_CLIENT_URL +
                "community?id=" +
                props.event.BOARD_ID +
                "&type=event"
            )
          }
        >
          <img
            className={style.event_img}
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + props.event.THUMBNAIL}
          />
        </div>
      )}
      <div
        className={style.event_info_wrap}
        onClick={() =>
          navigate(
            process.env.REACT_APP_CLIENT_URL +
              "community?id=" +
              props.event.BOARD_ID +
              "&type=event"
          )
        }
      >
        <div className={style.event_title}>{props.event.TITLE}</div>
        <div className={style.event_input_date}>{props.event.INPUT_DT}</div>
      </div>
    </>
  );
}

export default EventListItem;
