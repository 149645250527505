import React from "react";
import SwiperArea from "components/landing/swiperArea";
import ProductArea from "components/landing/productArea";
import EventArea from "components/landing/eventArea";
import NoticeArea from "components/landing/noticeArea";
import CustomerArea from "components/landing/customerArea";
import LandingPop from "components/common/landingPop";

/**
 * 파일명: landing.js
 * 작성자: 강연승
 * 작성일자: 24.02.14
 * 설명: landing page
 */
function Landing() {
  return (
    <>
      <LandingPop />
      <SwiperArea />

      <ProductArea />

      <EventArea />

      <NoticeArea />

      <CustomerArea />
    </>
  );
}

export default Landing;
