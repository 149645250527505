import React, { useState } from "react";
import style from "css/board.module.css";
import State from "pages/common/state";
function Adminrequestdata(props) {
  const [open, setopen] = useState(false);
  const clickline = () => {
    setopen(!open);
  };

  return (
    <div>
      <div
        className={style.free_list_body_line + " " + style.onsite_list_line}
        onClick={() => {
          clickline();
        }}
      >
        <div className={style.r10}>{props.data.COMPANY_MANAGER}</div>
        <div className={style.r10}>{props.data.COMPANY_CN}</div>
        <div className={style.r10}>{props.data.COMPANY_NAME}</div>
        <div className={style.r10}>{props.data.EMAIL}</div>
        <div className={style.r10}>{props.data.DATE}</div>
        <div className={style.r10}>{props.data.TIME}</div>
        <div className={style.r20}>{props.data.USECASE}</div>
        <div className={style.r10}>{props.data.INPUT_DT}</div>
        <div
          className={style.r10}
          onClick={((e) => e.preventDefault(), (e) => e.stopPropagation())}
        >
          <div>
            <State
              state={props.data.STATUS}
              TYPE="ONSITE"
              ID={props.data.ID}
              action={() => props.getlist()}
            />
          </div>
        </div>
      </div>
      <div className={open ? style.data_open : style.data_close}>
        <div className={style.request_body1}>
          <div className={style.request_left}>
            <div className={style.request_pt1}>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>파트너명</div>
                <div className={style.detail_box_data}>
                  {props.data.PARTNER_NAME}
                </div>
              </div>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>담당자</div>
                <div className={style.detail_box_data}>
                  {props.data.PARTNER_MANAGER}
                </div>
              </div>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>전화번호</div>
                <div className={style.detail_box_data}>
                  {props.data.PARTNER_CN}
                </div>
              </div>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>핸드폰</div>
                <div className={style.detail_box_data}>
                  {props.data.PARTNER_PN}
                </div>
              </div>
            </div>
            <div className={style.request_pt1}>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>회사명</div>
                <div className={style.detail_box_data}>
                  {props.data.COMPANY_NAME}
                </div>
              </div>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>담당자</div>
                <div className={style.detail_box_data}>
                  {props.data.COMPANY_MANAGER}
                </div>
              </div>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>연락처</div>
                <div className={style.detail_box_data}>
                  {props.data.COMPANY_CN}
                </div>
              </div>
              <div className={style.detail_box}>
                <div className={style.detail_box_header}>핸드폰</div>
                <div className={style.detail_box_data}>
                  {props.data.COMPANY_PN}
                </div>
              </div>
            </div>
          </div>
          <div className={style.request_right}>
            <div className={style.request_pt1}>
              <div className={style.detail_box2}>
                <div className={style.detail_box_header}>이메일</div>
                <div className={style.detail_box_data2}>{props.data.EMAIL}</div>
              </div>
              <div className={style.detail_box2}>
                <div className={style.detail_box_header}>주소</div>
                <div className={style.detail_box_data2}>
                  {props.data.LOCATION}
                </div>
              </div>
              <div className={style.detail_box2}>
                <div className={style.detail_box_header}>상세주소</div>
                <div className={style.detail_box_data2}>
                  {props.data.LOCATION_DETAIL}
                </div>
              </div>
              <div className={style.detail_box2}>
                <div className={style.detail_box_header}>사용현황</div>
                <div className={style.detail_box_data2}>
                  {props.data.USECASE}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.detail_box3}>
          <div className={style.detail_box3_header}>요청내용</div>
          <div className={style.detail_box3_contents}>
            {props.data.CONTENTS}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminrequestdata;
