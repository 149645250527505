import React, { useEffect, useRef, useState } from "react";
import style from "css/zwcad.module.css";
import { useNavigate } from "react-router-dom";
import { useScroll } from "framer-motion";
import Mobilemenu from "components/common/mobilemenu";
import { useMediaQuery } from "react-responsive";
import { act } from "react-dom/test-utils";

/**
 * Version : 1.0
 * 작성일자 : 2024-03-06
 * 작성자 : 강연승
 * 설명 : CAD 제품 비교 페이지
 */

function Zw3dCompare() {
  //navigate
  const navigate = useNavigate();
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const [index1, setindex1] = useState(true);
  const [index2, setindex2] = useState(false);
  const [index3, setindex3] = useState(false);
  const [index4, setindex4] = useState(false);
  // true, false, "empty"
  const compareList = [
    {
      index: "1",
      header: "CAD Function",
      open: index1,
      contents: [
        {
          text: "따라하기 학습 튜토리얼 시스템",
          text2: "",
          value: [true, true, true, true, true, true, true],
          youtube: [
            "https://www.youtube.com/playlist?list=PLIFLHLfQV9CClLCHh9FaoeTolwzX82TtO",
            "https://www.youtube.com/playlist?list=PLIFLHLfQV9CAjLUuDHjp32THNKqOUDukX",
          ],
        },
        {
          text: "IGES, Parasolid, STEP, DWG / DXF, VDA, STL 호환",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
        {
          text: "열기, 저장 및 직접 편집",
          text2:
            "(CATIA, Inventor, NX, Creo, SolidWorks, SolidEdge, Creo/Preo 및 ACIS 파일)",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
        {
          text: "3D 프린터 인터페이스",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
        {
          text: "2D, 3D 스케치 및 와이어프레임",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
        {
          text: "솔리드 - 서피스 (곡면) 하이브리드 모델링",
          text2: "",
          value: ["empty", true, true, false, "empty", "empty", true],
          youtube: [],
        },
        {
          text: "서피스 모델링",
          text2: "",
          value: ["empty", true, true, false, "empty", "empty", true],
          youtube: [
            "https://www.youtube.com/watch?v=S8d1WOUaT3s&list=PLIFLHLfQV9CAnziwpygNbA7MVO0pNwq5k",
          ],
        },
        {
          text: "어셈블리 설계",
          text2: "",
          value: ["empty", true, true, false, "empty", "empty", true],
          youtube: [
            "https://www.youtube.com/watch?v=AaAnt8ZdPMs&list=PLIFLHLfQV9CDUWAuRURfrIUxdXCF6YPtz",
          ],
        },
        {
          text: "데이터 분석 및 기하형상 복구 기능",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
        {
          text: "직접 편집",
          text2: "",
          value: ["empty", true, true, false, "empty", "empty", true],
          youtube: ["https://www.youtube.com/watch?v=hZVPk4Plw6E"],
        },
        {
          text: "2D 도면",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [
            "https://www.youtube.com/watch?v=_30YxVufDsc&list=PL2V9mJq4CX5QFutJYW1u-ZQTUwQnMBWOT",
          ],
        },
        {
          text: "PMI",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
        {
          text: "표준 부품 라이브러리",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
        {
          text: "TraceParts 온라인 부품 라이브러리",
          text2: "",
          value: [true, true, true, false, true, true, true],
          youtube: [],
        },
      ],
    },
    {
      index: "2",
      header: "Application",
      open: index2,
      contents: [
        {
          text: "판금 설계",
          text2: "",
          value: ["", true, true, false, "", "", true],
          youtube: [],
        },
        {
          text: "용접 구조물",
          text2: "",
          value: ["", true, true, false, "", "", true],
          youtube: [],
        },
        {
          text: "역설계",
          text2: "",
          value: ["", true, true, false, "", "", true],
          youtube: ["https://www.youtube.com/watch?v=WdhhtP2ci7U"],
        },
        {
          text: "구조 설계",
          text2: "",
          value: ["", false, false, false, "", "", false],
          youtube: [],
        },
        {
          text: "선형 정적 해석(부품)",
          text2: "",
          value: ["", false, false, false, "", "", false],
          youtube: [],
        },
        {
          text: "선형 정적 해석(조립)",
          text2: "",
          value: ["", "", "", false, "", "", false],
          youtube: [],
        },
        {
          text: "하네스 설계",
          text2: "",
          value: ["", "", "", false, "", "", ""],
          youtube: [],
        },
        {
          text: "ECAD/MCAD 협업",
          text2: "",
          value: ["", "", "", false, "", "", ""],
          youtube: [],
        },
        {
          text: "배관 설계",
          text2: "",
          value: ["", "", "", false, "", "", ""],
          youtube: [],
        },
        {
          text: "전극 설계",
          text2: "",
          value: ["", "", true, "", "", "", true],
          youtube: [],
        },
        {
          text: "금형 설계",
          text2: "",
          value: ["", "", true, "", "", "", true],
          youtube: [
            "https://www.youtube.com/watch?v=AGC-NKMISyg&list=PLIFLHLfQV9CB68vnZ8xUJjOfw-QuHHrP6",
          ],
        },
      ],
    },
    {
      index: "3",
      header: "CAM",
      open: index3,
      contents: [
        {
          text: "드릴",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [],
        },
        {
          text: "자동 형상 인식",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [],
        },
        {
          text: "2축 선반",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [
            "https://www.youtube.com/watch?v=VqdxjrqyjkE&list=PLIFLHLfQV9CBJNyy9w_C26Q0lPGMlWhH4&index=2",
          ],
        },
        {
          text: "2축 밀링",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [
            "https://www.youtube.com/watch?v=KemYig7bLLg&list=PLIFLHLfQV9CBJNyy9w_C26Q0lPGMlWhH4&index=1",
          ],
        },
        {
          text: "툴패스 편집",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [],
        },
        {
          text: "솔리드 검증",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [],
        },
        {
          text: "ZW3D 포스트 프로세서",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [],
        },
        {
          text: "NC 프로그램(G 코드) 출력",
          text2: "",
          value: ["", "", "", "", true, true, true],
          youtube: [],
        },
        {
          text: "3축 QuickMill™",
          text2: "",
          value: ["", "", "", "", "", true, true],
          youtube: [
            "https://www.youtube.com/watch?v=nW271BXouOU&list=PLIFLHLfQV9CA7OdlEs2vVYARzYsY4fT2i",
          ],
        },
        {
          text: "SmoothFlow™ 적응형 이송 속도 제어",
          text2: "",
          value: ["", "", "", "", "", true, true],
          youtube: [],
        },
      ],
    },
    {
      index: "4",
      header: "Add-on Module(Additional purchase required)",

      open: index4,
      contents: [
        {
          text: "JT 포맷 입력/출력",
          text2: "",
          value: ["", false, false, false, false, false, false],
          youtube: [],
        },
        {
          text: "CATIA, Solidworks 2D 도면 읽기",
          text2: "",
          value: ["", false, false, false, false, false, false],
          youtube: [],
        },
        {
          text: "FTI (Add-on 모듈)",
          text2: "",
          value: ["", false, false, false, "", "", false],
          youtube: [],
        },
        {
          text: "Nesting",
          text2: "",
          value: ["", false, false, false, false, false, false],
          youtube: [],
        },
        {
          text: "PARTsolutions 표준 부품 라이브러리",
          value: ["", false, false, false, false, false, false],
          youtube: [],
        },
        {
          text: "키샷 렌더링",
          text2: "",
          value: ["", false, false, false, false, false, false],
          youtube: ["https://www.youtube.com/watch?v=X14nuhk91LU"],
        },
        {
          text: "구조 시뮬레이션",
          text2: "",
          value: ["", false, false, false, false, false, false],
          youtube: [],
        },
        {
          text: "Volumill 고속 황삭",
          text2: "",
          value: ["", "", "", "", false, false, false],
          youtube: [
            "https://www.youtube.com/watch?v=t0uly2bE3bg&list=PLIFLHLfQV9CBUIsP4eZlTgDSs1A4V530e",
          ],
        },
        {
          text: "4축 및 5축 밀링",
          text2: "",
          value: ["", "", "", "", "", false, false],
          youtube: [
            "https://www.youtube.com/watch?v=t0uly2bE3bg&list=PLIFLHLfQV9CBUIsP4eZlTgDSs1A4V530e",
          ],
        },
        {
          text: "전체 기계 시뮬레이션",
          text2: "",
          value: ["", "", "", "", false, false, false],
          youtube: [],
        },
      ],
    },
  ];

  const make_compare = (contents) => {
    return (
      <div className={style.contents_box}>
        <div
          className={style.tite_line + " cursor"}
          onClick={
            contents.index === "1"
              ? () => setindex1(!index1)
              : contents.index === "2"
              ? () => setindex2(!index2)
              : contents.index === "3"
              ? () => setindex3(!index3)
              : contents.index === "4"
              ? () => setindex4(!index4)
              : () => setindex1(!index1)
          }
        >
          <div className={style.title_text}>
            <div className={style.title_index}>{contents.index}</div>
            <div className={style.title_name}>{contents.header}</div>
          </div>
          <div>
            {contents.open ? (
              <img
                alt=""
                className={style.title_arrow}
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/common/arrow_up.png"
                }
              />
            ) : (
              <img
                alt=""
                className={style.title_arrow}
                src={
                  process.env.REACT_APP_CLIENT_URL + "img/common/arrow_down.png"
                }
              />
            )}
          </div>
        </div>
        <div className={contents.open ? style.listopen : style.listhidden}>
          {contents.contents.map((contents, index) => (
            <div className={style.zdream_detail} key={index}>
              <div
                className={
                  contents.text2 !== ""
                    ? style.contents_text4
                    : style.contents_text3
                }
              >
                <div>
                  {contents.text}{" "}
                  <p className={style.text2}>{contents.text2}</p>
                </div>
                <div className={style.contents_youtube}>
                  {contents.youtube.length > 0
                    ? contents.youtube.map((value, index) => (
                        <img
                          alt=""
                          style={{ width: "29px", height: "19px" }}
                          onClick={() => window.open(value)}
                          src={
                            process.env.REACT_APP_CLIENT_URL +
                            "img/zwcad/youtube_icon.png"
                          }
                          className="cursor"
                        />
                      ))
                    : ""}
                </div>
              </div>
              <div
                className={style.zw3d_datalist + " scrollable"}
                ref={(ref) => (containerRefs.current[index] = ref)}
              >
                {contents.value.map((value, index) => (
                  <div className={index === 2 ? style.bigbox : style.smallbox}>
                    {index < 4 ? (
                      index === 2 ? (
                        <div
                          className={
                            contents.text2 !== ""
                              ? style.contents_blue3_52
                              : style.contents_blue3
                          }
                        >
                          {value === "empty" ? (
                            <div className={style.zw3d_empty} />
                          ) : value === true ? (
                            <div className={style.zw3d_true} />
                          ) : value === false ? (
                            <img
                              className={style.zw3d_false}
                              alt=""
                              src="/img/zwcad/zw3d_check.png"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div
                          className={
                            contents.text2 !== ""
                              ? style.contents_blue_52
                              : style.contents_blue2
                          }
                        >
                          {value === "empty" ? (
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "12px",
                                backgroundColor: "none",
                                border: "2px solid #fff",
                              }}
                            />
                          ) : value === true ? (
                            <div
                              style={{
                                width: "14px",
                                height: "14px",
                                borderRadius: "12px",
                                backgroundColor: "#fff",
                              }}
                            />
                          ) : value === false ? (
                            <img alt="" src="/img/zwcad/zw3d_check.png" />
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    ) : (
                      <div
                        className={
                          contents.text2 !== ""
                            ? style.contents_sky_52
                            : style.contents_sky3
                        }
                      >
                        {value === "empty" ? (
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "12px",
                              backgroundColor: "none",
                              border: "2px solid #fff",
                            }}
                          />
                        ) : value === true ? (
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              borderRadius: "12px",
                              backgroundColor: "#fff",
                            }}
                          />
                        ) : value === false ? (
                          <img alt="" src="/img/zwcad/zw3d_check.png" />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const containerRef1 = useRef(null);

  const [action, setaction] = useState(false);
  const containerRefs = useRef([]);

  useEffect(() => {
    const handleScroll = (container, otherContainers) => {
      const scrollLeft = container.scrollLeft;
      otherContainers.forEach((otherContainer) => {
        otherContainer.scrollLeft = scrollLeft;
      });
    };

    const containers = document.querySelectorAll(".scrollable");
    if (containers.length > 0) {
      containers.forEach((container) => {
        const otherContainers = Array.from(containers).filter(
          (item) => item !== container
        );
        container.addEventListener("scroll", () =>
          handleScroll(container, otherContainers)
        );
      });
    }

    return () => {
      if (containers.length > 0) {
        containers.forEach((container) => {
          const otherContainers = Array.from(containers).filter(
            (item) => item !== container
          );
          container.removeEventListener("scroll", () =>
            handleScroll(container, otherContainers)
          );
        });
      }
    };
  }, [containerRefs, action]);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const menulist = [
    { TITLE: "ZWCAD 2025", LINK: "zwcad" },
    { TITLE: "ZW3D 2025", LINK: "zw3d" },
    { TITLE: "ZDREAM", LINK: "zdream" },
    { TITLE: "CAD 제품 비교", LINK: "cad_compare" },
  ];
  return (
    <>
      <div className={style.wrapper}>
        <div
          className={style.background}
          style={
            isMobile
              ? {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/compare_m_bg.png')",
                }
              : {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/compare_bg.png')",
                }
          }
        >
          {isMobile ? (
            <img alt="" src="/img/zwcad/compare_m_bg_img.png" />
          ) : (
            <></>
          )}
        </div>
        <div className={style.zwcad_area}>
          <div className={style.zwcad_title_wrap}>
            <div className={style.zwcad_title}>ZW3D 제품 비교</div>
            <div className={style.zwcad_subtitle}>
              ZW3D 2025 버전별 기능 비교표
            </div>
            <div className={style.zwcad_title_btn_wrap}></div>
          </div>
        </div>
      </div>

      {isTablet ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="ZW3D 제품 비교" list={menulist} type="zwcad" />
        </div>
      ) : (
        <div className={style.zwcad_category}>
          <div className={style.zwcad_category_btn_wrap}>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zwcad")}
            >
              ZWCAD 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d")}
            >
              ZW3D 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zdream")}
            >
              ZDREAM
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("cad_compare")}
            >
              CAD 제품 비교
            </div>
            <div
              className={
                style.zwcad_category_btn + " " + style.button_focus + " cursor"
              }
              onClick={() => menuHandler("zw3d_compare")}
            >
              ZW3D 제품 비교
            </div>
          </div>
        </div>
      )}

      <div className={style.compare_wrapper}>
        <div className={style.compare_header}>
          <font className={style.ch_1}>ZW3D 2025 버전별 기능 </font>
          비교표
        </div>
        <div className={style.compare_list_header}>
          <div className={style.zw3d_compare_line}>
            <div className={style.compare_content8}>버전</div>
            <div
              className={style.zw3d_datalist_header + " scrollable"}
              ref={(ref) => (containerRefs.current[999] = ref)}
              onScroll={() => setaction(!action)}
            >
              <div className={style.compare_content6}>
                <div className={style.compare_blue3}>Lite</div>
                <div className={style.compare_blue3}>Standard</div>
                <div className={style.compare_blue4}>Professional</div>
                <div className={style.compare_blue3}>Advanced</div>
              </div>
              <div className={style.compare_content7}>
                <div className={style.compare_sky3}>2-Axis Machining</div>
                <div className={style.compare_sky3}>3-Axis Machining</div>
                <div className={style.compare_sky3}>Premium</div>
              </div>
            </div>
          </div>
          <div className={style.zw3d_compare_list}>
            {compareList.map((data, index) => make_compare(data))}
          </div>
        </div>
        <div className={style.compare_quote2}>
          <div>
            ※ 제품 및 버전
            <div className={style.quote2_box}>
              <div className={style.quote2_box_line}>
                <div className={style.quote2_circle} /> 모든 기능 지원
              </div>
              <div className={style.quote2_box_line}>
                <div className={style.quote2_empty} /> 지원되는 기능의 일부
              </div>
              <div className={style.quote2_box_line}>
                <img
                  className={style.quote2_check}
                  alt=""
                  src="/img/zwcad/quote_check.png"
                />
                추가 구매 필요
              </div>
            </div>
          </div>
          <div>※ Advanced는 2024부터 적용</div>
        </div>
      </div>
    </>
  );
}

export default Zw3dCompare;
