import React, { useCallback, useEffect, useState } from "react";
import style from "css/support.module.css";
import axios from "axios";
import styles from "css/community.module.css";
function Faqdata(props) {
  const [state, setstate] = useState(props.status);
  const [run, setrun] = useState(true);
  const open = () => {
    setstate(!state);
  };
  const [fileopen, setfileopen] = useState(false);
  const [files, setfiles] = useState({});
  const [detail, setdetail] = useState({});

  const detailinfo = useCallback(() => {
    let config = {
      params: {
        BOARD_ID: props.data.BOARD_ID,
        TYPE: "CD0403",
      },
    };
    axios.get("/open/detail", config).then((res) => {
      if (res.status === 200) {
        // 파일 처리
        let fileInfo = res.data.FILE_INFO;
        let rawfileInfo = [];
        let fileInfoList = [];
        if (fileInfo !== "") {
          const fileList = fileInfo.split("|");
          for (let i = 0; i < fileList.length; i++) {
            rawfileInfo = fileList[i].split(";");
            const fileInfo = {
              fileNm: rawfileInfo[0],
              fileoriNm: rawfileInfo[1],
              fileURL: rawfileInfo[2],
            };
            fileInfoList.push(fileInfo);
          }
        }
        setfiles(fileInfoList);
        setdetail(res.data);
        setrun(false);
      }
    });
  }, [props]);

  useEffect(() => {
    if (run) {
      detailinfo();
    }
  }, [run, detailinfo]);

  const convertHTML = (value) => {
    return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
  };
  return (
    <div className={style.faq_databody}>
      <div
        onClick={() => open()}
        className={style.faq_contents_title + " cursor"}
      >
        <div className={style.open_body}>
          <div className={state ? style.open_title : style.close_title}>
            <font className={style.titleQ}>Q</font> {props.data.TITLE}
          </div>
        </div>
        <div>
          <img
            alt=""
            src={
              state
                ? process.env.REACT_APP_CLIENT_URL + "img/common/arrow_up.png"
                : process.env.REACT_APP_CLIENT_URL + "img/common/arrow_down.png"
            }
          />
        </div>
      </div>
      <div className={state ? style.faq_open : style.faq_close}>
        <div className={style.answer}>A</div>
        <div className={style.answer_text}>
          {convertHTML(detail.TEXT)}
          <div>
            <div className={style.detail_files}>
              {files.length === 0 ? (
                <div className={styles.file_header}> </div>
              ) : (
                <div className={styles.file_header + " " + style.file_len}>
                  <font
                    onClick={() => setfileopen(!fileopen)}
                    className={"cursor"}
                  >
                    첨부파일이 {files.length}개 있습니다.
                  </font>
                  {fileopen ? (
                    <div className={style.file_list}>
                      <div className={styles.file_close}>
                        <div>첨부파일 목록</div>
                        <div
                          className={styles.file_closeicon + " cursor"}
                          onClick={() => setfileopen(false)}
                        >
                          X
                        </div>
                      </div>
                      <div>
                        {files.map((item, index) => (
                          <div
                            className={styles.file_line + " cursor"}
                            onClick={() =>
                              window.open(
                                process.env.REACT_APP_CLIENT_URL + item.fileURL
                              )
                            } //item.fileURL
                          >
                            <div className={styles.file_oriNm}>
                              {item.fileoriNm}
                            </div>
                            <div>
                              <img
                                alt=""
                                src={
                                  process.env.REACT_APP_CLIENT_URL +
                                  "img/admin/download.png"
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqdata;
