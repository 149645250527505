import React, { useCallback, useEffect, useState } from "react";
import style from "css/foxit.module.css";
import Underbar from "components/common/underbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

function Pdfreader() {
  const navigate = useNavigate();

  const makeCard = (img, title, contents) => {
    return (
      <div className={style.reader_cardbox}>
        <div className={style.reader_cardimg}>
          <img
            className={style.reader_img}
            alt=""
            src={
              process.env.REACT_APP_CLIENT_URL +
              "img/foxit/reader" +
              img +
              ".png"
            }
          />
        </div>
        <div className={style.reader_cardtitle}>{title}</div>
        <div className={style.reader_cardcontents}>{contents}</div>
      </div>
    );
  };

  const [data, setdata] = useState({
    LINK: "",
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TITLE: "PDFREADER",
      },
    };
    axios.get("/open/product_fileinfo", config).then((res) => {
      if (res.status === 200) {
        if (res.data.TYPE === "FILE") {
          setdata({ LINK: res.data.FILE_URL });
        } else {
          setdata({ LINK: res.data.LINK });
        }
      }
    });
  }, []);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const menulist = [
    { TITLE: "Foxit PDF Editor", LINK: "foxit_pdf_editor" },
    { TITLE: "Foxit PDF eSign", LINK: "foxit_pdf_esign" },
  ];

  return (
    <>
      <div className={style.wrapper_h}>
        <div
          className={style.background}
          style={
            isMobile
              ? {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/reader_bg_m.png')",
                }
              : {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/foxit/reader_bg.png')",
                }
          }
        >
          {" "}
          {isMobile ? <img alt="" src="/img/foxit/reader_img_m.png" /> : <></>}
        </div>

        <div className={style.foxit_area}>
          <div className={style.foxit_title_wrap}>
            <div className={style.foxit_title}>PDF Reader</div>
            <div className={style.foxit_subtitle}>PDF 리더 솔루션</div>
            <div className={style.foxit_title_buttons}>
              <div
                className={style.foxit_title_button2 + " cursor"}
                onClick={() =>
                  window.open(
                    "http://bluetns.co.kr/img/info_files/GA_PDFReader_KR_Online_Bundle_v2023.3.0.23028.zip"
                  )
                }
              >
                다운 로드
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.foxit_category}>
        {isTablet ? (
          <Mobilemenu selected="PDF Reader" list={menulist} type="" />
        ) : (
          <div className={style.foxit_category_buttons}>
            <div
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "foxit_pdf_editor")
              }
              className={style.foxit_button + " cursor"}
            >
              Foxit PDF Editor
            </div>
            <div
              className={style.foxit_button + " cursor"}
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "foxit_pdf_esign")
              }
            >
              Foxit PDF eSign
            </div>
            <div
              className={
                style.foxit_button + " " + style.button_focus + " cursor"
              }
              onClick={() =>
                navigate(process.env.REACT_APP_CLIENT_URL + "pdf_reader")
              }
            >
              PDF Reader
            </div>
          </div>
        )}
      </div>
      <div className={style.pdfreader_wrapper}>
        <div className={style.pdfreader_header}>
          <div className={style.header_txt1}>FEATURES</div>
          <div className={style.header_txt2}>
            제품 <label className={style.header_txt3}>특징</label>
          </div>
        </div>
        <div className={style.pdfreader_body}>
          <div className={style.pdfreader_contents}>
            {makeCard(
              1,
              "읽기 및 인쇄",
              "완전한 기능을 갖춘 PDF 리더를 통해 PDF의 힘을 경험해 보세요. PDF 읽기와 인쇄는 더 이상 쉽지 않습니다. 표준 PDF(Acroforms)와 XFA(XML Form Architecture) 양식 채우기를 통해 기존 양식과 워크플로우를 활용하십시오."
            )}
            {makeCard(
              2,
              "어디서나 PDF 읽기",
              "Foxit PDF Reader는 Windows, macOS, iOS, 안드로이드, 웹 및 Linux에서 사용할 수 있어 어디에 있든, 어떤 기기를 사용하든 일관된 읽기 경험을 제공합니다."
            )}
          </div>
          <div className={style.pdfreader_contents}>
            {makeCard(
              3,
              "협업 및 공유",
              "주요 클라우드 스토리지 서비스 및 인기 기업 CMS와 통합. 공유된 리뷰를 통해 협업하는 동시에 다양한 주석 도구로 문서 피드백을 제공합니다. 첨부 파일을 주석으로 추가합니다."
            )}
            {makeCard(
              4,
              "보호 및 서명",
              "자필로 문서에 서명하거나 eSignature를 활용하고 디지털 서명 상태를 확인합니다. Trust Manager/Safe Mode, ASLR & DEP, Disable JavaScript, Security Warning   Dialogs를 활용하여 취약점으로부터 안전합니다."
            )}
          </div>
          <div className={style.pdfreader_contents}>
            {makeCard(
              5,
              "AI 어시스턴트",
              "Foxit PDF Reader와 ChatGPT의 통합은 다양한 장치와 운영 체제에서 생산성을 높이고 문서 처리를 간소화하는 데 도움이 되는 다음과 같은 AI 기반 기능을 사용자에게 제공합니다."
            )}
            {makeCard(
              6,
              "사용자 지정 및 배포",
              "메뉴 및 구성 설정에는 그룹 정책을, 윈도우즈 Update Services를 통해 업데이트를 가져오고 배포하려면 SCUP 카탈로그를, 대량 배포를 사용자 지정하려면 XML 구성을, 설치 관리자 구성에는 설치 사용자 지정 도구를 사용합니다. 안전 모드, ASLR & DEP, JavaScript 사용  안 함 및 보안 경고 대화 상자를 사용합니다."
            )}
          </div>
        </div>
      </div>
      <Underbar
        c1="PDF Reader "
        c1_use={true}
        c1_link=""
        c2="Foxit PDF Editor"
        c2_use={true}
        c2_link={data.LINK}
        c3="Foxit PDF Editor"
        c3_use={false}
        c3_link=""
        c4="Foxit PDF Editor"
        c4_use={false}
        c4_link=""
        c7_use={true}
        c7_link="http://bluetns.co.kr/img/info_files/GA_PDFReader_KR_Online_Bundle_v2023.3.0.23028.zip"
      />
    </>
  );
}
export default Pdfreader;
