import React from "react";
import style from "css/zwcad.module.css";
import { useMediaQuery } from "react-responsive";

/**
 * Version : 1.0
 * 작성일자 : 2024-03-04
 * 작성자 : 강연승
 * 설명 : ZW3D 페이지
 */

function Zw3dProcedureListItem(props) {
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <div className={style.zw3d_procedure_item_wrap}>
      <div className={style.zw3d_procedure_item}>
        {isMobile || props.procedure.align === "left" ? (
          <>
            <div className={style.zw3d_procedure_img}>
              <img
                className={style.zw3d_procdure_img_c}
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/zwcad/procedure/" +
                  props.procedure.img
                }
              />
            </div>

            <div className={style.zw3d_procedure_description_wrap}>
              <div className={style.zw3d_procedure_item_index}>
                {props.procedure.index}
              </div>
              <div className={style.zw3d_procedure_item_title_wrap}>
                <div className={style.zw3d_procedure_item_sub_title}>
                  {props.procedure.sub_title}
                </div>
                <div className={style.zw3d_procedure_item_title}>
                  {props.procedure.title}
                </div>
              </div>
              <div className={style.zw3d_procedure_item_text}>
                {props.procedure.text}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.zw3d_procedure_description_wrap}>
              <div className={style.zw3d_procedure_item_index}>
                {props.procedure.index}
              </div>
              <div className={style.zw3d_procedure_item_title_wrap}>
                <div className={style.zw3d_procedure_item_sub_title}>
                  {props.procedure.sub_title}
                </div>
                <div className={style.zw3d_procedure_item_title}>
                  {props.procedure.title}
                </div>
              </div>
              <div className={style.zw3d_procedure_item_text}>
                {props.procedure.text}
              </div>
            </div>
            <div className={style.zw3d_procedure_img}>
              <img
                alt=""
                className={style.zw3d_procdure_img_c}
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/zwcad/procedure/" +
                  props.procedure.img
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Zw3dProcedureListItem;
