import React from "react";
import style from "css/landing.module.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useLocation } from "react-router-dom";
/**
 * 파일명: CustomerArea.js
 * 작성자: 강연승
 * 작성일자: 24.02.16
 * 설명: landing page - CustomerArea
 */

function CustomerArea() {
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  //navigate
  const navigate = useNavigate();

  const customerList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27,
  ];
  return (
    <div className={style.wrapper}>
      <div className={style.customer_area}>
        <div className={style.customer_title_wrap}>
          <div className={style.customer_title}>CUSTOMER</div>
          <div className={style.customer_subtitle}>
            블루티엔에스 주요 고객사
          </div>
        </div>
        <div className={style.customer_list}>
          {isMobile
            ? customerList
                .slice(0, 10)
                .map((customer, index) => (
                  <img
                    key={index}
                    alt=""
                    src={
                      process.env.REACT_APP_CLIENT_URL +
                      "img/case/case" +
                      (index + 1) +
                      ".png"
                    }
                  />
                ))
            : customerList.map((customer, index) => (
                <img
                  key={index}
                  alt=""
                  src={
                    process.env.REACT_APP_CLIENT_URL +
                    "img/case/case" +
                    (index + 1) +
                    ".png"
                  }
                />
              ))}
        </div>

        {isMobile ? (
          <div
            className={style.customer_view_more_btn}
            onClick={() => navigate(process.env.REACT_APP_CLIENT_URL + "case")}
          >
            <div>더 보기</div>
            <img
              alt=""
              src={process.env.REACT_APP_CLIENT_URL + "img/common/plus.png"}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CustomerArea;
