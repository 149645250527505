import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "redux/slices/alertSlice";

import ReactModal from "react-modal";
import style from "css/board.module.css";

function Adminuserpop(props) {
  const dispatch = useDispatch();

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className={style.aupmodal} //모달 ClassName
      overlayClassName={style.aup_overlay} ///모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <div className={style.aup}>
        <div className={style.aup_title}>
          {props.TYPE === "C" ? "사용자 등록" : "사용자 수정"}
        </div>
        <div className={style.aup_body}>
          <div className={style.aup_input_box}>
            <div className={style.aup_input_header}>아이디</div>
            <div className={style.aup_input_body}>
              <input
                className={style.aup_input}
                value={props.ID}
                onChange={(e) => props.changeID(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className={style.aup_input_box}>
            <div className={style.aup_input_header}>비밀번호</div>
            <div className={style.aup_input_body}>
              <input
                className={style.aup_input}
                type="password"
                value={props.PW}
                onChange={(e) => props.changePW(e.target.value)}
              />
            </div>
          </div>
          <div className={style.aup_input_box}>
            <div className={style.aup_input_header}>이름</div>
            <div className={style.aup_input_body}>
              <input
                className={style.aup_input}
                type="text"
                value={props.NM}
                onChange={(e) => props.changeNM(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={style.aup_footer}>
          <div
            className={style.aup_close + " cursor"}
            onClick={() => props.close()}
          >
            닫기
          </div>
          <div
            className={style.aup_submit + " cursor"}
            onClick={() => props.submit()}
          >
            확인
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Adminuserpop;
