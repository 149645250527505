import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "css/board.module.css";
import axios from "axios";
import Paging from "components/common/paging";
import { useDispatch } from "react-redux";
import { openAlert, openConfirm } from "redux/slices/alertSlice";
import State from "pages/common/state";

function Adminrequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boardlist, setboardlist] = useState([]);
  const [init, setInit] = useState(false);
  const [pagerun, setpagerun] = useState(true);

  const [page, setPage] = useState({
    totalCount: 0,
    rowCount: 10,
    currentPage: 1,
    maxPage: 5,
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TYPE: "REQUEST",
        ROW_COUNT: page.rowCount,
        PAGE_COUNT: 0,
      },
    };
    config.params.PAGE_COUNT = (page.currentPage - 1) * page.rowCount;

    axios.get("/board/supportlist", config).then((res) => {
      if (res.status === 200) {
        setboardlist(res.data);
      }
    });
  }, [page]);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const getcount = useCallback(() => {
    let config = {
      params: {
        TYPE: "REQUEST",
      },
    };

    axios.get("/board/supportcounts", config).then((res) => {
      if (res.status === 200) {
        setPage({
          ...page,
          totalCount: res.data,
          currentPage: 1,
        });
        setpagerun(false);
        setInit(true);
      }
    });
  }, [page]);

  useEffect(() => {
    if (pagerun) {
      getcount();
    }
  }, [getcount, pagerun]);

  return (
    <div className={style.request_wrapper}>
      <div className={style.request_body}>
        <div className={style.request_list_title}>
          <div>견적요청 확인 페이지</div>
        </div>
        <div>
          <div className={style.free_list_header}>
            <div className={style.r7}>구분</div>
            <div className={style.r20}>제품</div>
            <div className={style.r8}>신청자</div>
            <div className={style.r9}>연락처</div>
            <div className={style.r12}>회사명</div>
            <div className={style.r17}>이메일</div>
            <div className={style.r5}>수량</div>
            <div className={style.r10}>구매시기</div>
            <div className={style.r6}>접수일</div>
            <div className={style.r6}>접수상태</div>
          </div>
          <div className={style.board_list_body}>
            {boardlist.length > 0
              ? boardlist.map((data) => (
                  <div className={style.free_list_body_line} onClick={() => {}}>
                    <div className={style.r7}>{data.PRODUCT}</div>
                    <div className={style.r20}>
                      {data.PRODUCT === "Z+F" ? (
                        <>
                          {data.VERSION1}
                          {data.PROMOTION1 === "Y" ? " (프로모션)" : ""}
                          <p />
                          {data.VERSION2}
                          {data.PROMOTION2 === "Y" ? " (프로모션)" : ""}{" "}
                        </>
                      ) : data.PRODUCT === "Foxit PDF" ? (
                        <>
                          {data.VERSION2}
                          {data.PROMOTION2 === "Y" ? " (프로모션)" : ""}{" "}
                        </>
                      ) : data.PRODUCT === "ZWCAD" ? (
                        <>
                          {data.VERSION1}
                          {data.PROMOTION1 === "Y" ? " - (프로모션)" : ""}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className={style.r8}>{data.MANAGER}</div>
                    <div className={style.r9}>{data.PHONE}</div>
                    <div className={style.r12}>{data.COMPANY}</div>
                    <div className={style.r17}>{data.EMAIL}</div>
                    <div className={style.r5}>{data.COUNT}</div>
                    <div className={style.r10}>
                      {data.DATE === "1"
                        ? "1개월 이내"
                        : data.DATE === "1to3"
                        ? "1~3개월 이내"
                        : data.DATE === "3"
                        ? "3개월이상"
                        : data.DATE === "other"
                        ? "기타"
                        : "미설정"}
                    </div>
                    <div className={style.r6}>{data.INPUT_DT}</div>
                    <div
                      className={style.r6}
                      onClick={
                        ((e) => e.preventDefault(), (e) => e.stopPropagation())
                      }
                    >
                      <div>
                        <State
                          state={data.STATUS}
                          TYPE="REQUEST"
                          ID={data.ID}
                          action={() => getlist()}
                        />
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
          <div>
            <Paging
              init={init}
              setInit={setInit}
              totalCount={page.totalCount}
              rowCount={page.rowCount}
              currentPage={page.currentPage}
              maxPage={page.maxPage}
              onClick={(res) => {
                setPage({ ...page, currentPage: res });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminrequest;
