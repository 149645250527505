import axios from "axios";
import style from "css/board.module.css";
import React, { useState } from "react";

function State(props) {
  const [open, setopen] = useState(false);

  const onclickbtn = (data_s) => {
    const params = {
      config: {
        ID: props.ID,
        STATUS: data_s,
        TYPE: props.TYPE,
      },
    };
    const data = {
      ID: props.ID,
      STATUS: data_s,
      TYPE: props.TYPE,
    };
    axios.post("/board/state", data, params).then((res) => {
      if (res.status === 200) {
        setopen(false);
        props.action();
      }
    });
  };

  return (
    <div className={style.request_state + " cursor"}>
      <div
        className={style.state_click}
        onClick={() => setopen(!open)}
        title={
          props.state === "CD0501"
            ? "접수 대기"
            : props.state === "CD0502"
            ? "접수 진행"
            : "완료"
        }
      >
        <div
          className={
            props.state === "CD0501"
              ? style.state_red
              : props.state === "CD0502"
              ? style.state_grey
              : style.state_green
          }
        />
      </div>
      <div className={open ? style.state_list : style.state_list_off}>
        <div className={style.state_list_body}>
          {props.state === "CD0501" ? (
            <></>
          ) : (
            <div
              className={style.state_selects}
              onClick={() => onclickbtn("CD0501")}
            >
              <div className={style.state_red} />{" "}
              <div className={style.state_selects_text}>접수 대기</div>
            </div>
          )}
          {props.state === "CD0502" ? (
            <></>
          ) : (
            <div
              className={style.state_selects}
              onClick={() => onclickbtn("CD0502")}
            >
              <div className={style.state_grey} />{" "}
              <div className={style.state_selects_text}>접수 진행</div>
            </div>
          )}
          {props.state === "CD0503" ? (
            <></>
          ) : (
            <div
              className={style.state_selects}
              onClick={() => onclickbtn("CD0503")}
            >
              <div className={style.state_green} />{" "}
              <div className={style.state_selects_text}> 완료</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default State;
