import React, { useCallback, useEffect, useState } from "react";
import style from "css/zwcad.module.css";
import { useNavigate } from "react-router-dom";
import Underbar from "components/common/underbar";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";

/**
 * Version : 1.0
 * 작성일자 : 2024-03-05
 * 작성자 : 강연승
 * 설명 : ZDream 페이지
 */

function Zdream() {
  //navigate
  const navigate = useNavigate();
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [data, setdata] = useState({
    LINK: "",
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TITLE: "ZDREAM",
      },
    };
    axios.get("/open/product_fileinfo", config).then((res) => {
      if (res.status === 200) {
        if (res.data.TYPE === "FILE") {
          setdata({ LINK: res.data.FILE_URL });
        } else {
          setdata({ LINK: res.data.LINK });
        }
      }
    });
  }, []);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  const functionList = [
    {
      index: "1",
      height: "395px",
      title: "CLVIL (토목 설계 기능)",
      text: [
        "실무에서 더욱 편리하게 사용할 수 있는 토목 설계 기능",
        "종단/횡단/선형/사면 등 기능 세분화로 더욱 편리한 사용",
      ],
    },
    {
      index: "2",
      height: "395px",
      title: "다중플롯 (출력)",
      text: [
        "여러 도면을 한 번에 다양한 설정과 스타일로 출력 가능",
        "도면 출력 시 소요 시간 단축. 번거로운 작업을 일괄적으로 설정하여 출력 가능",
      ],
    },
    {
      index: "3",
      height: "395px",
      title: "엑셀연동",
      text: [
        "엑셀 표 → 캐드로 ETC",
        "캐드표 → 엑셀로 CTE",
        "문자를 → 엑셀로 TTE",
      ],
    },
    {
      index: "4",
      height: "324px",
      title: "도면층 관리",
      text: [
        "작업 중에 도면층 병합, 도면층 끄기, 모든 도면층 켜기 등의 도면 관리 가능",
        "도면 선택 / 수정 / 변경 등의 기능으로 능률적인 도면 작업 수행",
      ],
    },
    {
      index: "5",
      height: "324px",
      title: "문자 편집",
      text: ["문자 수정, 복사, 정렬, 삭제 등의 기능 설정"],
    },
    {
      index: "6",
      height: "402px",
      title: "각종 객체 수정",
      text: [
        "객체 수정 기능 및 Z값 변경 기능 RZV",
        "객체 축척 일괄 변경 기능 MSC",
      ],
    },
    {
      index: "7",
      height: "576px",
      title: "블록 수정",
      text: ["외부 참조 및 블록 이름 변경REB", "블록 변경 RBC"],
    },
    {
      index: "8",
      height: "402px",
      title: "유틸리티",
      text: [
        "캐드 계산기 CALC 및 다양한 유틸 기능",
        "ZDREAM(지드림) 명령어 변경 ZDCMD",
      ],
    },
  ];

  const function_draw = (contents) => {
    return (
      <div className={style.zdream_box} style={{ height: contents.height }}>
        <div className={style.zdream_img_box}>
          <img
            alt=""
            className={style.zdrea_box_img}
            style={{ height: contents.height, width: "704px" }}
            src={
              process.env.REACT_APP_CLIENT_URL +
              "img/zwcad/zdream/zdream" +
              contents.index +
              ".png"
            }
          />
        </div>
        <div className={style.zdream_contents}>
          <div className={style.zdream_box_title}>{contents.title}</div>
          <div className={style.zdream_line} />
          <div className={style.zdream_details}>
            {contents.text.map((text, index) => (
              <div className={style.zdream_detail} key={index}>
                <div className={style.zdream_box_dot}></div> {text}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const menulist = [
    { TITLE: "ZWCAD 2025", LINK: "zwcad" },
    { TITLE: "ZW3D 2025", LINK: "zw3d" },
    { TITLE: "CAD 제품 비교", LINK: "cad_compare" },
    { TITLE: "ZW3D 제품 비교", LINK: "zw3d_compare" },
  ];

  return (
    <>
      <div className={style.wrapper}>
        <div
          className={style.background}
          style={
            isMobile
              ? {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/zdream_m_bg.png')",
                }
              : {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/zdream_bg.png')",
                }
          }
        >
          {isMobile ? (
            <img alt="" src="/img/zwcad/zdream_m_bg_img.png" />
          ) : (
            <></>
          )}
        </div>
        <div className={style.zwcad_area}>
          <div className={style.zwcad_title_wrap}>
            <div className={style.zwcad_title}>ZDREAM</div>
            <div className={style.zwcad_subtitle}>
              ZWCAD의 3rd-Party (응용프로그램)
            </div>
            <div className={style.zwcad_title_btn_wrap}>
              <div
                className={style.zwcad_title_btn + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "free")
                }
              >
                무료체험 신청
              </div>
              <div
                className={style.zwcad_title_btn + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "request")
                }
              >
                견적 신청
              </div>
            </div>
          </div>
        </div>
      </div>

      {isTablet ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="ZDREAM" list={menulist} type="zwcad" />
        </div>
      ) : isMobile ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="ZDREAM" list={menulist} type="zwcad" />
        </div>
      ) : (
        <div className={style.zwcad_category}>
          <div className={style.zwcad_category_btn_wrap}>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zwcad")}
            >
              ZWCAD 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d")}
            >
              ZW3D 2025
            </div>
            <div
              className={
                style.zwcad_category_btn + " " + style.button_focus + " cursor"
              }
              onClick={() => menuHandler("zdream")}
            >
              ZDREAM
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("cad_compare")}
            >
              CAD 제품 비교
            </div>

            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d_compare")}
            >
              ZW3D 제품 비교
            </div>
          </div>
        </div>
      )}

      <div className={style.zw3d_info_wrap}>
        <div className={style.zw3d_info_title}>제품 개요</div>
        <div className={style.zw3d_info_description}>
          {isMobile ? (
            <>
              <span className={style.highlight}>
                토목, 건축 분야 뿐만 아니라 다양한 산업분야
              </span>
              에서 사용하는 기능과 단순하거나 동일한 작업을 효육적으로 진행할 수
              있도록 도와주는&nbsp;
              <span className={style.highlight}>
                유틸리티 기능을 지원하는 CAD 3rd-Party
              </span>
              입니다.
            </>
          ) : (
            <>
              <span className={style.highlight}>
                토목, 건축 분야 뿐만 아니라 다양한 산업분야
              </span>
              에서 사용하는 기능과
              <br />
              단순하거나 동일한 작업을 효육적으로 진행할 수 있도록 도와주는
              <br />
              <span className={style.highlight}>
                유틸리티 기능을 지원하는 CAD 3rd-Party
              </span>
              입니다.
            </>
          )}
        </div>
        <div className={style.zw3d_info_guarantee}>
          ※ FULL 버전 사용 가능 · ZWCAD 버전과 동일한 버전의 ZDREAM 설치하여
          사용
        </div>
      </div>
      <div className={style.zdream_introduce_wrap}>
        <div className={style.zdream_introduce}>
          <div className={style.zdream_introduce_card}>
            <div className={style.zdream_introduce_txt}>
              <span className={style.zdream_introduce_txt_color}>200여개</span>
              의
            </div>
            <div className={style.zdream_introduce_txt2}>다양한 기능 제공</div>
            <img
              alt=""
              className={style.zdream_box_img1}
              src={
                process.env.REACT_APP_CLIENT_URL +
                "img/zwcad/zdream/zdream_box1.png"
              }
            />
          </div>
          <div className={style.zdream_introduce_card}>
            <div className={style.zdream_introduce_txt}>
              <span className={style.zdream_introduce_txt_color}>
                리본 / 클래식
              </span>
            </div>
            <div className={style.zdream_introduce_txt2}>메뉴 제공</div>
            <img
              alt=""
              className={style.zdream_box_img2}
              src={
                process.env.REACT_APP_CLIENT_URL +
                "img/zwcad/zdream/zdream_box2.png"
              }
            />
          </div>
          <div className={style.zdream_introduce_card}>
            <div className={style.zdream_introduce_txt}>
              <span className={style.zdream_introduce_txt_color}>엑셀 </span>
              데이터
            </div>
            <div className={style.zdream_introduce_txt2}>
              <span className={style.zdream_introduce_txt_color}>호환 </span>
              가능
            </div>
            <img
              alt=""
              className={style.zdream_box_img3}
              src={
                process.env.REACT_APP_CLIENT_URL +
                "img/zwcad/zdream/zdream_box3.png"
              }
            />
          </div>
        </div>
      </div>

      <div className={style.zw3d_procedure_wrap}>
        <div className={style.zw3d_procedure_title_wrap}>
          <div className={style.zw3d_procedure_label}>MAIN FUNCTION</div>
          <div className={style.zw3d_procedure_title}>
            주요 <span>기능</span>
          </div>
        </div>
        <div className={style.zdream_list}>
          {functionList.map((data, index) => function_draw(data))}
        </div>
      </div>

      <Underbar
        c1="ZDREAM"
        c1_use={true}
        c1_link=""
        c2="ZDREAM"
        c2_use={true}
        c2_link={data.LINK}
        c3="ZDREAM"
        c3_use={true}
        c3_link=""
        c4="ZDREAM"
        c4_use={true}
        c4_link=""
        c6="프로세스"
        c6_use={true}
        c6_link="https://www.youtube.com/watch?v=sKdAwOl1Oq0&list=PLIFLHLfQV9CARCKpAegQhq_zUvKCBENuv&index=1"
      />
    </>
  );
}

export default Zdream;
