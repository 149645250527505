import React from "react";
import style from "css/community.module.css";
function Eventdata(props) {
  return (
    <>
      <div
        className={props.indexdata === 1 ? style.eventcard_mg : style.eventcard}
      >
        <div className={style.eventcard_imgbox}>
          <img
            className={style.eventcard_img}
            alt=""
            src={process.env.REACT_APP_CLIENT_URL + props.data.THUMBNAIL}
          />
        </div>
        <div>
          <div className={style.eventcard_title}>{props.data.TITLE}</div>
          <div className={style.eventcard_date}>{props.data.INPUT_DT}</div>
        </div>
      </div>
    </>
  );
}

export default Eventdata;
