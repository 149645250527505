import React from "react";
import style from "css/zwcad.module.css";
import { useMediaQuery } from "react-responsive";

function ZwcadFeatureListItem(props) {
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <div
      className={
        props.feature.thema === "gray"
          ? `${style.zwcad_feature_item_wrap} gray`
          : style.zwcad_feature_item_wrap
      }
    >
      <div className={style.zwcad_feature_item}>
        {isMobile || props.feature.align === "left" ? (
          <>
            <img
              alt=""
              className={style.zwcad_feature_img}
              src={
                process.env.REACT_APP_CLIENT_URL +
                "img/zwcad/feature/" +
                props.feature.img
              }
            />
            <div className={style.zwcad_feature_description_wrap}>
              <div className={style.zwcad_feature_index}>
                {props.feature.index}
              </div>
              <div className={style.zwcad_feature_name}>
                {props.feature.name}
              </div>
              <div className={style.zwcad_feature_text}>
                {props.feature.text}
              </div>
              {props.feature?.tag !== undefined && props.feature.tag}
              {/* {Array.isArray(props.feature.tag_list) &&
                props.feature.tag_list.length > 0 && (
                  <div className={style.zwcad_feature_tag_list}>
                    {props.feature.tag_list.map((tag, index) => (
                      <div className={style.zwcad_feature_tag}>
                        {tag.tag_name}
                      </div>
                    ))}
                  </div>
                )} */}
            </div>
          </>
        ) : (
          <>
            <div className={style.zwcad_feature_description_wrap}>
              <div className={style.zwcad_feature_index}>
                {props.feature.index}
              </div>
              <div className={style.zwcad_feature_name}>
                {props.feature.name}
              </div>
              <div className={style.zwcad_feature_text}>
                {props.feature.text}
              </div>
              {/* {Array.isArray(props.feature.tag_list) &&
                props.feature.tag_list.length > 0 && (
                  <div className={style.zwcad_feature_tag_list}>
                    {props.feature.tag_list.map((tag, index) => (
                      <div className={style.zwcad_feature_tag}>
                        {tag.tag_name}
                      </div>
                    ))}
                  </div>
                )} */}
              {props.feature?.tag !== undefined && props.feature.tag}
            </div>
            <img
              alt=""
              className={style.zwcad_feature_img}
              src={
                process.env.REACT_APP_CLIENT_URL +
                "img/zwcad/feature/" +
                props.feature.img
              }
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ZwcadFeatureListItem;
