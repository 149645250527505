import React, { useEffect, useState } from "react";
import style from "css/board.module.css";
import Joditeditor from "components/common/joditeditor";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { openAlert } from "redux/slices/alertSlice";

function Board() {
  const [contents, setcontents] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [boardInfo, setboardInfo] = useState({
    TYPE: location.state.type,
    TITLE: "",
    TEXT: "",
    IMG: [],
    FILE: [],
    HOLD: false,
    BOARD_ID: location.state.board_id,
  });

  const loaddatachange = (data) => {
    let tmpBoardInfo = boardInfo;
    tmpBoardInfo.TITLE = data.TITLE;

    tmpBoardInfo.BOARD_ID = data.BOARD_ID;
    if (data.HOLD === "0") {
      tmpBoardInfo.HOLD = false;
    } else {
      tmpBoardInfo.HOLD = true;
    }
    console.log(tmpBoardInfo);
    setboardInfo(tmpBoardInfo);
  };

  /////////////////img
  const [totalImg, settotalImg] = useState([]);
  const [oldImgFiles, setOldImgFiles] = useState([]);

  const onChangeImgFile = (e) => {
    let tmpArr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].type.replace(/\/.*/, "") !== "image") {
        alert("형식 오류");
        return;
      }
      if (oldImgFiles.length > 0) {
        setOldImgFiles([]);
      }
      tmpArr.push(e.target.files[i]);
    }
    console.log(tmpArr);
    settotalImg(tmpArr);
    setboardInfo({ ...boardInfo, IMG: tmpArr });
    e.target.value = "";

    console.log("total img" + totalImg[0]);
  };
  const onRemoveImg = (file) => {
    setOldImgFiles([]);
    setboardInfo({
      ...boardInfo,
      IMG: [],
    });
  };

  /////////// files
  const [totalFiles, setTotalFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);

  const onChangeFile = (e) => {
    let tmpArr = [];
    let totalTmp = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (boardInfo.FILE.find((file) => file.name === e.target.files[i].name)) {
        alert("파일이 이미 존재합니다.");
        return;
      }
      if (
        oldFiles.find((file) => file.ORI_FILE_NM === e.target.files[i].name)
      ) {
        alert("파일이 이미 존재합니다.");
        return;
      }
      tmpArr.push(e.target.files[i]);
      totalTmp.push({ FILE_NM: e.target.files[i].name, type: "new" });
    }
    setboardInfo({ ...boardInfo, FILE: boardInfo.FILE.concat(tmpArr) });
    setTotalFiles(totalFiles.concat(totalTmp));
    e.target.value = "";
  };

  const onRemoveFile = (file) => {
    if (file.type === "old") {
      setOldFiles(oldFiles.filter((list) => list.FILE_NM !== file.FILE_NM));
    } else if (file.type === "new") {
      setboardInfo({
        ...boardInfo,
        FILE: boardInfo.FILE.filter((item) => item.name !== file.FILE_NM),
      });
    }

    for (let k = 0; k < boardInfo.FILE.length; k++) {
      console.log(boardInfo.FILE[k].name);
    }

    setTotalFiles(totalFiles.filter((list) => list.FILE_NM !== file.FILE_NM));
  };

  ///////////////////////////////// create class

  const submit = () => {
    if (location.state.board_id === "NEW") {
      writeBoard();
    } else {
      updateBoard();
    }
  };

  const writeBoard = () => {
    let boardInfoObj = {
      TYPE: location.state.type,
      TITLE: boardInfo.TITLE,
      TEXT: contents,
      HOLD: boardInfo.HOLD,
    };

    const formData = new FormData();
    formData.append("boardInfo", JSON.stringify(boardInfoObj));

    if (Array.isArray(boardInfo.IMG) && boardInfo.IMG.length > 0) {
      for (let i = 0; i < boardInfo.IMG.length; i++) {
        formData.append("img", boardInfo.IMG[i]);
      }
    }
    if (Array.isArray(boardInfo.FILE) && boardInfo.FILE.length > 0) {
      for (let i = 0; i < boardInfo.FILE.length; i++) {
        formData.append("files", boardInfo.FILE[i]);
      }
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post("/board/write", formData, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            openAlert({
              title: "Notice",
              content:
                (location.state.type === "CD0401"
                  ? "이벤트가"
                  : location.state.type === "CD0402"
                  ? "공지사항이"
                  : "FAQ가") + " 등록 되었습니다.",
              submitEventHandler: () =>
                navigate(
                  process.env.REACT_APP_CLIENT_URL +
                    "admin/" +
                    (location.state.type === "CD0401"
                      ? "event"
                      : location.state.type === "CD0402"
                      ? "notice"
                      : "faq")
                ),
            })
          );
        }
      })
      .catch(() => {});
  };
  /////  수정
  const updateBoard = () => {
    let boardInfoObj = {
      TYPE: location.state.type,
      TITLE: boardInfo.TITLE,
      TEXT: contents,
      HOLD: boardInfo.HOLD,
      BOARD_ID: boardInfo.BOARD_ID,
    };
    if (Array.isArray(oldFiles) && oldFiles.length > 0) {
      boardInfoObj = {
        ...boardInfoObj,
        file_info: oldFiles,
      };
    }

    if (Array.isArray(oldImgFiles) && oldImgFiles.length > 0) {
      boardInfoObj = {
        ...boardInfoObj,
        img_info: oldImgFiles,
      };
    }
    const formData = new FormData();
    formData.append("boardInfo", JSON.stringify(boardInfoObj));

    if (Array.isArray(boardInfo.IMG) && boardInfo.IMG.length > 0) {
      for (let i = 0; i < boardInfo.IMG.length; i++) {
        formData.append("img", boardInfo.IMG[i]);
      }
    }
    if (Array.isArray(boardInfo.FILE) && boardInfo.FILE.length > 0) {
      for (let i = 0; i < boardInfo.FILE.length; i++) {
        formData.append("files", boardInfo.FILE[i]);
      }
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .put("/board/write", formData, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            openAlert({
              title: "Notice",
              content:
                (location.state.type === "CD0401"
                  ? "이벤트가"
                  : location.state.type === "CD0402"
                  ? "공지사항이"
                  : "FAQ가") + " 수정 되었습니다.",
              submitEventHandler: () =>
                navigate(
                  process.env.REACT_APP_CLIENT_URL +
                    "admin/" +
                    (location.state.type === "CD0401"
                      ? "event"
                      : location.state.type === "CD0402"
                      ? "notice"
                      : "faq")
                ),
            })
          );
        } else {
          console.log(res);
        }
      })
      .catch(() => {});
  };

  const loadInfo = () => {
    let config = {
      params: {
        BOARD_ID: location.state.board_id,
      },
    };

    axios.get("/board/load", config).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        loaddatachange(res.data.BoardInfo);
        let fileInfo = "";
        if (res.data.CLASS_INFO !== "") {
          fileInfo = res.data.BoardInfo.FILE_INFO;
        }
        let imgFileInfo = res.data.BoardInfo.IMG_FILE_INFO;

        if (fileInfo !== "") {
          const fileList = fileInfo.split("|");
          let rawfileInfo = [];
          let fileInfoList = [];

          for (let i = 0; i < fileList.length; i++) {
            rawfileInfo = fileList[i].split(";");
            const fileInfo = {
              fileNm: rawfileInfo[0],
              fileoriNm: rawfileInfo[1],
              fileURL: rawfileInfo[2],
            };
            fileInfoList.push(fileInfo);
          }

          let tmp = [];
          for (let i = 0; i < fileInfoList.length; i++) {
            tmp.push({
              FILE_NM: fileInfoList[i].fileNm,
              ORI_FILE_NM: fileInfoList[i].fileoriNm,
              type: "old",
            });
          }
          setOldFiles(tmp);
          setTotalFiles(tmp);
        }
        if (imgFileInfo !== "") {
          const fileList = imgFileInfo.split("|");
          let rawfileInfo = [];
          let fileInfoList = [];

          for (let i = 0; i < fileList.length; i++) {
            rawfileInfo = fileList[i].split(";");
            const fileInfo = {
              fileNm: rawfileInfo[0],
              fileoriNm: rawfileInfo[1],
              fileURL: rawfileInfo[2],
            };
            fileInfoList.push(fileInfo);
          }

          let tmp = [];
          for (let i = 0; i < fileInfoList.length; i++) {
            tmp.push({
              FILE_NM: fileInfoList[i].fileNm,
              ORI_FILE_NM: fileInfoList[i].fileoriNm,
              type: "loaded",
            });
          }

          setOldImgFiles(tmp);
        }
        if (res.data.BoardInfo.TEXT !== "") {
          setcontents(res.data.BoardInfo.TEXT);
        } else {
          setcontents("내용을 입력하세요");
        }
      }
    });
  };

  useEffect(() => {
    if (location.state.board_id !== "NEW") {
      loadInfo();
    }
  }, [location]);

  return (
    <>
      <div style={{ height: "80px", backgroundColor: "black" }}></div>
      <div className={style.wrapper}>
        <div className={style.header}>
          {" "}
          {location.state.type === "CD0401"
            ? "이벤트"
            : location.state.type === "CD0403"
            ? "FAQ"
            : "공지사항"}
          {location.state.board_id === "NEW" ? " 등록" : " 수정"}페이지
        </div>
        <div className={style.body}>
          <div className={style.contents}>
            <div className={style.contents_header}>상단 고정 여부</div>
            <div className={style.contents_body}>
              <input
                type="checkbox"
                checked={boardInfo.HOLD}
                onChange={(e) =>
                  setboardInfo({ ...boardInfo, HOLD: e.target.checked })
                }
              />
              체크하시면 목록 상단에 고정됩니다.
            </div>
          </div>
          <div className={style.contents}>
            <div className={style.contents_header}> 썸네일</div>
            <div className={style.contents_body}>
              <div className={style.contents_body}>
                <div className={style.contents_file}>
                  <div className={style.contents_files}>
                    {oldImgFiles.length === 0 && boardInfo.IMG.length > 0 ? (
                      <div className={style.file_list_single}>
                        <div className={style.files_line}>
                          <div>{boardInfo.IMG[0].name}</div>
                          <div className={style.file_remove + " cursor"}>
                            <img
                              src="/img/common/remove.png"
                              alt=""
                              className={style.file_remove}
                              onClick={() => onRemoveImg()}
                            />
                          </div>
                        </div>
                      </div>
                    ) : oldImgFiles.length > 0 && boardInfo.IMG.length === 0 ? (
                      <div className={style.file_list_single}>
                        <div className={style.files_line}>
                          <div>{oldImgFiles[0].ORI_FILE_NM}</div>
                          <div className={style.file_remove + " cursor"}>
                            <img
                              src="/img/common/remove.png"
                              alt=""
                              className={style.file_remove}
                              onClick={() => onRemoveImg()}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={style.file_list_none}>
                        썸네일이 등록되지 않았습니다.
                      </div>
                    )}
                  </div>
                  <div className={style.contents_files_btn}>
                    <label htmlFor="IMG" className={style.files_btn}>
                      <div className="cursor"> 이미지 등록</div>
                    </label>
                    <input
                      id="IMG"
                      className="new-class-file-input"
                      type="file"
                      style={{ display: "none" }}
                      name="IMG"
                      onChange={onChangeImgFile}
                      alt=""
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </div>
                </div>
              </div>
              <div className={style.contents_files_annotation}>
                * 썸네일 이미지 미지정 시 대표이미지가 게시됩니다.{" "}
              </div>
            </div>
          </div>
          <div className={style.contents}>
            <div className={style.contents_header}>제목</div>
            <div className={style.contents_body}>
              <div className={style.contents_title}>
                <input
                  type="text"
                  placeholder="제목을 입력하세요."
                  className={style.contents_title_input}
                  value={boardInfo.TITLE}
                  onChange={(e) =>
                    setboardInfo({ ...boardInfo, TITLE: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className={style.contents}>
            <div className={style.contents_header}>내용</div>
            <div className={style.contents_body}>
              <div className={style.contents_text_annotation}>
                * 본문이 이미지인 경우 우측 상단의 이미지 아이콘을 클릭해주세요
              </div>
              <Joditeditor
                value={contents} //초기값
                onChange={
                  (contents) => setcontents(contents) // 값
                }
              />
            </div>
          </div>
          <div className={style.contents}>
            <div className={style.contents_header}>첨부</div>
            <div className={style.contents_body}>
              <div className={style.contents_file}>
                <div className={style.contents_files}>
                  {Array.isArray(totalFiles) && totalFiles.length > 0 ? (
                    <div className={style.file_list}>
                      {totalFiles.map((item, idx) => (
                        <div key={idx} className={style.files_line}>
                          <div className={style.files_name}>
                            {item.type === "old"
                              ? item.ORI_FILE_NM
                              : item.FILE_NM}
                          </div>
                          <div className={style.file_remove + " cursor"}>
                            <img
                              src="/img/common/remove.png"
                              alt=""
                              className={style.file_remove}
                              onClick={() => onRemoveFile(item)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={style.file_list_none}>
                      첨부파일이 등록되지 않았습니다.
                    </div>
                  )}
                </div>
                <div className={style.contents_files_btn}>
                  <label htmlFor="FILE" className={style.files_btn}>
                    <div className="cursor">파일 등록</div>
                  </label>
                  <input
                    id="FILE"
                    className="new-class-file-input"
                    type="file"
                    style={{ display: "none" }}
                    name="file"
                    onChange={onChangeFile}
                    multiple
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={style.buttons}>
            <div
              className={style.board_cancel + " cursor"}
              onClick={() =>
                navigate(
                  process.env.REACT_APP_CLIENT_URL +
                    "admin/" +
                    (location.state.type === "CD0401"
                      ? "event"
                      : location.state.type === "CD0402"
                      ? "notice"
                      : "faq")
                )
              }
            >
              취소
            </div>
            <div
              className={style.board_submit + " cursor"}
              onClick={() => submit()}
            >
              등록
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Board;
