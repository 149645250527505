import React, { useCallback, useEffect, useState } from "react";
import style from "css/zwcad.module.css";
import { useNavigate } from "react-router-dom";
import ZwcadFeatureListItem from "components/zwcad/zwcadFeatureListItem";
import Underbar from "components/common/underbar";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Mobilemenu from "components/common/mobilemenu";
/**
 * Version : 1.0
 * 작성일자 : 2024-02-14
 * 작성자 : 강연승
 * 설명 : ZWCAD 페이지
 */

function Zwcad() {
  //navigate
  const navigate = useNavigate();
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  const [data, setdata] = useState({
    LINK: "",
  });

  const getlist = useCallback(() => {
    let config = {
      params: {
        TITLE: "ZWCAD",
      },
    };
    axios.get("/open/product_fileinfo", config).then((res) => {
      if (res.status === 200) {
        if (res.data.TYPE === "FILE") {
          setdata({ LINK: res.data.FILE_URL });
        } else {
          setdata({ LINK: res.data.LINK });
        }
      }
    });
  }, []);
  useEffect(() => {
    getlist();
  }, [getlist]);

  const featureList = [
    {
      index: "01",
      thema: "white",
      align: "left",
      img: "1.png",
      name: "친근한 CAD",
      text: (
        <>
          ZWCAD는 익숙한{" "}
          <span className={style.highlight}>
            클래식 인터페이스와 리본 인터페이스
          </span>
          로 제공되어, 기존에 사용하던 캐드 명령어 동일하여 별도에 교육이 없이
          바로 사용할 수 있습니다.
        </>
      ),
    },
    {
      index: "02",
      thema: "white",
      align: "right",
      img: "2.png",
      name: "호환성",
      text: (
        <>
          <span className={style.highlight}>DWG 100% 호환</span>되어 디자인
          작업을 열고 작업을 진행할 수 있으며, 내부적으로 도면을 공유하여 워크
          플로를 보다 유창하게 만들 수 있습니다.
        </>
      ),
      tag: (
        <div className={style.tag_square}>
          * 모든 2D CAD 및 DWG 100% 호환 가능
        </div>
      ),
    },
    {
      index: "03",
      thema: "white",
      align: "left",
      img: "3.png",
      name: "도면 작업",
      text: "ZWCAD는 다양한 기능과 혁신적인 도구를 제공하여 도면/제도 작업을 훨씬 효과적으로 만들어 줍니다.",
      tag: (
        <div className={style.tag_grid}>
          <div className={style.tag_grid_item}># 파일비교</div>
          <div className={style.tag_grid_item}># 속성매치</div>
          <div className={style.tag_grid_item}># 오브젝트 분리</div>
          <div className={style.tag_grid_item}># DGN 내보내기</div>
        </div>
      ),
    },
    {
      index: "04",
      thema: "gray",
      align: "right",
      img: "4.png",
      name: "워크플로우",
      text: (
        <>
          ZWCAD 모바일 솔루션–CAD Pockets으로
          <br />
          <span className={style.highlight}>PC 뿐만 아니라 모바일</span> 에서도
          자유롭게 작업할 수 있습니다.
        </>
      ),
    },
    {
      index: "05",
      thema: "white",
      align: "left",
      img: "5.png",
      name: "혁신적 기능",
      text: "스마트한 기능으로 활용도를 높일 수 있습니다.",
      tag: (
        <div className={style.tag_container}>
          <div className={style.tag_container_item}>
            <span className={style.item_dot} />
            스마트 보이스
          </div>
          <div className={style.tag_container_item}>
            <span className={style.item_dot} />
            스마트 마우스
          </div>
          <div className={style.tag_container_item}>
            <span className={style.item_dot} />
            스마트 셀렉트
          </div>
          <div className={style.tag_container_item}>
            <span className={style.item_dot} />
            바코드 및 QR코드
          </div>
        </div>
      ),
    },
    {
      index: "06",
      thema: "white",
      align: "right",
      img: "6.png",
      name: "고급 툴",
      text: <span className={style.highlight}>편의성과 사용범위 향상</span>,
      tag: (
        <div className={style.tag_wrap}>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            속성 추출
          </div>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            속성 매칭
          </div>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            OLE 개체삽입
          </div>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            도구 팔레트
          </div>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            슈퍼 해치
          </div>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            레퍼런스 관리자
          </div>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            개체 격리
          </div>
          <div className={style.tag_wrap_item}>
            <span className={style.item_dot} />
            파일 비교
          </div>
        </div>
      ),
    },
    {
      index: "07",
      thema: "white",
      align: "left",
      img: "7.png",
      name: "3D 기능",
      text: "스마트한 기능으로 활용도를 높일 수 있습니다.",
      tag: (
        <div className={style.tag_list}>
          <div className={style.tag_list_item}>
            <div className={style.tag_list_item_label}>
              <span className={style.item_dot} />
              시각화
            </div>
            <div className={style.tag_list_item_text}>
              숨기기·음영 및 렌더링
            </div>
          </div>
          <div className={style.tag_list_item}>
            <div className={style.tag_list_item_label}>
              <span className={style.item_dot} />
              모델링
            </div>
            <div className={style.tag_list_item_text}>3D 모델 작성 및 수정</div>
          </div>
          <div className={style.tag_list_item}>
            <div className={style.tag_list_item_label}>
              <span className={style.item_dot} />
              보기
            </div>
            <div className={style.tag_list_item_text}>
              3D궤도 보기와 DVIEW를 사용한 PERSPECTIVE 뷰 디스플레이
            </div>
          </div>
        </div>
      ),
    },
    {
      index: "08",
      thema: "white",
      align: "right",
      img: "8.png",
      name: "출력",
      text: (
        <>
          <span className={style.highlight}>STB / CTB 플롯 스타일</span> 및{" "}
          <span className={style.highlight}>다른 포맷</span>으로 인쇄 가능
        </>
      ),
      // tag_list: [
      //   {
      //     tag_name: <div></div>,
      //   },
      // ],
    },
    {
      index: "09",
      thema: "gray",
      align: "left",
      img: "9.png",
      name: "APIs",
      text: (
        <>
          마이그레이션이 쉬운 코드 수준의 호환가능한 API 수많은 맵들이 출시되고
          있습니다.
        </>
      ),
    },
  ];
  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };

  const menulist = [
    { TITLE: "ZW3D 2025", LINK: "zw3d" },
    { TITLE: "ZDREAM", LINK: "zdream" },
    { TITLE: "CAD 제품 비교", LINK: "cad_compare" },
    { TITLE: "ZW3D 제품 비교", LINK: "zw3d_compare" },
  ];

  return (
    <>
      <div className={style.wrapper}>
        <div
          className={style.background}
          style={
            isMobile
              ? {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/zwcad_m_bg.png')",
                }
              : {
                  backgroundImage:
                    "url('" +
                    process.env.REACT_APP_CLIENT_URL +
                    "img/zwcad/zwcad_bg.png')",
                }
          }
        >
          {isMobile ? (
            <img alt="" src="/img/zwcad/zwcad_m_bg_img.png" />
          ) : (
            <></>
          )}
        </div>
        <div className={style.zwcad_area}>
          <div className={style.zwcad_title_wrap}>
            <div className={style.zwcad_title}>ZWCAD 2025</div>
            <div className={style.zwcad_subtitle}>
              효율성과 안정성이 극대화된 ZWCAD 2025
            </div>
            <div className={style.zwcad_title_btn_wrap}>
              <div
                className={style.zwcad_title_btn + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "free")
                }
              >
                무료체험 신청
              </div>
              <div
                className={style.zwcad_title_btn + " cursor"}
                onClick={() =>
                  navigate(process.env.REACT_APP_CLIENT_URL + "request")
                }
              >
                견적 신청
              </div>
            </div>
          </div>
        </div>
      </div>
      {isTablet ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="ZWCAD 2025" list={menulist} type="zwcad" />
        </div>
      ) : isMobile ? (
        <div className={style.zwcad_category}>
          <Mobilemenu selected="ZWCAD 2025" list={menulist} type="zwcad" />
        </div>
      ) : (
        <div className={style.zwcad_category}>
          <div className={style.zwcad_category_btn_wrap}>
            <div
              className={
                style.zwcad_category_btn + " " + style.button_focus + " cursor"
              }
              onClick={() => menuHandler("zwcad")}
            >
              ZWCAD 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d")}
            >
              ZW3D 2025
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zdream")}
            >
              ZDREAM
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("cad_compare")}
            >
              CAD 제품 비교
            </div>
            <div
              className={style.zwcad_category_btn + " cursor"}
              onClick={() => menuHandler("zw3d_compare")}
            >
              ZW3D 제품 비교
            </div>
          </div>
        </div>
      )}

      <div className={style.zwcad_feature_list}>
        {featureList.map((feature, index) => (
          <ZwcadFeatureListItem feature={feature} key={index} />
        ))}
      </div>

      <Underbar
        c1="ZWCAD 2025"
        c1_use={true}
        c1_link=""
        c2="ZWCAD 2025"
        c2_use={true}
        c2_link={data.LINK}
        c3="ZWCAD 2025"
        c3_use={true}
        c3_link=""
        c4="ZWCAD 2025"
        c4_use={true}
        c4_link=""
      />
    </>
  );
}

export default Zwcad;
