import React, { useState } from "react";
import style from "css/landing.module.css";
import { useNavigate } from "react-router-dom";

/**
 * 파일명: ProductCardItem.js
 * 작성자: 강연승
 * 작성일자: 24.02.15
 * 설명: landing page - ProductArea - ProductCardItem
 */

function ProductCardItem(props) {
  //navigate
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);

  const menuHandler = (e) => {
    navigate(process.env.REACT_APP_CLIENT_URL + e);
  };
  return (
    <div
      className={`${style.product_card_item} cursor`}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={() => menuHandler(props.product.KEY)}
    >
      <img
        className={
          isHover ? style.product_card_img_hover : style.product_card_img
        }
        alt=""
        src={
          process.env.REACT_APP_CLIENT_URL +
          "img/landing/product/" +
          props.product.IMG
        }
      />
      <div className={style.product_info_wrap}>
        <div className={style.product_info}>
          <div className={style.product_info_title}>{props.product.TITLE}</div>
          <div className={style.product_info_subtitle}>
            {props.product.SUBTITLE}
          </div>
        </div>
        <div className={style.product_nav_wrap}>
          <div className={style.product_nav}>
            <div className={style.product_nav_inner}>
              <img
                alt=""
                src={
                  process.env.REACT_APP_CLIENT_URL +
                  "img/common/arrow_right.png"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCardItem;
