import React, { useCallback, useEffect, useState } from "react";
import "css/paging.css";

/**
 * Version : 1.0
 * 파일명 : Paging.js
 * 작성일자 : 2023-01-13
 * 작성자 : 권도훈
 */
function Paging(props) {
  const [page, setPage] = useState([]);
  const [maxPage, setMaxpage] = useState(props.maxPage);
  const [minPage, setMinpage] = useState(1);

  const calPage = useCallback(() => {
    const totalPage = Math.ceil(props.totalCount / props.rowCount);
    var pageArr = [];

    for (var i = 1; i <= totalPage; i++) {
      pageArr.push(i);
    }

    setPage(pageArr);

    if (props.currentPage > maxPage) {
      setMaxpage(maxPage + props.maxPage);
      setMinpage(minPage + props.maxPage);
    } else if (props.currentPage < minPage) {
      setMaxpage(maxPage - props.maxPage);
      setMinpage(minPage - props.maxPage);
    }
  }, [maxPage, minPage, props]);

  useEffect(() => {
    if (props.init) {
      calPage();
      props.setInit(false);
    }
  }, [calPage, props, props.init]);

  const btnRender = (el, idx) => {
    if (!(el < maxPage - 4) && !(el > maxPage)) {
      return (
        <div
          className={
            el === props.currentPage
              ? "paging-item center cursor active"
              : "paging-item center cursor"
          }
          key={idx}
          onClick={() => {
            if (el !== props.currentPage) {
              props.onClick(el);
            }
          }}
        >
          {el}
        </div>
      );
    }
  };

  return (
    <div className="paging-container-landing">
      {props.location === "landing" ? (
        <>
          <div
            className="paging-item center cursor"
            onClick={() => {
              if (props.currentPage > 1) {
                if (props.currentPage - 1 < maxPage - 4) {
                  props.setInit(true);
                }
                props.onClick(props.currentPage - 1);
              }
            }}
          >
            &#60;
          </div>

          {page.map((el, idx) => btnRender(el, idx))}

          <div
            className="paging-item center cursor"
            onClick={() => {
              if (
                props.currentPage < Math.ceil(props.totalCount / props.rowCount)
              ) {
                if (props.currentPage + 1 > maxPage) {
                  props.setInit(true);
                }
                props.onClick(props.currentPage + 1);
              }
            }}
          >
            &#62;
          </div>
        </>
      ) : props.totalCount > props.rowCount ? (
        <>
          <div
            className="paging-item center cursor"
            onClick={() => {
              if (props.currentPage > 1) {
                if (props.currentPage - 1 < maxPage - 4) {
                  props.setInit(true);
                }
                props.onClick(props.currentPage - 1);
              }
            }}
          >
            &#60;
          </div>

          {page.map((el, idx) => btnRender(el, idx))}

          <div
            className="paging-item center cursor"
            onClick={() => {
              if (
                props.currentPage < Math.ceil(props.totalCount / props.rowCount)
              ) {
                if (props.currentPage + 1 > maxPage) {
                  props.setInit(true);
                }
                props.onClick(props.currentPage + 1);
              }
            }}
          >
            &#62;
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Paging;
