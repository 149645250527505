import React from "react";
import style from "css/landing.module.css";
import ProductCardItem from "./productCardItem";
import { useMediaQuery } from "react-responsive";

/**
 * 파일명: ProductArea.js
 * 작성자: 강연승
 * 작성일자: 24.02.15
 * 설명: landing page - ProductArea
 */

function ProductArea() {
  //반응형
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });

  const productList = [
    {
      KEY: "zwcad",
      TITLE: "ZWCAD 2025",
      SUBTITLE: "빠르고 강력한 호환성",
      IMG: "zwcad_2024.png",
    },
    {
      KEY: "zw3d",
      TITLE: "ZW3D",
      SUBTITLE: "설계·제조 생산성 향상",
      IMG: "zw3d.png",
    },
    {
      KEY: "zdream",
      TITLE: "ZDREAM",
      SUBTITLE: "동일한 작업을 효율적으로",
      IMG: "zdream.png",
    },
    {
      KEY: "foxit_pdf_editor",
      TITLE: "FOXIT\nPDF EDITOR",
      SUBTITLE: "공유와 협업",
      IMG: "foxit_pdf_editor.png",
    },
    {
      KEY: "foxit_pdf_esign",
      TITLE: "FOXIT\nPDF ESIGN",
      SUBTITLE: "전자서명 서비스",
      IMG: "foxit_pdf_esign.png",
    },
    {
      KEY: "pdf_reader",
      TITLE: "FOXIT\nPDF READER",
      SUBTITLE: "자유로운 열람·인쇄",
      IMG: "foxit_pdf_reader.png",
    },
  ];

  return (
    <div className={style.wrapper}>
      <div
        className={style.background}
        style={{
          backgroundImage:
            "url('" +
            process.env.REACT_APP_CLIENT_URL +
            "img/landing/product_bg.png')",
        }}
      />

      <div className={style.product_area}>
        <div className={style.product_title}>Product</div>
        <div className={style.product_subtitle}>
          <div className={style.product_description}>
            {isMobile || isTablet
              ? "고객의 성장이 BLUETNS의 기반이며,\n고객의 만족이 BLEUTNS의 자부심입니다."
              : "고객의 성장이 BLUETNS의 기반이며, 고객의 만족이 BLEUTNS의 자부심입니다."}
          </div>
          <div className={style.product_description}>
            {isMobile
              ? "Customer growth is the basis of BLUTNS,\nand customer satisfaction is the pride\nof BLUTNS"
              : isTablet
              ? "Customer growth is the basis of BLUTNS,\nand customer satisfaction is the pride of BLUTNS"
              : "Customer growth is the basis of BLUTNS, and customer satisfaction is the pride of BLUTNS"}
          </div>
        </div>
        <div className={style.product_card_list}>
          {isMobile || isTablet ? (
            productList.map((item, idx) => (
              <div key={idx} className={style.product_card_wrapper}>
                <ProductCardItem product={item} />
              </div>
            ))
          ) : (
            <>
              <div className={style.product_card_wrapper}>
                <ProductCardItem product={productList[0]} />
              </div>
              <div className={style.product_card_wrapper}>
                <ProductCardItem product={productList[1]} />
                <ProductCardItem product={productList[3]} />
              </div>
              <div className={style.product_card_wrapper}>
                <ProductCardItem product={productList[2]} />
                <ProductCardItem product={productList[4]} />
              </div>
              <div className={style.product_card_wrapper}>
                <ProductCardItem product={productList[5]} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductArea;
