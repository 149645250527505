import React from "react";

/**
 * Version : 1.0
 * 작성일자 : 2024-02-14
 * 작성자 : 강연승
 * 설명 : Foxit PDF 페이지
 */

function Foxit() {
  return <div>Foxit PDF</div>;
}

export default Foxit;
